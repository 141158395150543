<div class="form">
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <label>Picture</label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label" for="customFile">Choose file</label>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <label>Name*</label>
        <input type="name" class="form-control">
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <label>Phone Number*</label>
        <input type="tel" class="form-control">
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <label>Role	</label>
        <select class="custom-select" id="role">
          <option selected="">Select Role</option>
          <option value="1">Focal Teacher	</option>
          <option value="2">School Administrator</option>
          <option value="3">Teacher</option>
      </select>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <label>School</label>
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <label>Location</label>
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <label>User Type</label>
        <select class="custom-select" id="user-type">
          <option selected="">Select User Type</option>
          <option value="1">Trainee</option>
          <option value="2">Guest</option>
       </select>
      </div>
    </div>
  </div>
  <button class="btn btn-warning" type="submit">Save</button>
</div>