<div class="auth-body-bg">
	<div class="home-btn d-none d-sm-block"> <a><i class="mdi mdi-home-variant h2 text-white"></i></a>
	</div>
	<div>
		<div class="container-fluid p-0">
			<div class="row no-gutters">
				<div class="col-lg-4">
					<div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
						<div class="w-100">
							<div class="row justify-content-center">
								<div class="col-lg-9">
									<div>
										<div class="text-center">
											<div>
												<a class="logo">
													<img src="assets/images/logo-dark.png" height="45" alt="logo">
												</a>
											</div>
											<h4 class="font-size-18 mt-3">Forgot Password</h4>
										</div>
										<div class="p-2 mt-3">
											<form class="form-horizontal" [formGroup]="ResetPasswordForm">
												<div class="mb-4">
													<div class="form-group auth-form-group-custom mb-0"> <i
															class="ri-lock-2-line auti-custom-input-icon"></i>
														<label for="userpassword">Password</label>
														<input [type]="password ? 'text' : 'password' "
															class="form-control" placeholder="Enter new password"
															formControlName="password"> <i (click)="showPassword()"
															class=" auti-custom-input-icon auti-custom-eye"
															[ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>

													</div>
													<div *ngIf="submitted && f.password.errors" class="invalid-feedback"
														style="display: block;">
														<div *ngIf="f.password.errors.required">Password is required
														</div>
													</div>
												</div>
												<div class="mb-4">
													<div class="form-group auth-form-group-custom mb-0"> <i
															class="ri-lock-2-line auti-custom-input-icon"></i>
														<label for="userpassword">Password</label>
														<input [type]="confirmpassword ? 'text' : 'password' "
															class="form-control" placeholder="Enter confirm password"
															formControlName="confirmpassword"> <i
															(click)="showConfirmPassword()"
															class=" auti-custom-input-icon auti-custom-eye"
															[ngClass]="confirmpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
													</div>
													<div *ngIf="submitted && f.confirmpassword.errors"
														class="invalid-feedback" style="display: block;">
														<div *ngIf="f.confirmpassword.errors.required">Confirm password
															is
															required</div>
													</div>
												</div>
												<div class="mt-4 text-center">
													<button class="btn btn-primary w-md waves-effect waves-light"
														type="submit" (click)="ResetPassword()">Reset</button>
												</div>
											</form>
										</div>
										<div class="mt-3 text-center">
											<p>Don't have an account ? <a [routerLink]="['/login']"
													class="font-weight-medium text-primary">
													Log in </a>
											</p>
											<p class=" copyright">© 2021 Splash Remote Learning Crafted with <i
													class="mdi mdi-heart text-danger"></i> by <a
													href="https://greychaindesign.com/">Grey Chain</a>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-8">
					<div class="authentication-bg">
						<div class="bg-overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>