<!-- <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item" [routerLink]="['/admin/sms']">Training Programs</li>
                <li class="breadcrumb-item active">Modules</li>
              </ol>
            </div>
            <div class="page-title-right2">
              <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                (click)="openModal('addModules')">Add</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card table_page">
            <div class="card-body pt-3">
              <div class="table-responsive">
                <div class="row">
                  <div class="col-sm-12 col-md-6"><h4 class="card-title mb-4">Training Name:- Lorem ipsum</h4></div>
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_filter">
                      <label>
                        <input type="search" class="form-control form-control-sm" placeholder="Search..">
                      </label>
                    </div>
                  </div>
                </div>
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th style="width:40px;">No.</th>
                      <th>Module Name</th>
                      <th style="width:140px;">Chapters </th>
                      <th style="width:85px;">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-title="No.">1</td>
                       <td data-title="Module Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Chapters"><span class="badge badge-dark" [routerLink]="['/admin/sms/sms-chapters']">4</span></td>
                      
                        <td data-title="Actions">
                        <button type="button" (click)="openModal('editModules')" class="mr-2 text-primary btn_style"><i
                            class="mdi mdi-pencil font-size-18"></i>
                        </button>
                         <a [routerLink]="['/admin/sms-chapters']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                          <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">2</td>
                       <td data-title="Module Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Chapters"><span class="badge badge-dark" [routerLink]="['/admin/sms-chapters']">4</span></td>
                      
                        <td data-title="Actions">
                        <button type="button" (click)="openModal('editModules')" class="mr-2 text-primary btn_style"><i
                            class="mdi mdi-pencil font-size-18"></i>
                        </button>
                         <a [routerLink]="['/admin/sms-chapters']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                          <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">3</td>
                       <td data-title="Module Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Chapters"><span class="badge badge-dark" [routerLink]="['/admin/sms-chapters']">4</span></td>
                      
                        <td data-title="Actions">
                        <button type="button" (click)="openModal('editModules')" class="mr-2 text-primary btn_style"><i
                            class="mdi mdi-pencil font-size-18"></i>
                        </button>
                         <a [routerLink]="['/admin/sms-chapters']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                          <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">4</td>
                       <td data-title="Module Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Chapters"><span class="badge badge-dark" [routerLink]="['/admin/sms-chapters']">4</span></td>
                      
                        <td data-title="Actions">
                        <button type="button" (click)="openModal('editModules')" class="mr-2 text-primary btn_style"><i
                            class="mdi mdi-pencil font-size-18"></i>
                        </button>
                         <a [routerLink]="['/admin/sms-chapters']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                          <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">5</td>
                       <td data-title="Module Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Chapters"><span class="badge badge-dark" [routerLink]="['/admin/sms-chapters']">4</span></td>
                      
                        <td data-title="Actions">
                        <button type="button" (click)="openModal('editModules')" class="mr-2 text-primary btn_style"><i
                            class="mdi mdi-pencil font-size-18"></i>
                        </button>
                         <a [routerLink]="['/admin/sms-chapters']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                          <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info">Showing 1 to 10 of 11 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div class="dataTables_paginate paging_simple_numbers">
                      <ul class="pagination pagination-rounded">
                        <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-left"></i></a>
                        </li>
                        <li class="paginate_button page-item active"><a href="#" class="page-link">1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                        </li>
                        <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-right"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- End Page-content -->
  
  <!-- Add User Popup -->
  <!-- <modal id="addModules">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Add Modules</h5>
      <button type="button" class="close" (click)="closeModal('addModules');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Module Name (English)</label>
              <input type="name" class="form-control">
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Module Name (Amharic)</label>
              <input type="name" class="form-control">
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-warning">Save</button>
       </div>
    </div>
  </modal> -->
  <!-- End Add Modules Popup -->
  
  <!-- Edit  Modules Popup -->
  <modal id="editModules">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Edit Modules</h5>
      <button type="button" class="close" (click)="closeModal('editModules');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Module Name (English)</label>
              <input type="name" class="form-control">
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Module Name (Amharic)</label>
              <input type="name" class="form-control">
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-warning">Save</button>
       </div>
    </div>
  </modal>
  <!-- End Edit  User Popup -->

  <app-modules></app-modules>
  