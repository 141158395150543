import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressService {

  constructor(
    private imageCompress: NgxImageCompressService
  ) { }

  async compressFile(image: any, file: File, returnable: "dataUrl" | "file") {
    let result = await this.imageCompress.compressFile(image, -1, 60, 50);
    let imageBlob = this.dataURItoBlob(result.split(',')[1], file.type);
    let imageFile = new File([imageBlob], file.name, { type: file.type });
    let size = imageFile.size / 1024;
    while (size > 50) {
      result = await this.imageCompress.compressFile(result, -1, 60, 50);
      imageBlob = this.dataURItoBlob(result.split(',')[1], file.type);
      imageFile = new File([imageBlob], file.name, { type: file.type });
      size = imageFile.size / 1024;
    }
    if (returnable == "file") {
      return { compressedImage: imageFile, dataUri: result };
    } else if (returnable == "dataUrl") {
      return result;
    }
  }

  dataURItoBlob(dataURI, type: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { "type": type });
    return blob;
  }

  dataURItoBlobForPDF(dataURI, type: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { "type": type });
    return blob;
  }
}
