 <div class="page_policy"> 
	<div class="header">
		<div class="container">
			<a href="#" class="logo">
				<img src="assets/images/logo-dark.png">
			</a>
		</div>
	</div>
	<div class="content-page">
		<div class="container">
			<h1 class="heading-title text-center">Privacy Policy</h1>
			<div class="content" [innerHtml]="languageType == 'en'? privacypolicy?.en: privacypolicy?.am">
				 
			</div>
		</div>
	</div>
	<footer class="copyright">© 2021 Splash Remote Learning. All rights reserved.</footer>
</div>
 


 