<!-- <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item" [routerLink]="['/admin/sms']">Training Programs</li>
                <li class="breadcrumb-item" [routerLink]="['/admin/sms-modules']">Modules</li>
                <li class="breadcrumb-item" [routerLink]="['/admin/sms-chapters']">Chapters</li>
                <li class="breadcrumb-item active">Assessment</li>
              </ol>
              <ol class="breadcrumb m-0">
  
              </ol>
            </div>
            <div class="page-title-right2">
              <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                (click)="openModal('reorder')">Reorder</button>
              <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                (click)="openModal('addAssessment')">Add Question</button>
            </div>
          </div>
        </div>
      </div>
      <div class="section-assessment">
        <div class="row" id="table-2">
          <div id="1" class="col-xl-12">
            <div class="card table_page">
              <div class="card-body">
                <div class="section-question mt-3">
                  <div class="question-row">
                    <div class="question_header">
                      <div class="q-title">1. <span>What products are you interested in today?<br>ዛሬ የትኞቹን ምርቶች ይፈልጋሉ?
                        </span>
                      </div>
                      <span class="action_btn">
                        <button type="button" class="mr-2 text-primary btn_style" (click)="openModal('editAssessment')"><i
                            class="mdi mdi-pencil font-size-18"></i></button>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </span>
                    </div>
                    <table class="table mb-0 question-box">
                      <thead class="thead-light">
                        <tr>
                          <th style="width:30px;">No.</th>
                          <th>Choices (English)</th>
                          <th>Choices (Amharic)</th>
                          <th style="width: 110px;">Correct Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row" data-title="No.">1</td>
                          <td data-title="Choices (English)">Windows</td>
                          <td data-title="Choices (Amharic)">Windows</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">2</td>
                          <td data-title="Choices (English)">Siding</td>
                          <td data-title="Choices (Amharic)">Siding</td>
                          <td data-title="Correct Answer"><button type="button" class="mr-2 btn_success btn_style"><i
                                class="mdi mdi-check-bold font-size-18"></i></button></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">3</td>
                          <td data-title="Choices (English)">Roofing</td>
                          <td data-title="Choices (Amharic)">Roofing</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">4</td>
                          <td data-title="Choices (English)">Gutter Protection</td>
                          <td data-title="Choices (Amharic)">Gutter Protection</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">5</td>
                          <td data-title="Choices (English)">Gutters</td>
                          <td data-title="Choices (Amharic)">Gutters</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
          <div id="2" class="col-xl-12">
            <div class="card table_page">
              <div class="card-body">
                <div class="section-question mt-3">
                  <div class="question-row">
                    <div class="question_header">
                      <div class="q-title">2. <span>What products are you interested in today?<br>ዛሬ የትኞቹን ምርቶች ይፈልጋሉ?
                      </span>
                      </div>
                      <span class="action_btn">
                        <button type="button" class="mr-2 text-primary btn_style" (click)="openModal('editAssessment')"><i
                            class="mdi mdi-pencil font-size-18"></i></button>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </span>
                    </div>
                    <table class="table mb-0 question-box">
                      <thead class="thead-light">
                        <tr>
                          <th style="width:30px;">No.</th>
                          <th>Choices (English)</th>
                          <th>Choices (Amharic)</th>
                          <th style="width: 110px;">Correct Answer</th>
  
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row" data-title="No.">1</td>
                          <td data-title="Choices (English)">House (Single Residence)</td>
                          <td data-title="Choices (Amharic)">House (Single Residence)</td>
                          <td data-title="Correct Answer"><button type="button" class="mr-2 btn_success btn_style"><i
                                class="mdi mdi-check-bold font-size-18"></i></button></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">2</td>
                          <td data-title="Choices (English)">House (Multiple Residence)</td>
                          <td data-title="Choices (Amharic)">House (Multiple Residence)</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">3</td>
                          <td data-title="Choices (English)">Condominium</td>
                          <td data-title="Choices (Amharic)">Condominium</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">4</td>
                          <td data-title="Choices (English)">Apartment</td>
                          <td data-title="Choices (Amharic)">Apartment</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">5</td>
                          <td data-title="Choices (English)">Mobile Home</td>
                          <td data-title="Choices (Amharic)">Mobile Home</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">6</td>
                          <td data-title="Choices (English)">Other</td>
                          <td data-title="Choices (Amharic)">Other</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
  
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="3" class="col-xl-12">
            <div class="card table_page">
              <div class="card-body">
                <div class="section-question mt-3">
                  <div class="question-row">
                    <div class="question_header">
                      <div class="q-title">3. <span>What products are you interested in today?<br>ዛሬ የትኞቹን ምርቶች ይፈልጋሉ?
                      </span>
                      </div>
                      <span class="action_btn">
                        <button type="button" class="mr-2 text-primary btn_style" (click)="openModal('editAssessment')"><i
                            class="mdi mdi-pencil font-size-18"></i></button>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </span>
                    </div>
                    <table class="table mb-0 question-box">
                      <thead class="thead-light">
                        <tr>
                          <th style="width:30px;">No.</th>
                          <th>Choices (English)</th>
                          <th>Choices (Amharic)</th>
                          <th style="width: 110px;">Correct Answer</th>
  
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row" data-title="No.">1</td>
                          <td data-title="Choices (English)">Aluminium Siding</td>
                          <td data-title="Choices (Amharic)">Aluminium Siding</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">2</td>
                          <td data-title="Choices (English)">Brick</td>
                          <td data-title="Choices (Amharic)">Brick</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">3</td>
                          <td data-title="Choices (English)">Vinyl Siding</td>
                          <td data-title="Choices (Amharic)">Vinyl Siding</td>
                          <td data-title="Correct Answer"><button type="button" class="mr-2 btn_success btn_style"><i
                                class="mdi mdi-check-bold font-size-18"></i></button></td>
  
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">4</td>
                          <td data-title="Choices (English)">Other</td>
                          <td data-title="Choices (Amharic)">Other</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">5</td>
                          <td data-title="Choices (English)">Vinyl Siding</td>
                          <td data-title="Choices (Amharic)">Vinyl Siding</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">6</td>
                          <td data-title="Choices">Wood</td>
                          <td data-title="Choices">Wood</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="4" class="col-xl-12">
            <div class="card table_page">
              <div class="card-body">
                <div class="section-question mt-3">
                  <div class="question-row">
                    <div class="question_header">
                      <div class="q-title">4. <span>What products are you interested in today?<br>ዛሬ የትኞቹን ምርቶች ይፈልጋሉ?
                      </span>
                      </div>
                      <span class="action_btn">
                        <button type="button" class="mr-2 text-primary btn_style" (click)="openModal('editAssessment')"><i
                            class="mdi mdi-pencil font-size-18"></i></button>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </span>
                    </div>
                    <table class="table mb-0 question-box">
                      <thead class="thead-light">
                        <tr>
                          <th style="width:30px;">No.</th>
                          <th>Choices (English)</th>
                          <th>Choices (Amharic)</th>
                          <th style="width: 110px;">Correct Answer</th>
  
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row" data-title="No.">1</td>
                          <td data-title="Choices (English)">Yes</td>
                          <td data-title="Choices (Amharic)">Yes</td>
                          <td data-title="Correct Answer"><button type="button" class="mr-2 btn_success btn_style"><i
                                class="mdi mdi-check-bold font-size-18"></i></button></td>
                        </tr>
                        <tr>
                          <td scope="row" data-title="No.">2</td>
                          <td data-title="Choices (English)">No</td>
                          <td data-title="Choices (Amharic)">No</td>
                          <td data-title="Correct Answer"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  
  </div> -->
  <!-- End Page-content -->
  
  <app-assessment></app-assessment>
  
  <!-- Add Assessment Popup -->
  <!-- <modal id="addAssessment" class="popup_assessment">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Add Assessment</h5>
      <button type="button" class="close" (click)="closeModal('addAssessment');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <app-sms-add-assessment></app-sms-add-assessment>
    </div>
  </modal> -->
  <!-- End Add Assessment Popup -->
  
  <!-- Edit  Assessment Popup -->
  <!-- <modal id="editAssessment" class="popup_assessment">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Edit Assessment</h5>
      <button type="button" class="close" (click)="closeModal('editAssessment');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <app-sms-edit-assessment></app-sms-edit-assessment>
    </div>
  </modal> -->
  <!-- End Edit  Assessment Popup -->
  
  <!-- Reorder Popup -->
  <modal id="reorder">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Reorder (Assessment)</h5>
      <button type="button" class="close" (click)="closeModal('reorder');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <app-sms-reorder-assessment></app-sms-reorder-assessment>
    </div>
  </modal>
  <!-- End Reorder Popup -->
  