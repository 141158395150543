<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Expert Q&A</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter">
                    <label>
                      <select id="selectType" class="custom-select custom-select-sm form-control"
                        [(ngModel)]="expartStatus" (change)="getExpertQaList(1)">
                        <option value="" selected>Select Status</option>
                        <option value="pending">Pending</option>
                        <option value="inProgress">In Progress</option>
                        <option value="resolved">Resolved</option>
                      </select>
                      <input type="search" class="form-control form-control-sm" placeholder="Search.."
                        [(ngModel)]="searchKeyword" (input)="getExpertQaList(1)">
                    </label>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th style="width:115px;">Support ID #</th>
                    <th style="width:90px;">User Detail</th>
                    <th style="width:135px;">Date / Time</th>
                    <th style="width:75px;">Status</th>
                    <th>Last User Message</th>
                    <th style="width:60px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="tr-Bg" *ngFor="let list of ExpertQaList | paginate: {
                    id: 'ExpertQaList',
                    itemsPerPage: limit,
                    currentPage: page,
                    totalItems: entries?.total
                  }; let i = index;">
                    <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                    <td data-title="Support ID #">{{list?.supportId}}</td>
                    <td data-title="User Detail">
                      <div class="user-info">
                        <!-- <div class="bg_img"
                          style="background-color: rgb(248, 249, 250); background-image: url({{list?.senderName?.profileImage}});">
                          <span class="show-mess">{{list?.messageCount}}</span>
                        </div> -->
                        <div class="bg_img"
                          style="background-color: rgb(248, 249, 250); background-image: url(assets/images/users/admin.jpg);"
                          [ngStyle]="{'background-image': list?.senderName?.profileImage ? 'url('+list?.senderName?.profileImage+')' : 'url(assets/images/users/admin.jpg)'}">
                          <span class="show-mess" *ngIf="list.messageCount > 0">{{list?.messageCount}}</span>
                        </div>
                        <span> {{list?.senderName?.name}}
                          <br>{{list?.senderName?.mobileNumber}}</span>
                      </div>
                    </td>
                    <td data-title="Date / Time">{{list?.timestamp | date}}</td>
                    <td data-title="Status">
                      <span class="badge badge-warning" *ngIf="list?.status == 'pending'">Pending</span>
                      <span class="badge badge-danger" *ngIf="list?.status == 'inProgress'">In Progress</span>
                      <span class="badge badge-success" *ngIf="list?.status == 'resolved'">Resolved</span>
                    </td>
                    <td data-title="Last Message">
                      <p class="message">{{list?.message}}</p>
                    </td>
                    <td data-title="Actions">
                      <button type="button" class="mr-2 text-primary btn_style" (click)="doAction('edit',list)"><i
                          class="mdi mdi-chat-processing-outline font-size-18"></i></button>
                      <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(list?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !ExpertQaList?.length">
                    <td colspan="4">
                      <h5 class="text-center">Q&A not found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && ExpertQaList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
                    {{entries?.total}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="ExpertQaList"
                      (pageChange)="getExpertQaList($event)"></pagination-controls>
                    <!-- <ul class="pagination pagination-rounded">
                      <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-left"></i></a>
                      </li>
                      <li class="paginate_button page-item active"><a href="#" class="page-link">1</a>
                      </li>
                      <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                      </li>
                      <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-right"></i></a>
                      </li>
                    </ul> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="chat-box  mb-4">
          <div class="chat-blank card" *ngIf="!action">
            <div class="card-body">
              <p>Select query on left side.</p>
            </div>
          </div>
          <div class="card" *ngIf="action">
            <div class="card-body border-bottom">
              <div class="user-chat-border">
                <h5 class="font-size-15  avatar-img"> {{userChat[0]?.senderName?.name}}</h5>
                <ul class="list-inline user-chat-nav text-right mb-0">
                  <li class="list-inline-item">
                    <select class="form-control form-control-sm uptodown-icon" id="status-type" [(ngModel)]="status"
                      (change)="updateMessageStatus()" [disabled]="status == 'resolved'">
                      <option value="" selected>Select Status</option>
                      <option value="pending">Pending</option>
                      <option value="inProgress">In Progress</option>
                      <option value="resolved">Resolved</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="chat-widget">
                <div class="chat-conversation" data-simplebar="init" style="max-height: 292px;">
                  <div class="simplebar-wrapper" style="margin: 0px;">
                    <div class="simplebar-height-auto-observer-wrapper">
                      <div class="simplebar-height-auto-observer"></div>
                    </div>
                    <div class="simplebar-mask">
                      <div class="simplebar-offset" style="right: -17px; bottom: 0px;">
                        <div class="simplebar-content-wrapper" id="chat-inbox"
                          style="height: auto; overflow: hidden scroll;">
                          <div class="simplebar-content" style="padding: 0px;">
                            <ul class="list-unstyled mb-0 pr-3">
                              <li *ngFor="let msg of userChat"
                                [ngClass]="[msg.sendBy === 'user' ? '' : 'right',msg.mediaMessage != ''? '' : 'attachment']">
                                <div class="conversation-list">
                                  <div class="chat-avatar" *ngIf="msg.sendBy === 'user'">
                                    <!-- <img src="{{msg?.senderName?.profileImage}}" alt="" > -->

                                    <div class="bg_img"
                                      style="background-color: rgb(248, 249, 250); background-image: url(assets/images/users/admin.jpg);"
                                      [ngStyle]="{'background-image': msg?.senderName?.profileImage ? 'url('+msg?.senderName?.profileImage+')' : 'url(assets/images/users/admin.jpg)'}">
                                    </div>
                                  </div>
                                  <div class="ctext-wrap">
                                    <div class="conversation-name">{{msg?.senderName?.name}}</div>

                                    <div class="ctext-wrap-content">
                                      <p class="mb-0">{{msg?.message}}</p>
                                      <p class="mb-0" *ngIf="msg.mediaMessage != ''">

                                        <img src="{{msg.mediaMessage}}" alt="">

                                      </p>
                                    </div>
                                    <!-- <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle mr-1"></i>
                                      12:09</p> -->
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="simplebar-placeholder" style="width: auto; height: 946px;"></div>
                  </div>
                  <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                    <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;">
                    </div>
                  </div>
                  <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                    <div class="simplebar-scrollbar"
                      style="height: 90px; transform: translate3d(0px, 171px, 0px); display: block;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3 chat-input-section border-top">
              <div class="row">
                <div class="col">
                  <div>
                    <input type="text" class="form-control rounded chat-input pl-3" placeholder="Enter Message..."
                      [(ngModel)]="message" [disabled]="userChat[0]?.status == 'resolved'"
                      (keypress)="replyOnEnter($event)" (input)="getMessage()">
                  </div>
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-primary chat-send w-md waves-effect waves-light"
                    (click)="replyMessage()" [disabled]="userChat[0]?.status == 'resolved' || (!btnDisable)"><span
                      class="d-none d-sm-inline-block mr-2">Send</span> <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->
<div class="modal fade alert_popup" id="delete-offer" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Chat</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteChat()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>