import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';
import { UserservicesService } from 'src/app/services/user/userservices.service';
import { find, get, pull } from 'lodash';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ImageSettingsModel, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
declare var $: any;
@Component({
  selector: 'app-add-program',
  templateUrl: './add-program.component.html',
  styleUrls: ['./add-program.component.scss']
})
export class AddProgramComponent implements OnInit {

  @ViewChild('tagInput') tagInputRef: ElementRef;
  public insertImageSettings: ImageSettingsModel = { allowedTypes: ['.jpeg', '.jpg', '.png'], display: 'inline', width: 'auto', height: 'auto', saveFormat: 'Base64', saveUrl: null, path: null, }

  private model: any = {
    alertMsg: "<p>Sample content</p>"
  };
  fileToUpload: File = null;
  image_upload: string = '';
  searchKeyword: string;
  addForm: FormGroup;
  formSubmitted: boolean;
  trainingList: any = [];
  tagList: any = [];
  trainingDetail: any;
  remainingEn:number = 0
  messagesEn:number = 0;
  remainingAm:number = 0
  messagesAm:number = 0;
  routeUrl:any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,

    height: '15rem',
    minHeight: '5rem',
    // placeholder: 'Enter text here...',
    // translate: 'yes',
    // defaultParagraphSeparator: 'p',
    // showToolbar: true,
    // defaultFontName: 'Roboto',
    // defaultFontSize: '2',

    toolbarHiddenButtons: [
      [
        'insertVideo',
        'fontName',
        'fontSize'
      ]
    ],

  };
  constructor(
    private commonService: CommonService,
    private modalService: ModalService,
    private events: EventsService,
    private trainingService: TrainingService,
    private userService: UserservicesService, private elementRef: ElementRef
  ) {
    this.routeUrl = this.commonService.getRouterUrl();
    this.events.subscribe(EventsKey.edit, (data) => {

      if (data) {
        this.getAlltags();

        this.tagList = [];
        this.trainingDetail = data;
        this.image_upload = this.trainingDetail.trainingImage;

        this.addForm.patchValue({
          "trainingName": {
            "en": this.trainingDetail.trainingName.en,
            "am": this.trainingDetail.trainingName.am
          },
          "description": {
            "en": this.trainingDetail.description.en,
            "am": this.trainingDetail.description.am
          },
        })
        this.trainingDetail.tagsId.forEach(x => {
          let tagName = this.trainingList.find((e) => e._id == x);
          this.tagList.push(tagName.tagsName);
        });
      } else {
        this.formSubmitted = false;
        this.addForm.reset();
        this.trainingDetail = null;
        this.image_upload = '';
        this.tagList = [];

      }
      this.searchlistShow = false;
    })
    this.addForm = new FormGroup({
      "trainingImage": new FormControl('', Validators.compose([Validators.required])),
      "trainingName": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "description": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "searchKeyword": new FormControl(''),
    })
  }

  public rteObj;
  public onRTECreate(e: any): void {
    this.rteObj.refreshUI();
  }
  ngOnInit(): void {
    this.getAlltags();
  }

  smsCount(action){
    // let chars;
    // if(action == 'en'){
    //   chars = this.addForm.value.description.en.length;
    //   this.messagesEn = Math.ceil(chars / 160);
    //   this.remainingEn = this.messagesEn * 160 - (chars % (this.messagesEn * 160) || this.messagesEn * 160);
    // }else{
    //   chars = this.addForm.value.description.am.length;
    //   this.messagesAm = Math.ceil(chars / 160);
    //   this.remainingAm = this.messagesAm * 160 - (chars % (this.messagesAm * 160) || this.messagesAm * 160);
    // }
    let count;
    if(action == 'en'){
      count =  this.commonService.messageCount(this.addForm.value.description.en.length )
      this.messagesEn = count.messages;
      this.remainingEn = count.remaining;
    }else{
     count = this.commonService.messageCount(this.addForm.value.description.am.length )
     this.messagesAm = count.messages;
     this.remainingAm = count.remaining;
   
    }

   
  }

  // selectImage(files: FileList) {
  //   this.fileToUpload = files.item(0);
  //   this.uploadImage();
  // }
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  selectImage(event) {
    this.imageErrMsg = '';
    var file: File = event.target.files[0];
    this.currentFile = file;
    var imagetype = this.currentFile.type.split('/');
    var sizeInMB = (Math.round(this.currentFile.size * 100 / (1024 * 1024)) / 100).toString();
    if (parseInt(sizeInMB) > 10) {
      this.commonService.showErrorToastMsg('', 'Only 10 MB image allowed');
    }
    // jpeg, jpg, png,webp, gif, & ico
    let ValidImageExt = ["jpeg", "png", "jpg", "webp", "gif", "x-icon"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Only jpg, jpeg ,webp,gif & x-icon image formats are allowed";
      this.commonService.showErrorToastMsg('', this.imageErrMsg);
      this.addForm.controls['trainingImage'].setValue('');
      return false;
    }
    this.fileToUpload = this.currentFile;
    var frmData = new FormData();
    frmData.append("profileImg", this.fileToUpload);
    this.uploadImage(frmData)
  }
  uploadImage(frmData) {
    this.commonService.presentSpinner();
    // var frmData = new FormData();
    // frmData.append("profileImg", this.fileToUpload);
    this.userService.imageUpload(frmData).then(
      (res: any) => {
        if (res.code == 200) {
          this.image_upload = res.image_upload;
          this.commonService.dismissSpinner();
        }
      }
    ).finally(() => {

      this.commonService.dismissSpinner();

    })
  }

  getAlltags(name?: string) {
    this.trainingService.getAllTags({ "search": name, }).then(
      ({ trainingList }) => {
        if (trainingList) {
          this.trainingList = trainingList;

        } else {
          this.trainingList = []
        }
      }
    ).catch((error) => {
      this.trainingList = []
    })
  }

  searchlistShow: boolean = false;
  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.addForm.controls.searchKeyword.value;
    if (inputValue.length > 0) {
      this.searchlistShow = true;
    } else {
      this.searchlistShow = false;
    }
    this.getAlltags(inputValue)
    if (event.code === 'Backspace' && !inputValue) {
      // this.removeitem();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {

        this.addTag(inputValue);
        this.addForm.controls.searchKeyword.setValue('');
      }
    }
  }
  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.tagList, tag)) {
      let findTag = this.tagList.find((x) => x === tag);
      if (!findTag) {
        this.tagList.push(tag.toLowerCase());

      }
    }
  }
  // removeitem(i) {
  //   this.tagList.splice(i, 1)
  // }
  removeitem(tag?: string): void {
    if (!!tag) {
      pull(this.tagList, tag);
    } else {
      this.tagList.splice(-1);
    }
  }
  showtags(tags: any) {
    this.addForm.patchValue({
      searchKeyword: tags.tagsName
    })
    this.tagList.push(tags.tagsName)
    this.trainingList = [];
  }
  savetraining() {
    this.formSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }
    let payload = {};

    payload = {
      "trainingId": "",
      "trainingImage": this.image_upload,
      "trainingName": {
        "en": this.addForm.value.trainingName.en,
        "am": this.addForm.value.trainingName.am
      },
      "description": {
        "en": this.addForm.value.description.en,
        "am": this.addForm.value.description.am,
      },
      "tagsId": this.tagList
    }

    // console.log("payload", payload)
    this.commonService.presentSpinner();
    this.trainingService.addUpdatetraining(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message);
        }
      }
    ).catch((error) => {
      console.log("error", error);
      this.commonService.showErrorToastMsg(error.error.message);
    }).finally(() => {
      this.events.publish(EventsKey.successfullyaddupdate, '');
      this.commonService.dismissSpinner();
      this.formSubmitted = false;
      this.addForm.reset();
      this.tagList = [];
      // this.trainingList = [];
      this.image_upload = '';
      this.getAlltags();
      this.modalService.close('addProgram');
    })
  }

  updateTraining() {

    if (this.addForm['controls'].trainingName['controls'].en.invalid || this.addForm['controls'].trainingName['controls'].am.invalid || this.addForm['controls'].description['controls'].en.invalid || this.addForm['controls'].description['controls'].am.invalid) {
      return;
    }
    let payload = {};
    this.formSubmitted = true;
    payload = {
      "trainingId": this.trainingDetail._id,
      "trainingImage": this.image_upload,
      "trainingName": {
        "en": this.addForm.value.trainingName.en,
        "am": this.addForm.value.trainingName.am
      },
      "description": {
        "en": this.addForm.value.description.en,
        "am": this.addForm.value.description.am,
      },
      "tagsId": this.tagList
    }
    this.commonService.presentSpinner();
    this.trainingService.addUpdatetraining(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message);
        }
      }
    ).catch((error) => {
      console.log("error", error)
    }).finally(() => {
      this.events.publish(EventsKey.successfullyaddupdate, '');
      this.commonService.dismissSpinner();
      this.formSubmitted = false;
      this.addForm.reset();
      this.tagList = [];
      // this.trainingList = [];
      this.image_upload = '';
      this.getAlltags();
      this.trainingDetail = null;
      this.modalService.close('addProgram');
    })
  }
}
