import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { Chapter } from 'src/app/services/training/addmodule.model';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-add-chapter',
  templateUrl: './add-chapter.component.html',
  styleUrls: ['./add-chapter.component.scss']
})
export class AddChapterComponent implements OnInit {
  @Input() ids: any
  addForm: FormGroup;
  formSubmitted: boolean;
  chapterDetail: any;
  routeUrl: any;
  remainingEn: number = 0
  messagesEn: number = 0;
  remainingAm: number = 0
  messagesAm: number = 0;
  constructor(
    private events: EventsService,
    private trainingService: TrainingService,
    private commonService: CommonService,
    private modalService: ModalService,
  ) {
    this.routeUrl = this.commonService.getRouterUrl();
    this.events.subscribe(EventsKey.edit, (data) => {
      if (data) {
        this.chapterDetail = data;
        this.addForm.patchValue(this.chapterDetail)
      } else {
        this.chapterDetail = null;
        this.addForm.reset();
        this.formSubmitted = false;
      }

    })
    this.addForm = new FormGroup({
      "chapterName": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "description": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "readTime": new FormControl('', Validators.compose([Validators.required]))
    })
  }

  ngOnInit(): void {

  }

  smsCount(action) {
    let count;
    if (action == 'en') {
      count = this.commonService.messageCount(this.addForm.value.description.en.length)
      this.messagesEn = count.messages;
      this.remainingEn = count.remaining;
    } else {
      count = this.commonService.messageCount(this.addForm.value.description.am.length)
      this.messagesAm = count.messages;
      this.remainingAm = count.remaining;
    }
  }

  numberOnly(event, controlName?: string): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (controlName) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }
  }
  saveChapter() {
    // console.log("ids?.trainingProgramsId", this.ids.trainingProgramsId)
    // console.log("ids?.modulesId", this.ids.modulesId)
    this.formSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }
    let payload = new Chapter(this.addForm.value);
    payload.trainingProgramsId = this.ids.trainingProgramsId;
    payload.modulesId = this.ids.modulesId;
    if (this.chapterDetail) {
      payload.chaptersId = this.chapterDetail._id;
    } else {
      payload.chaptersId = '';
    }
    this.commonService.presentSpinner();
    this.trainingService.addUpdateChapter(payload).then(
      (res: any) => {

      }
    ).catch((error) => {

    }).finally(() => {
      this.events.publish(EventsKey.successfullyaddupdate, '');
      this.commonService.dismissSpinner();
      this.modalService.close('addChapters');
      this.chapterDetail = null;
    })
  }

}
