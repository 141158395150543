<div class="from">
  <!-- program-editor -->

  <form [formGroup]="addForm">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Picture</label>
              <div class="custom_file">
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="customFile" formControlName="trainingImage"
                    (change)="selectImage($event)">
                  <label class="custom-file-label" for="customFile">Choose file</label>
                </div>
                <img src="{{image_upload}}" alt="" *ngIf="image_upload">
              </div>
              <div *ngIf="!trainingDetail?._id">
                <div *ngIf="formSubmitted && !addForm?.value?.trainingImage" class="error-msg">
                  <span>Required!</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">

        <div class="form-group" formGroupName="trainingName">
          <label>Training Name (English)</label>
          <input type="name" class="form-control" formControlName="en">
          <div *ngIf="formSubmitted && !addForm?.value?.trainingName?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="description">
          <!-- description -->
          <label>Description (English)</label>

          <!-- <angular-editor id="editor1" formControlName="en" [config]="config" [fullPage]="true">
          </angular-editor> -->
          <!-- <ejs-richtexteditor #alertMsg [insertImageSettings]='insertImageSettings' formControlName="en" required>
          </ejs-richtexteditor> -->
          <textarea name="en" id="" cols="30" rows="10" formControlName="en" class="form-control" (input)="smsCount('en')"></textarea>
         <span *ngIf="routeUrl[2] == 'sms'">{{remainingEn}} characters remaining {{messagesEn}}  message(s)</span>  
          <div *ngIf="formSubmitted && !addForm?.value?.description?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- <div class="form-group">
        <label>Picture (Amharic)</label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label" for="customFile">Choose file</label>
        </div>
      </div> -->
        <div class="form-group" formGroupName="trainingName">
          <label>Training Name (Amharic)</label>
          <input type="name" class="form-control" formControlName="am">
          <div *ngIf="formSubmitted && !addForm?.value?.trainingName?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="description">
          <label>Description (Amharic)</label>
          <!-- <angular-editor id="editor1" formControlName="am" [config]="config"></angular-editor> -->
          <!-- <ejs-richtexteditor #alertMsg [insertImageSettings]='insertImageSettings' formControlName="am" required>
          </ejs-richtexteditor> -->
          <textarea name="am" id="" cols="30" rows="10" formControlName="am" class="form-control" (input)="smsCount('am')"></textarea>
          <span *ngIf="routeUrl[2] == 'sms'">{{remainingAm}} characters remaining {{messagesAm}}  message(s)</span>
          <div *ngIf="formSubmitted && !addForm?.value?.description?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Tags</label>
          <div class="data">
            <div class="data-value" *ngFor="let list of tagList; let i = index"><span
                class="data-value-label">{{list}}</span><span class="data-value-icon"
                (click)="removeitem(list)">×</span>
            </div>
          </div>
          <input type="search" class="form-control" formControlName="searchKeyword" #tagInput (keyup)="onKeyUp($event)"
            placeholder="Select tag">
          <div *ngIf="formSubmitted && !this.tagList.length" class="error-msg">
            <span>Required!</span>
          </div>

          <ul class="search-list" *ngIf="trainingList.length && searchlistShow">
            <li class="search-list-item" *ngFor="let tag of trainingList" (click)="showtags(tag)">{{tag?.tagsName}}</li>
            <!-- <li class="search-list-item">a</li>
            <li class="search-list-item">a</li>
            <li class="search-list-item">a</li> -->
          </ul>
        </div>
      </div>
    </div>
  </form>
  <button type="submit" class="btn btn-warning"
    (click)="trainingDetail ? updateTraining() : savetraining()">Save</button>
</div>