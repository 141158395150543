import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { GlobalassessmentService } from 'src/app/services/AssessmentQuestion/globalassessment.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';
declare var $: any;

@Component({
  selector: 'app-add-assessment',
  templateUrl: './add-assessment.component.html',
  styleUrls: ['./add-assessment.component.scss']
})
export class AddAssessmentComponent implements OnInit {
  @Input() ids: any
  otherAdded: boolean;
  addQuestion: FormGroup;
  answertype: any;
  options: any;
  curerctansweryes: boolean = false;
  curerctanswerno: boolean = false;
  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private assessmentServices: GlobalassessmentService,
    private events: EventsService,
    private trainingService: TrainingService,
    private modalService: ModalService,
  ) {
    this.events.subscribe(EventsKey.openassessmentmodal, (data) => {
      if (data) {

      } else {
        this.resetAddQuestionForm();
        // this.addOptionsControl('addQuestion');
      }

    })
    this.addQuestion = this.assessmentServices.getAddAssessmentQuestionForm();
    this.addOptionsControl('addQuestion');
    this.onAddQuestionTypeChange();
  }
  questionTypes = [

    { id: 1, value: 'multipleChoice', name: 'Multiple choice' },
    { id: 2, value: 'singleChoice', name: 'Single choice' },
    { id: 3, value: 'yesNoChoice', name: 'Yes/No' },
    // { id: 1, value: 'Checkboxes', name: 'Multiple choice' },
    // { id: 2, value: 'Multiple', name: 'Single choice' },
    // { id: 3, value: 'Yesno', name: 'Yes/No' },


  ];


  ngOnInit(): void {
    this.answertype = this.questionTypes[0].value
  }
  get f() {
    return this.addQuestion.controls
  }
  setHeight(element: HTMLTextAreaElement) {
    element.style.height = (element.scrollHeight) + 'px';
  }

  resetHeight(element: HTMLTextAreaElement) {
    element.style.height = '48px';
    element.style['overflow-y'] = 'hidden';
    return true;
  }

  onAddQuestionTypeChange() {
    this.addQuestion.controls['questionType'].valueChanges.subscribe(
      (value: 'singleChoice' | 'multipleChoice' | 'yesNoChoice') => {
        this.removeOptionsControl('addQuestion');
        this.options = {};
        switch (value) {

          case 'singleChoice':
            this.addOptionsControl('addQuestion', value);
            break;

          case 'multipleChoice':
            this.addOptionsControl('addQuestion', value);
            break;
          case 'yesNoChoice':
            this.addOptionsControl('addQuestion', value);
            break;
        }
      }
    );
  }
  createOptionsForm(index: number) {
    let text = (index > -1 ? ('Option ' + (index + 1)) : 'Other');
    return new FormGroup({
      // questionId: new FormControl(),
      "option": new FormGroup({
        "en": new FormControl(text + ' English'),
        "am": new FormControl(text + ' Amharic')
      }),
      optionType: new FormControl(),
      correctAnswer: new FormControl(false, Validators.compose([Validators.required]))
    });
  }
  addOptionsControl(type: 'addQuestion' | 'editQuestion', value?: string) {

    this[type].addControl('options', new FormArray([this.createOptionsForm(0)]));
    this.options = this[type].controls['options']['controls'];

  }
  removeOptionsControl(type: 'addQuestion' | 'editQuestion') {
    this[type].removeControl('options');
    // this.resetAddQuestionForm();
  }
  submitted = false;
  resetAddQuestionForm() {
    this.submitted = false;
    let frmArray = this.addQuestion.get('options') as FormArray;
    frmArray.clear();
    frmArray.push(new FormGroup({
      "option": new FormGroup({
        "en": new FormControl('Option English'),
        "am": new FormControl('Option Amharic')
      }),
      optionType: new FormControl(),
      correctAnswer: new FormControl(false, Validators.compose([Validators.required]))
    }));
    this.addQuestion.patchValue({
      question: {
        en: '',
        am: ''
      }
    })
  }

  addMoreOptionsFields(option: string, formType: 'addQuestion' | 'editQuestion') {
    let optionsArray = this[formType].controls['options'] as FormArray;
    let group = this.createOptionsForm(option.length ? Number(option) : optionsArray.length);
    let count = 0;
    let othersIndex = -1;
    optionsArray.controls.forEach(
      (formGroup, index) => {
        if (formGroup.value.option.en == 'Other') {
          othersIndex = index;
          count += 1;
        }
      }
    );
    if (count > 0) {
      this.otherAdded = true;
    } else {
      if (group.value.option.en == 'Other') {
        this.otherAdded = true;
      } else {
        this.otherAdded = false;
      }
    }

    if (this.otherAdded) {
      if (othersIndex >= 0 && othersIndex < optionsArray.length) {
        let othersGroup = optionsArray.get([optionsArray.length - 1])
        optionsArray.removeAt(optionsArray.length - 1);
        group.patchValue({
          // 'option': 'Option ' + (optionsArray.length + 1)
          option: {
            en: 'Option ' + (optionsArray.length + 1),
            am: 'Option ' + (optionsArray.length + 1),
          }
        })
        optionsArray.push(group);
        optionsArray.push(othersGroup);
      } else {
        optionsArray.push(group);
      }
    } else {
      optionsArray.push(group);
    }

    this.options = this[formType].controls['options']['controls'];
  }


  optionsToDelete: Array<any> = [];
  removeOptionsFields(index: number, type: 'addQuestion' | 'editQuestion') {
    let options = this[type].controls['options'] as FormArray;
    if (type === 'editQuestion' && options.controls[index].value.optionId) {
      this.optionsToDelete.push({ optionId: options.controls[index].value.optionId, questionId: options.controls[index].value.questionId });
    }
    options.removeAt(index);
    let count = 0;
    options.controls.forEach(
      (formGroup) => {
        if (formGroup.value.option == 'Other') {
          count += 1;
        }
      }
    );
    if (count > 0) {
      this.otherAdded = true;
    } else {
      this.otherAdded = false;
    }

  }
  onRadioValueChange(formGroup: number, formType: 'addQuestion' | 'editQuestion', event) {
    let optionsArray = this[formType].controls['options'] as FormArray;
    optionsArray.controls.forEach(
      (form, index) => {
        form.patchValue({
          correctAnswer: index == formGroup ? true : false
        });
      }
    );
  }
  selectText(id: string) {
    if (id) {
      let inputValue: any = document.getElementById(id);
      inputValue.select();
    }
  }



  onChangeValue(formType: 'addQuestion' | 'editQuestion', e) {

    if (e.target.value == 'Yes') {
      this.curerctansweryes = true;
      this.curerctanswerno = false;

    } else {
      this.curerctansweryes = false;
      this.curerctanswerno = true;

    }
  }
  AddQuestion() {
    this.submitted = true;
    if (this.addQuestion.invalid) {
      return;
    }

    if (this.addQuestion.value.questionType == 'singleChoice' || this.addQuestion.value.questionType == 'multipleChoice') {
      let options: Array<any> = this.addQuestion.value.options;
      let count = 0
      options.forEach((option) => {
        if (option.correctAnswer) {
          count += 1
        }
      });
      if (!count) {
        this.commonService.showErrorToastMsg('Please select correct answer.')
        return;
      }
    }
    this.addQuestion.value.questionId = ''
    this.addQuestion.value.assessmentsId = this.ids.assessmentId || '';
    this.addQuestion.value.chaptersId = this.ids.chaptersId || '';
    this.addQuestion.value.modulesId = this.ids.modulesId || '';
    this.addQuestion.value.trainingProgramsId = this.ids.trainingProgramsId || '';
    if (this.addQuestion.value.questionType == 'multipleChoice') {
      this.addQuestion.value.options.forEach(e => {
        e.optionType = 'checkbox'
      });

    } else if (this.addQuestion.value.questionType == 'singleChoice') {
      this.addQuestion.value.options.forEach(e => {
        e.optionType = 'radio'
      });
    } else {

      let opt = [
        {
          "option": {
            "en": 'Yes',
            "am": "አዎ"
          },
          "optionType": "radio",
          "correctAnswer": this.curerctansweryes
        },
        {
          "option": {
            "en": 'No',
            "am": "አይ"
          },
          "optionType": "radio",
          "correctAnswer": this.curerctanswerno
        }
      ]
      this.addQuestion.value.options = opt
      // console.log(payload)
    }

    delete this.addQuestion.value.questionOption;
    delete this.addQuestion.value.questionOptionLabel;
    delete this.addQuestion.value.yesNoChoice;
    this.commonService.presentSpinner();
    this.trainingService.addUpdateQuestion(this.addQuestion.value).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message);
        }
      }
    ).catch((error) => {

    }).finally(() => {
      this.commonService.dismissSpinner();
      this.resetAddQuestionForm();
      this.events.publish(EventsKey.updateassessmentquestion, '');
      this.modalService.close('addAssessment');
    })
  }

}
