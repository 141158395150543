import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalassessmentService {

  constructor(
    private httpWrapper: HttpWrapperService
  ) { }

  getAddAssessmentQuestionForm() {
    return new FormGroup({
      questionType: new FormControl('Multiple'),
      "question": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),

      yesNoChoice: new FormControl(''),
      questionOption: new FormControl(''),
      questionOptionLabel: new FormControl(''),

    });
  }

  getEditAssessmentQuestionForm() {
    return new FormGroup({
      questionType: new FormControl('Multiple'),
      "question": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      yesChoice: new FormControl(''),
      NoChoice: new FormControl(''),
    });
  }
}
