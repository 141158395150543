<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item" [routerLink]="[routeUrl[2] == 'sms' ?'/admin/sms/sms': '/admin/program']">Training Programs</li>
              <li class="breadcrumb-item active">Modules</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
            (click)="openReorderModal('moduleModal')">Reorder</button>
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addModules')">Add</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <h4 class="card-title mb-4">Training Name:- {{moduleList[0]?.Training?.trainingName?.en}}</h4>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter">
                    <label>
                      <input type="search" class="form-control form-control-sm" placeholder="Search.."
                        [(ngModel)]="searchKeyword" (input)="getModules(1)">
                    </label>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th>Module Name</th>
                    <th style="width:140px;">Chapters </th>
                    <th style="width:85px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of moduleList | paginate: {
                    id: 'moduleList',
                    itemsPerPage: limit,
                    currentPage: page,
                    totalItems: entries?.total
                  }; let i = index;">
                    <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                    <td data-title="Module Name">{{list?.modulesName?.en}}<br>{{list?.modulesName?.am}}</td>
                    <td data-title="Chapters"><span class="badge badge-dark"
                        (click)="goToChapter(list)">{{list?.chaptersCount}}</span>
                    </td>

                    <td data-title="Actions">
                      <button type="button" (click)="openModal('addModules',list)"
                        class="mr-2 text-primary btn_style"><i class="mdi mdi-pencil font-size-18"></i>
                      </button>
                      <a (click)="goToChapter(list)" class="mr-2 text-solitud"><i
                          class="fas fa-eye font-size-18"></i></a>
                      <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(list?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !moduleList?.length">
                    <td colspan="4">
                      <h5 class="text-center">Training programs not found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && moduleList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
                    {{entries?.total}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="moduleList"
                      (pageChange)="getModules($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->

<!-- Add User Popup -->
<modal id="addModules">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{ToBeEdited ? 'Edit' : 'Add'}} Modules</h5>
    <button type="button" class="close" (click)="closeModal('addModules');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="moduleForm">
      <div class="form">
        <div class="row" formGroupName="modulesName">
          <div class="col-md-12">
            <div class="form-group">
              <label>Module Name (English)</label>
              <input type="name" class="form-control" formControlName="en">
              <div *ngIf="FormSubmitted && !moduleForm?.value?.modulesName?.en" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Module Name (Amharic)</label>
              <input type="name" class="form-control" formControlName="am">
              <div *ngIf="FormSubmitted && !moduleForm?.value?.modulesName?.am" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-warning" (click)="saveModule()">Save</button>
      </div>
    </form>
  </div>
</modal>
<!-- End Add Modules Popup -->

<!-- Edit  Modules Popup -->
<modal id="editModules">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Modules</h5>
    <button type="button" class="close" (click)="closeModal('editModules');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Module Name (English)</label>
            <input type="name" class="form-control">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Module Name (Amharic)</label>
            <input type="name" class="form-control">
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-warning">Save</button>
    </div>
  </div>
</modal>

<!-- Add Module Popup -->
<modal id="moduleModal" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reorder Training</h5>
    <button type="button" class="close" (click)="closeModal('moduleModal');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-module-reorder [moduleList]="moduleList"  (moduleReorderSuccess)="getData($event)"></app-module-reorder>
  </div>
</modal>
<!-- Edit Module Popup -->


<!-- End Edit  User Popup -->
<div class="modal fade alert_popup" id="delete-module" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Modules</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteModule()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>