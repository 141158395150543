
export declare type Settings = "aboutus";
export class Addmodule {
    modulesId: string;
    trainingProgramsId: string;
    modulesName: {
        en: string,
        am: string,
    }
    constructor(payload: {
        modulesId: string;
        trainingProgramsId: string;
        modulesName: {
            en: string,
            am: string,
        }
    }) {
        if (payload) {
            this.modulesId = payload.modulesId;
            this.trainingProgramsId = payload.trainingProgramsId;
            this.modulesName = {
                en: payload.modulesName.en,
                am: payload.modulesName.am
            }
        }
    }
}
export class Chapter {
    chaptersId: string;
    modulesId: string;
    trainingProgramsId: string;
    readTime: string;
    chapterName: {
        en: string,
        am: string,
    }
    description: {
        en: string,
        am: string,
    }
    constructor(payload: {
        chaptersId: string;
        modulesId: string;
        trainingProgramsId: string;
        readTime: string;
        chapterName: {
            en: string,
            am: string,
        }
        description: {
            en: string,
            am: string,
        }
    }) {
        if (payload) {
            this.chaptersId = payload.chaptersId;
            this.modulesId = payload.modulesId;
            this.trainingProgramsId = payload.trainingProgramsId;
            this.readTime = payload.readTime;
            this.chapterName = {
                en: payload.chapterName.en,
                am: payload.chapterName.am
            }
            this.description = {
                en: payload.description.en,
                am: payload.description.am
            }
        }
    }
}
export class Topic {
    topicsId: string;
    mediaUrl: string;
    chaptersId: string;
    modulesId: string;
    trainingProgramsId: string;
    readTime: string;
    narrative: boolean;
    fullNarrative: boolean;
    topicsName: {
        en: string,
        am: string,
    }
    description: {
        en: string,
        am: string,
    }
    fullNarritiveDescription: {
        en: string,
        am: string,
    }
    constructor(payload: {
        topicsId: string;
        mediaUrl: string;
        chaptersId: string;
        modulesId: string;
        trainingProgramsId: string;
        readTime: string;
        narrative: boolean;
        fullNarrative: boolean;
        topicsName: {
            en: string,
            am: string,
        }
        description: {
            en: string,
            am: string,
        }
        fullNarritiveDescription: {
            en: string,
            am: string,
        }
    }) {
        if (payload) {
            this.topicsId = payload.topicsId;
            this.mediaUrl = payload.mediaUrl;
            this.chaptersId = payload.chaptersId;
            this.modulesId = payload.modulesId;
            this.trainingProgramsId = payload.trainingProgramsId;
            this.readTime = payload.readTime;
            this.narrative = payload.narrative;
            this.fullNarrative = payload.fullNarrative;
            this.topicsName = {
                en: payload.topicsName.en,
                am: payload.topicsName.am
            }
            this.description = {
                en: payload.description.en,
                am: payload.description.am
            }
            this.fullNarritiveDescription = {
                en: payload.fullNarritiveDescription.en || '',
                am: payload.fullNarritiveDescription.am || ''
            }
        }
    }
}
export class addAssessments {
    assessmentsId: string;
    chaptersId: string;
    modulesId: string;
    trainingProgramsId: string;
    estimatedTime: string;
    title: {
        en: string,
        am: string,
    }
    description: {
        en: string,
        am: string,
    }
    constructor(payload: {
        assessmentsId: string;
        chaptersId: string;
        modulesId: string;
        trainingProgramsId: string;
        estimatedTime: string;
        narrative: boolean;
        title: {
            en: string,
            am: string,
        }
        description: {
            en: string,
            am: string,
        }
    }) {
        if (payload) {
            this.assessmentsId = payload.assessmentsId;
            this.chaptersId = payload.chaptersId;
            this.modulesId = payload.modulesId;
            this.trainingProgramsId = payload.trainingProgramsId;
            this.estimatedTime = payload.estimatedTime;
            this.title = {
                en: payload.title.en,
                am: payload.title.am
            }
            this.description = {
                en: payload.description.en,
                am: payload.description.am
            }
        }
    }
}