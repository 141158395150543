<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item" [routerLink]="['/admin/program']">Training Programs</li>
              <li class="breadcrumb-item" (click)="goBack()">Modules</li>
              <li class="breadcrumb-item" (click)="goBack()">Chapters</li>
              <li class="breadcrumb-item active">Assessment</li>
            </ol>
            <ol class="breadcrumb m-0">

            </ol>
          </div>
          <div class="page-title-right2">
            <!-- <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('reorder')">Reorder</button> -->
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openReorderModal()">Reorder</button>
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addAssessment')">Add Question</button>
          </div>
        </div>
      </div>
    </div>
    <div class="section-assessment">
      <div class="row form_assessment">
        <div class="col-md-12">
          <div class="card table_page" style="border-bottom:3px solid #354d9b;">
            <div class="card-body">
              <form [formGroup]="addassessmentForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group" formGroupName="title">
                      <label>Assessment Title (English)</label>
                      <input type="text" class="form-control" formControlName="en">
                      <div *ngIf="formSubmitted && !addassessmentForm?.value?.title?.en" class="error-msg">
                        <span>Required!</span>
                      </div>
                    </div>
                    <div class="form-group" formGroupName="description">
                      <label>Assessment Description (English)</label>
                      <textarea class="form-control" rows="3" formControlName="en"></textarea>
                      <div *ngIf="formSubmitted && !addassessmentForm?.value?.description?.en" class="error-msg">
                        <span>Required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" formGroupName="title">
                      <label>Assessment Title (Amharic)</label>
                      <input type="text" class="form-control" formControlName="am">
                      <div *ngIf="formSubmitted && !addassessmentForm?.value?.title?.am" class="error-msg">
                        <span>Required!</span>
                      </div>
                    </div>
                    <div class="form-group" formGroupName="description">
                      <label>Assessment Description (Amharic)</label>
                      <textarea class="form-control" rows="3" formControlName="am"></textarea>
                      <div *ngIf="formSubmitted && !addassessmentForm?.value?.description?.am" class="error-msg">
                        <span>Required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Estimated Time</label>
                      <input type="text" class="form-control" formControlName="estimatedTime">
                      <div *ngIf="formSubmitted && !addassessmentForm?.value?.estimatedTime" class="error-msg">
                        <span>Required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div style="display: block; text-align: right; margin-top: 30px;">
                      <button type="submit" class="btn btn-warning" (click)="addUpdateAssessment()">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="table-2">
        <div id="1" class="col-xl-12">
          <div class="card table_page" *ngFor="let assessment of assessmentList | paginate: {
                    id: 'assessmentList',
                    itemsPerPage: limit,
                    currentPage: page,
                    totalItems: entries?.total
                  }; let i = index;">
            <div class="card-body">
              <div class="section-question mt-3">
                <div class="question-row">
                  <div class="question_header">
                    <div class="q-title">{{ limit * (page - 1) + i + 1 }}.
                      <span>{{assessment?.question?.en}}<br>{{assessment?.question?.am}}
                      </span>
                    </div>
                    <span class="action_btn">

                      <button type="button" class="mr-2 text-primary btn_style"
                        (click)="openModal('editAssessment',assessment)"><i
                          class="mdi mdi-pencil font-size-18"></i></button>
                      <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(assessment?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </span>
                  </div>
                  <table class="table mb-0 question-box">
                    <thead class="thead-light">
                      <tr>
                        <th style="width:30px;">No.</th>
                        <th>Choices (English)</th>
                        <th>Choices (Amharic)</th>
                        <th style="width: 110px;">Correct Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ans of assessment?.options; let in = index">
                        <td scope="row" data-title="No.">{{in + 1}}</td>
                        <td data-title="Choices (English)">{{ans?.option?.en}}</td>
                        <td data-title="Choices (Amharic)">{{ans?.option?.am}}</td>
                        <td data-title="Correct Answer">
                          <button *ngIf="ans?.correctAnswer" type="button" class="mr-2 btn_success btn_style"><i
                              class="mdi mdi-check-bold font-size-18"></i></button>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
          <div *ngIf="apiHitDone && !assessmentList?.length">
            <td colspan="4">
              <h5 class="text-center">No question added yet!</h5>
            </td>
          </div>
          <div class="api-inline-loader" *ngIf="!apiHitDone">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->





<!-- Add Assessment Popup -->
<modal id="addAssessment" class="popup_assessment">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Assessment </h5>
    <button type="button" class="close" (click)="closeModal('addAssessment');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-assessment [ids]="sendId"></app-add-assessment>
  </div>
</modal>
<!-- End Add Assessment Popup -->

<!-- Edit  Assessment Popup -->
<modal id="editAssessment" class="popup_assessment">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Assessment </h5>
    <button type="button" class="close" (click)="closeModal('editAssessment');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-assessment [ids]="sendId"></app-edit-assessment>
  </div>
</modal>
<!-- End Edit  Assessment Popup -->

<!-- Reorder Popup -->
<modal id="reorder">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reorder (Assessment)</h5>
    <button type="button" class="close" (click)="closeModal('reorder');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-reorder-assessment></app-reorder-assessment>
  </div>
</modal>
<!-- End Reorder Popup -->

<div class="modal fade alert_popup" id="delete-module" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Assessments</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteAssessment()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="reorderAssessment" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Reorder (FAQ)</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!-- <div class="row"> -->
        <!-- <h2>Vertical</h2> -->
        <div #container2 dropListScroller cdkDropList cdkDropListLockAxis="y" cdkDropListOrientation="vertical"
          class="elements dropList_box" (cdkDropListDropped)="drop($event)">
          <div class="element" *ngFor="let tab of reorderAssessmentList ; let i = index" cdkDrag vsDragScroll
            [vsDragScrollContainer]="container2" class="vertical-scrool">
            <div class="number">{{i + 1}}</div>
            <span [innerHtml]="tab.question.en"></span>
            <span [innerHtml]="tab.question.am"></span>
          </div>
          <div *ngIf="apiHitDone && !reorderAssessmentList?.length">
            <h5 class="text-center">No record found!</h5>
          </div>

          <div class="api-inline-loader" *ngIf="!apiHitDone">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <button class="btn btn-warning" type="submit" (click)="reOrder()"
          *ngIf="reorderAssessmentList?.length">Save</button>
        <!-- </div> -->

      </div>
    </div>
  </div>
</div>