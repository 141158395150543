import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/modal/modal.service';
import { ResetPasswordForAdmin } from 'src/app/services/admin/admin.mode';
import { AdminService } from 'src/app/services/admin/admin.service';
import { CommonService, passwordMatchValidator } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  oldpassword: boolean;
  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  userDetail: any;
  adminForm: FormGroup;
  resetPasswordFormSubmitted: boolean;
  constructor(
    private adminService: AdminService,
    private commonService: CommonService,
    private modalService: ModalService,
    private events: EventsService,
  ) {
    this.events.subscribe(EventsKey.edit, (data) => {
      if (data) {
        this.userDetail = data;
        console.log("this.userDetail", this.userDetail)
      } else {

        this.userDetail = null
      }

    })
    this.adminForm = new FormGroup({

      "password": new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16)])),
      "confirmPassword": new FormControl('', Validators.compose([Validators.required])),
    }, [passwordMatchValidator]);
  }

  ngOnInit(): void {
  }
  showoldPassword() {
    this.oldpassword = !this.oldpassword;
  }

  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }
  resetPassword() {
    this.resetPasswordFormSubmitted = false;
    if (this.adminForm.invalid) {
      this.resetPasswordFormSubmitted = true;
      return;
    }
    let payload = new ResetPasswordForAdmin({
      "_id": this.userDetail._id,
      "password": this.adminForm.value.password,
      "confirmPassword": this.adminForm.value.confirmPassword
    });
    this.commonService.presentSpinner();
    this.adminService.resetPassword(payload).then(
      (res) => {
        if (res) {
          this.commonService.showSuccessToastMsg('Password updated!');
        } else {
          this.commonService.showErrorToastMsg("Something went wrong!");
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.commonService.showErrorToastMsg(error.error.message || "Something went wrong!");
        } else {
          this.commonService.showErrorToastMsg("Something went wrong!");
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.adminForm.reset();
        this.resetPasswordFormSubmitted = false;
        this.userDetail = null;
        this.events.publish(EventsKey.successfullyaddupdate, '');
        this.modalService.close('resetPassword');
      }
    )
  }
}
