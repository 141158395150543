import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
declare var $: any;

@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss']
})
export class ChaptersComponent implements OnInit {
  ToBeEdited: any;
  chapterList: Array<any> = new Array<any>();
  apiHitDone: boolean;
  entries: Entries;
  page: number = 1;
  limit: number = 10;
  totalResult: any;
  searchKeyword: string;
  trainingProgramsId: string;
  modulesId: string;
  chapterDetail: any;
  sendId: any = {};
  routeUrl:any;
  constructor(
    private modalService: ModalService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
    private trainingService: TrainingService,
    private commonService: CommonService,
  ) {
    this.activatedRoute.params.subscribe(
      ({ id, page, search, trainingname, trainingId }) => {
        this.page = page || 1;
        this.trainingProgramsId = id;
        this.modulesId = trainingId;
        this.sendId = { 'trainingProgramsId': this.trainingProgramsId, 'modulesId': this.modulesId }
        console.log(trainingname)
        if (search) {
          this.searchKeyword = search
        }
        this.getChapter(this.page);
      }
    );
    this.events.subscribe(EventsKey.successfullyaddupdate, () => {
      this.getChapter(this.page);
    })
    this.routeUrl = this.commonService.getRouterUrl();
  }

  ngOnInit(): void {
    $(".description p").text(function (_index, currentText) {
      var maxLength = $(this).parent().attr('data-maxlength');
      if (currentText.length >= maxLength) {
        return currentText.substr(0, maxLength) + "...";
      } else {
        return currentText
      }
    });
  }
  openModal(id: string, chapterDetail?: string) {
    if (chapterDetail) {
      this.chapterDetail = chapterDetail;
    } else {
      this.chapterDetail = null;
    }
    this.events.publish(EventsKey.edit, this.chapterDetail);
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  openReorderModal(id:string){
    this.limit = 500
    this.getChapter(this.page)
    this.modalService.open(id);
  }

  goBack() {
    this.location.back();
  }
  showText(locale: "en" | "am", list: any) {
    switch (locale) {
      case "en":
        list.readMore_en = !list.readMore_en;
        break;

      case "am":
        list.readMore_am = !list.readMore_am;
        break;
    }
  }
  getChapter(page: number) {
    this.trainingService.getAllChapter({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword, trainingProgramsId: this.trainingProgramsId, modulesId: this.modulesId }).then(
      ({ chaptersList, totalCount }) => {
        if (chaptersList) {
          this.chapterList = chaptersList;
          this.chapterList.forEach(
            (chapter) => {
              chapter.readMore_en = true;
              chapter.readMore_am = true;
            }
          );
          this.entries = this.commonService.calculateEntries(this.chapterList, page, this.limit, totalCount);
        } else {
          this.chapterList = [];
          this.totalResult = 0;
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.chapterList = [];
        this.totalResult = 0;
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        this.apiHitDone = true;
        this.page = page;
        if (this.page > 1 && this.chapterList.length == 0) {
          this.page = this.page - 1;
          this.getChapter(this.page);
        } else {
          let params: any = {
            "page": this.page,
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }


          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }

  toBeDeleted: string;
  deleteModal(id: string) {
    this.toBeDeleted = id;
    $('#delete-module').modal('show');
  }

  deleteChapter() {
    this.commonService.presentSpinner();

    this.trainingService.deleteChapter(this.toBeDeleted).then(
      (res) => {

      }
    ).finally(
      () => {

        $('#delete-module').modal('hide');
        this.getChapter(this.page);
        this.commonService.dismissSpinner();
      }
    )
  }

  goToTopic(chapterdata) {
    this.router.navigate([this.routeUrl[2] == 'sms' ? '/admin/sms/sms-topics': '/admin/topics', this.trainingProgramsId, this.modulesId, chapterdata._id]);
  }
  goToAssessment(assessment) {
    // let assessmentId = assessment.assessmentsList != [] ? assessment.assessmentsList._id : '';
    let assessmentId = ''
    if (assessment.assessmentsList.length == 0) {
      assessmentId = ''
    } else {
      assessmentId = assessment.assessmentsList._id;
    }
    this.router.navigate([this.routeUrl[2] == 'sms' ? '/admin/sms/sms-assessment' :'/admin/assessment', this.trainingProgramsId, this.modulesId, assessment._id], { queryParams: { assessmentId: assessmentId } });
    // this.router.navigate(['/admin/assessment', this.trainingProgramsId, this.modulesId, assessment._id, assessmentId]);
  }

  getData(evt:any){
    this.getChapter(this.page);
  }
}

