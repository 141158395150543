import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
declare var $: any;

@Component({
  selector: 'app-sms-chapters',
  templateUrl: './sms-chapters.component.html',
  styleUrls: ['./sms-chapters.component.scss']
})
export class SmsChaptersComponent implements OnInit {

 
  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    $(".description p").text(function(_index, currentText) {
      var maxLength = $(this).parent().attr('data-maxlength');
      if(currentText.length >= maxLength) {
        return currentText.substr(0, maxLength) + "...";
      } else {
        return currentText
      } 
    });
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

}

