<div class="type_qauestions">
  <!-- {{ids | json}} -->
  <div class="question_row" [formGroup]="addQuestion">
    <!-- <div class="item-dlg-dragHandle"></div> -->
    <div class="itemShowOnExpand">
      <div class="ItemTitleRow">
        <div class="ItemTitleInput">
          <div class="textarea-two" formGroupName="question">
            <textarea placeholder="Question (English)" id="text-area" #add_question_input
              (input)="setHeight(add_question_input)" style="height:50px;overflow-y:hidden;"
              class="form-control ItemTitle" formControlName="en"></textarea>

            <textarea placeholder="Question (Amharic)" id="text-area" #add_question_input1
              (input)="setHeight(add_question_input1)" style="height:50px;overflow-y:hidden;"
              class="form-control ItemTitle" formControlName="am"></textarea>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div *ngIf="submitted && !addQuestion?.value?.question?.en" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="submitted && !addQuestion?.value?.question?.am" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
          </div>


        </div>
        <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
        <!-- Example single danger button -->
        <div class="ng_select_option">
          <ng-select [(ngModel)]="answertype" [items]="questionTypes" bindLabel="name" bindValue="value"
            placeholder="Select type" formControlName="questionType">
            <ng-template ng-label-tmp let-item="item">
              <span class="ng-value-label" data-title="{{item.name}}">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <span class="ng-option-label" data-title="{{item.name}}">{{item.name}}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <!-- <div class="short-answer box-input" *ngIf="answertype == 'Short'">
      <ul>
        <li>
          <div class="input-row"><input type="text" class="input" value="Short answer text" disabled>
          </div>
        </li>

      </ul>
    </div> -->
    <!-- Multiple choice -->
    <div class="checkboxes-choice box-input" *ngIf="addQuestion?.value?.questionType == 'multipleChoice'">
      <ul formArrayName="options">
        <li *ngFor="let option of addQuestion?.controls['options']['controls']; let i = index" [formGroupName]="i">
          <label class="checkbox-box checkbox-true">
            <input type="checkbox" id="infrasonic" name="infrasonic" formControlName="correctAnswer" [value]="true">
            <span>true</span>
          </label>
          <div class="input-row">

            <div class="d-flex">
              <div class="input" formGroupName="option">

                <input type="text" (focus)="selectText('checkboxes0'+i)" [id]="'checkboxes0'+i" formControlName="en"
                  [readOnly]="options[i]?.value?.option === 'Other'" class="input">
                <input type="text" [id]="'checkboxes0'+i" formControlName="am"
                  [readOnly]="options[i]?.value?.option === 'Other'" class="input">
              </div>
              <div class="icon-box">
                <div class="input-action">
                  <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                  <span class="iconbuttonContentSpan" (click)="removeOptionsFields(i, 'addQuestion')"><svg
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path fill="#5f6368"
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                      </path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg></span>
                </div>
              </div>
            </div>

          </div>
          <div class="ViewMediaImagePos d-none">
            <span class="remove-exportLabel"></span>
            <img class="ViewMediaImage" src="/assets/images/users/user-1.jpg" style="width:100%;">
          </div>
        </li>

        <!-- <li> -->
        <!-- <input (click)="addMoreOptionsFields('', 'addQuestion')" readonly type="text" class="input"
            placeholder="Add option"> -->
        <button class="btn btn-warning" (click)="addMoreOptionsFields('', 'addQuestion')">Add option</button>
        <!-- <span class="OtherDivider" *ngIf="!otherAdded">or</span>
          <span class="other-exportLabel" *ngIf="!otherAdded" (click)="addMoreOptionsFields('-1', 'addQuestion')">add
            "Other"</span> -->
        <!-- </li> -->
      </ul>
    </div>

    <!-- Single Choice -->
    <div class="multiple-choice box-input" *ngIf="addQuestion?.value?.questionType == 'singleChoice'">
      <ul formArrayName="options">
        <li *ngFor="let option of addQuestion?.controls['options']['controls']; let i = index" [formGroupName]="i">
          <label class="radio-box">
            <input type="radio" name="correctAnswer" id="radio" (change)="onRadioValueChange(i, 'addQuestion', $event)"
              [value]="'add'+i+1">
            <span>radio</span></label>
          <div class="input-row">

            <div class="d-flex">
              <div class="input" formGroupName="option">

                <input type="text" (focus)="selectText('multiple0'+i)" [id]="'multiple0'+i" formControlName="en"
                  [readOnly]="options[i]?.value?.option === 'Other'" class="input">
                <input type="text" [id]="'multiple0'+i" formControlName="am"
                  [readOnly]="options[i]?.value?.option === 'Other'" class="input">

              </div>
              <div class="icon-box">
                <div class="input-action">
                  <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                  <span class="iconbuttonContentSpan" (click)="removeOptionsFields(i, 'addQuestion')"><svg
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path fill="#5f6368"
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                      </path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg></span>
                </div>
              </div>
            </div>

          </div>
          <div class="ViewMediaImagePos d-none">
            <span class="remove-exportLabel"></span>
            <img class="ViewMediaImage" src="/assets/images/users/user-1.jpg" style="width:100%;">
          </div>
        </li>

        <!-- <li class="add-option">
          <input type="text" class="input" placeholder="Add option" readonly
            (click)="addMoreOptionsFields('', 'addQuestion')">
          <span class="OtherDivider" *ngIf="!otherAdded">or</span>
          <span class="other-exportLabel" *ngIf="!otherAdded" (click)="addMoreOptionsFields('-1', 'addQuestion')">add
            "Other"</span>
        </li> -->
        <button class="btn btn-warning" (click)="addMoreOptionsFields('', 'addQuestion')">Add option</button>
      </ul>
    </div>
    <div class="multiple-choice box-input" *ngIf="addQuestion?.value?.questionType == 'yesNoChoice'">
      <ul>
        <li>
          <label class="radio-box">
            <input type="radio" name="yesNoChoice" id="Yes" value="Yes" formControlName="yesNoChoice"
              (change)="onChangeValue('addQuestion', $event)"><span>radio</span></label>
          <div class="input-row">

            <div class="d-flex">
              <span class="input">Yes</span>

            </div>

          </div>

        </li>
        <li>
          <label class="radio-box">
            <input type="radio" name="yesNoChoice" id="No" formControlName="yesNoChoice" value="No"
              (change)="onChangeValue('addQuestion', $event)"><span>radio</span></label>
          <div class="input-row">
            <div class="d-flex">
              <span class="input">No</span>

            </div>

          </div>
        </li>
      </ul>
    </div>
    <button type="submit" class="btn btn-warning" (click)="AddQuestion()">Save</button>
  </div>
</div>