<div class="form">
  <!-- program-editor -->
  <form [formGroup]="addForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" formGroupName="chapterName">
          <label>Chapter Name (English)</label>
          <input type="name" class="form-control" formControlName="en">
          <div *ngIf="formSubmitted && !addForm?.value?.chapterName?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="description">
          <label>Description (English)</label>
          <!-- <angular-editor id="editor1" formControlName="en"></angular-editor> -->
          <textarea class="form-control" id="editor1" formControlName="en" (input)="smsCount('en')"></textarea>
          <span *ngIf="routeUrl[2] == 'sms'">{{remainingEn}} characters remaining {{messagesEn}} message(s)</span>
          <div *ngIf="formSubmitted && !addForm?.value?.description?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" formGroupName="chapterName">
          <label>Chapter Name (Amharic)</label>
          <input type="name" class="form-control" formControlName="am">
          <div *ngIf="formSubmitted && !addForm?.value?.chapterName?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="description">
          <label>Description (Amharic)</label>
          <textarea id="editor1" class="form-control" formControlName="am" (input)="smsCount('am')"></textarea>
          <span *ngIf="routeUrl[2] == 'sms'">{{remainingAm}} characters remaining {{messagesAm}} message(s)</span>
          <!-- <angular-editor id="editor1" formControlName="am"></angular-editor> -->
          <div *ngIf="formSubmitted && !addForm?.value?.description?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Read Time </label>
          <input type="text" class="form-control" formControlName="readTime" (keypress)="numberOnly($event, 'value')">
          <div *ngIf="formSubmitted && !addForm?.value?.readTime" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
      </div>
    </div>
    <button type="submit" class="btn btn-warning" (click)="saveChapter()">Save</button>
  </form>
</div>