<div class="form">
  <!-- program-editor -->
  <form [formGroup]="addForm">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="form-group" formGroupName="topicsName">
          <label>Topic Name (English)</label>
          <input type="name" class="form-control" formControlName="en">
          <div *ngIf="formSubmitted && !addForm?.value?.topicsName?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="description">
          <label>Description (English)</label>
          <button type="button" class="mr-2 text-primary btn_style insert-image-icon" (click)="englishImage.click()">
            <i class="mdi mdi-image-plus"></i>
          </button>
          <input type="file" #englishImage hidden name="" multiple
            (change)="onImageSelect($event.target.files, 'en');englishImage.value = ''"
            accept="image/jpg,image/jpeg,image/png,image/svg" id="">
          <angular-editor id="editor1" formControlName="en" [config]="config" (input)="smsCount('en')"></angular-editor>
          <span *ngIf="routeUrl[2] == 'sms'">{{remainingEn}} characters remaining {{messagesEn}} message(s)</span>
          <!-- <ejs-richtexteditor [insertImageSettings]='insertImageSettings' formControlName="en" required>
          </ejs-richtexteditor> -->
          <div *ngIf="formSubmitted && !addForm?.value?.description?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" formGroupName="topicsName">
          <label>Topic Name (Amharic)</label>
          <input type="name" class="form-control" formControlName="am">
          <div *ngIf="formSubmitted && !addForm?.value?.topicsName?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="description">
          <label>Description (Amharic)</label>
          <button type="button" class="mr-2 text-primary btn_style insert-image-icon" (click)="amharicImage.click()">
            <i class="mdi mdi-image-plus"></i>
          </button>
          <input type="file" #amharicImage hidden name="" multiple
            (change)="onImageSelect($event.target.files, 'am');amharicImage.value = ''"
            accept="image/jpg,image/jpeg,image/png,image/svg" id="">
          <angular-editor id="editor1" formControlName="am" [config]="config" (input)="smsCount('am')"></angular-editor>
          <span *ngIf="routeUrl[2] == 'sms'">{{remainingAm}} characters remaining {{messagesAm}} message(s)</span>
          <!-- <ejs-richtexteditor #alertMsg [insertImageSettings]='insertImageSettings' formControlName="am" required>
          </ejs-richtexteditor> -->
          <div *ngIf="formSubmitted && !addForm?.value?.description?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Read Time </label>
          <input type="text" class="form-control" formControlName="readTime" (keypress)="numberOnly($event, 'value')">
          <div *ngIf="formSubmitted && !addForm?.value?.readTime" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Full Narrative</label>
          <select class="custom-select" id="user-type" formControlName="narrative">
            <option value="" selected="">Select Narrative</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <div *ngIf="formSubmitted && !addForm?.value?.narrative" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Full Activity Description</label>
          <select class="custom-select" id="user-type" formControlName="fullNarrative">
            <option value="" selected>Select Full Narrative</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <div *ngIf="formSubmitted && !addForm?.value?.fullNarrative" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6"></div>
      <!-- <div class="row" style="margin: 0px;padding:0px;" *ngIf="showFullnaritiveDescription">
     
        <div class="col-md-6" formGroupName="fullNarritiveDescription">
          <div class="form-group">
            <label>Description (English)</label>
            <textarea id="editor1" class="form-control" formControlName="en"></textarea>
         
          </div>
        </div>
        <div class="col-md-6" formGroupName="fullNarritiveDescription">
          <div class="form-group">
            <label>Description (Amharic)</label>
            <textarea id="editor1" class="form-control" formControlName="am"></textarea>
          
          </div>
        </div>
      
      </div> -->
    </div>
    <button type="submit" class="btn btn-warning" (click)="saveTopic()">Save</button>
  </form>
</div>
