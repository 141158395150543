import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  languageType: any;
  aboutus: any;
  contentList: any = [];
  constructor(
    private trainingService: TrainingService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(
      ({ language }) => {
        this.languageType = language;

      }
    );
    this.getStaticContent()
  }

  ngOnInit(): void {
  }

  getStaticContent() {
    this.commonService.presentSpinner();
    this.trainingService.getContaint().then(
      ({ contentList }) => {
        if (contentList) {
          this.contentList = contentList;
          this.contentList.forEach(element => {

            if (element.slug == 'about-us') {

              this.aboutus = element.description;

            }

          });
        }
      }
    ).catch((error) => {
      this.contentList = [];
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {

      this.commonService.dismissSpinner();
    })
  }
}
