<form>
    <div class="row">
      <!-- <div class="col-lg-12">
        <div class="form-group">
          <label>Picture</label>
          <div class="custom_file custom_img_input">
            <input type="file" class="custom_file_input">
            <div class="file_img"
              style="background-color: rgb(245, 245, 245);background-image: url(assets/images/users/admin.jpg);">
            </div>

          </div>
        </div>
      </div> -->
      <div class="col-lg-12">
        <div class="form-group">
          <label for="validationCustom01">Name</label>
          <input type="text" class="form-control">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label for="validationCustom01">Email</label>
          <input type="email" class="form-control">
        </div>
      </div>
    </div>
    <button class="btn btn-warning" type="submit">Save</button>
  </form>