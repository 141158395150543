import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsService } from 'src/app/services/events/events.service';
import { Addmodule } from 'src/app/services/training/addmodule.model';
import { TrainingService } from 'src/app/services/training/training.service';
declare var $: any;
@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {
  trainingProgramsId: string;
  moduleForm: FormGroup;
  FormSubmitted: boolean;
  ToBeEdited: any;
  moduleList: Array<any> = new Array<any>();
  apiHitDone: boolean;
  entries: Entries;
  page: number = 1;
  limit: number = 10;
  totalResult: any;
  searchKeyword: string;
  moduleDetail: any;
  routeUrl:any;
  constructor(
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
    private trainingService: TrainingService,
    private commonService: CommonService,
  ) {
    // this.ext = this.router.getCurrentNavigation().extras.state.example;
    this.activatedRoute.params.subscribe(
      ({ id, page, search, trainingname, }) => {
        this.page = page || 1;
        this.trainingProgramsId = id;
        console.log(trainingname)
        if (search) {
          this.searchKeyword = search
        }

        this.getModules(this.page);

      }
    );

    this.moduleForm = new FormGroup({
      "modulesName": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
    })
    this.routeUrl = this.commonService.getRouterUrl();
  }

  ngOnInit(): void {

  }
  openModal(id: string, moduleDetail?: any) {
    if (moduleDetail) {
      this.ToBeEdited = moduleDetail;
      this.moduleForm.patchValue(this.ToBeEdited)
    } else {
      this.ToBeEdited = null;
      this.FormSubmitted = false;
      this.moduleForm.reset();
    }
    this.modalService.open(id);
  }

  openReorderModal(id:string){
    this.limit = 500
    this.getModules(this.page)
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  getModules(page: number) {
    this.trainingService.getAllModules({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword, trainingProgramsId: this.trainingProgramsId }).then(
      ({ modulesList, totalCount }) => {
        if (modulesList) {
          this.moduleList = modulesList;
          this.entries = this.commonService.calculateEntries(this.moduleList, page, this.limit, totalCount);
        } else {
          this.moduleList = [];
          this.totalResult = 0;
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.moduleList = [];
        this.totalResult = 0;
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        this.apiHitDone = true;
        this.page = page;
        if (this.page > 1 && this.moduleList.length == 0) {
          this.page = this.page - 1;
          this.getModules(this.page);
        } else {
          let params: any = {
            "page": this.page,
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }


          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }
  saveModule() {
    this.FormSubmitted = true;
    if (this.moduleForm.invalid) {
      return;
    }
    // let payload = {};
    let payload = new Addmodule(this.moduleForm.value);
    payload.trainingProgramsId = this.trainingProgramsId;
    if (this.ToBeEdited) {
      payload.modulesId = this.ToBeEdited._id;
    } else {
      payload.modulesId = '';
    }

    this.commonService.presentSpinner();
    this.trainingService.addUpdateModules(payload).then(
      (res: any) => {

      }
    ).catch((error) => {
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.modalService.close('addModules');
      this.getModules(this.page);
      this.ToBeEdited = null;
    })
  }

  toBeDeleted: string;
  deleteModal(id: string) {
    this.toBeDeleted = id;
    $('#delete-module').modal('show');
  }

  deleteModule() {
    this.commonService.presentSpinner();

    this.trainingService.deleteModule(this.toBeDeleted).then(
      (res) => {

      }
    ).finally(
      () => {
        $('#delete-module').modal('hide');
        this.getModules(this.page);
        this.commonService.dismissSpinner();
      }
    )
  }

  goToChapter(moduledata) {
    this.router.navigate([this.routeUrl[2] == 'sms' ? '/admin/sms/sms-chapters' : '/admin/chapters', this.trainingProgramsId, moduledata._id]);
  }

  getData(evt:any){
    this.getModules(this.page);
  }
}
