import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  contentList: any = [];
  privacypolicy: any;
  privacypolicyDetail: any;
  languageType: any;
  constructor(
    private trainingService: TrainingService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(
      ({ language }) => {
        this.languageType = language;

      }
    );
    this.getStaticContent();
  }

  ngOnInit(): void {
  }
  getStaticContent() {
    this.commonService.presentSpinner();
    this.trainingService.getContaint().then(
      ({ contentList }) => {
        if (contentList) {
          this.contentList = contentList;
          this.contentList.forEach(element => {
            if (element.slug == 'privacy-policy') {
              this.privacypolicy = element.description;
              this.privacypolicyDetail = element;
              // this.privacyAm = element.description.am;
            }

          });
        }
      }
    ).catch((error) => {
      this.contentList = [];
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {

      this.commonService.dismissSpinner();
    })
  }
}
