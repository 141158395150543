<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">FAQS</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <!-- <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('reorder')">Reorder</button> -->
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openReorderModal()">Reorder</button>
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addFaq')">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">

              <div id="accordion" class="custom-accordion">
                <div class="card mb-1 shadow-none" *ngFor="let faq of faqList | paginate: {
                    id: 'faqList',
                    itemsPerPage: limit,
                    currentPage: currentPage,
                    totalItems: entries?.total
                  }; ; let i = index;">
                  <div class="card-header" [id]="'accordion-'+ i">
                    <a [href]="'#collapse-'+i" [ngClass]="{'collapsed': i != 0}" class="card-header-a text-dark"
                      data-toggle="collapse" aria-controls="'collapse-' + i">
                      <h6 class="m-0">
                        <span [innerHtml]="faq?.question?.en"></span> <br>
                        <span [innerHtml]="faq?.question?.am"></span>
                        <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                      </h6>
                    </a>
                    <div class="acc-action">
                      <button type="button" (click)="openModal('addFaq',faq)" class="mr-2 text-primary btn_style"><i
                          class="mdi mdi-pencil font-size-18"></i>
                      </button> <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(faq?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </div>
                  </div>
                  <div [id]="'collapse-' + i" class="collapse" [ngClass]="{'show': i == '0'}"
                    [aria-labelledby]="'accordion-' + i" data-parent="#accordion">
                    <div class="card-body">
                      <div class="english">
                        <p [innerHtml]="faq?.answer?.en"></p>
                      </div>
                      <div class="amharic">
                        <p [innerHtml]="faq?.answer?.am"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-1 shadow-none" *ngIf="apiHitDone && !faqList?.length">
                  <h5 class="text-center">No record found!</h5>
                </div>
              </div>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && faqList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of {{entries?.total}}
                    entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <!-- <ul class="pagination pagination-rounded">
                      <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-left"></i></a>
                      </li>
                      <li class="paginate_button page-item active"><a href="#" class="page-link">1</a>
                      </li>
                      <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                      </li>
                      <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-right"></i></a>
                      </li>
                    </ul> -->
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="faqList"
                      (pageChange)="getFaq($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>

  <!-- container-fluid -->
</div>
<!-- End Page-content -->

<!-- Add Faq Popup -->
<modal id="addFaq" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{faqToBeEdited ? 'Edit' : 'Add'}} FAQ</h5>
    <button type="button" class="close" (click)="closeModal('addFaq');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-faq></app-add-faq>
  </div>
</modal>
<!-- End Add Faq Popup -->

<!-- Edit Faq Popup -->
<modal id="editFaq" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Faq</h5>
    <button type="button" class="close" (click)="closeModal('editFaq');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-faq></app-edit-faq>
  </div>
</modal>
<!-- End Edit Faq Popup -->
<!-- reorder Popup -->
<modal id="reorder">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reorder (FAQ)</h5>
    <button type="button" class="close" (click)="closeModal('reorder');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="overflow: hidden !important;">
    <app-reorder-faq></app-reorder-faq>
  </div>
</modal>
<!-- End reorder Popup -->
<div class="modal fade alert_popup" id="delete-offer" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete FAQ</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteFaq()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="reorder1" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
  style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Reorder (FAQ)</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!-- <div class="row"> -->
        <!-- <h2>Vertical</h2> -->
        <div #container2 dropListScroller cdkDropList cdkDropListLockAxis="y" cdkDropListOrientation="vertical"
          class="elements dropList_box" (cdkDropListDropped)="drop($event)">
          <div class="element" *ngFor="let tab of faqreorderList ; let i = index" cdkDrag vsDragScroll
            [vsDragScrollContainer]="container2" class="vertical-scrool">
            <div class="number">{{i + 1}}</div>
            <span [innerHtml]="tab.question.en"></span>
            <span [innerHtml]="tab.question.am"></span>
          </div>
          <div *ngIf="apiHitDone && !faqreorderList?.length">
            <h5 class="text-center">No record found!</h5>
          </div>

          <div class="api-inline-loader" *ngIf="!apiHitDone">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <button class="mt-2 btn btn-warning" type="submit" (click)="SaveChangeOrder()"
          *ngIf="faqreorderList?.length">Save</button>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>