import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpInputData, HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';
import { addAssessments, Addmodule, Chapter, Topic } from './addmodule.model';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  constructor(
    private http: HttpWrapperService,
    private commonService: CommonService,
  ) { }

  addUpdatetraining(payload) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.trainingaddupdate;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.smsTrainingaddupdate;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  getAllTags(query?: { page?: string; limit?: string; search?: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.tags, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  getAllTraining(query?: { page?: string; limit?: string; search?: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search.trim());
    }
    httpInput.params = httpParams;
    let endpoint = Endpoints.getTraining;
    let url = this.commonService.getRouterUrl();
    if(url && url[2] == 'sms'){
      endpoint = Endpoints.sms.getSmsTraining;
    }
    return new Promise((resolve, reject) => {
      this.http.get(endpoint, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteTraining(id: string) {
    return new Promise((resolve, reject) => {
      let endpoint =Endpoints.deletetraining;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.getSmsTraining;
      }
      this.http.delete(endpoint + `/${id}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUpdateModules(payload: Addmodule) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.modulesaddupdate;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.smsModulesaddupdate;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getAllModules(query?: { page?: string; limit?: string; search?: string, trainingProgramsId?: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    if (query && query.trainingProgramsId) {
      httpParams = httpParams.set('trainingProgramsId', query.trainingProgramsId);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.getmodules;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.getSmsmodules;
      }
      this.http.get(endpoint, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteModule(id: string) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.getmodules;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.getSmsmodules;
      }
      this.http.delete(endpoint + `/${id}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getAllChapter(query?: { page?: string; limit?: string; search?: string, trainingProgramsId?: string, modulesId?: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    if (query && query.trainingProgramsId) {
      httpParams = httpParams.set('trainingProgramsId', query.trainingProgramsId);
    }
    if (query && query.modulesId) {
      httpParams = httpParams.set('modulesId', query.modulesId);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.chapters;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.smsChapters;
      }
      this.http.get(endpoint, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUpdateChapter(payload: Chapter) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.chapters;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.smsChapters;
      }
      this.http.post(endpoint + `/add-update`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteChapter(id: string) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.chapters
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.smsChapters;
      }
      this.http.delete(endpoint + `/${id}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getAllTopic(query?: { page?: string; limit?: string; search?: string, trainingProgramsId?: string, modulesId?: string, chaptersId?: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    if (query && query.trainingProgramsId) {
      httpParams = httpParams.set('trainingProgramsId', query.trainingProgramsId);
    }
    if (query && query.modulesId) {
      httpParams = httpParams.set('modulesId', query.modulesId);
    }
    if (query && query.chaptersId) {
      httpParams = httpParams.set('chaptersId', query.chaptersId);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.topics;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.smstopics;
      }
      this.http.get(endpoint, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUpdateTopic(payload: Topic) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.topics;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.smstopics;
      }
      this.http.post(endpoint + `/add-update`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  deleteTopic(id: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(Endpoints.topics + `/${id}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUpdateNarrative(payload, id) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.narrative;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.narrative;
      }
      this.http.put(endpoint + `/${id}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  getContaint() {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.systemsetting).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  getUserRole(query?: { page?: string; limit?: string; }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.userrole, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  getLocationData(query?: { page?: string; limit?: string; }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.location, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  updateContent(payload, contentId) {

    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.updatesystemsetting(contentId), payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUserRole(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(Endpoints.userrole, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  UpdateUserRole(payload, rolId?: string) {
    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.userrole + `/${rolId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUpdateAssessments(payload: addAssessments) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.assessments;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.assessments;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getAllAssessment(query?: { page?: string; limit?: string; search?: string, trainingProgramsId?: string, modulesId?: string, chaptersId?: string, assessmentsId?: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    if (query && query.trainingProgramsId) {
      httpParams = httpParams.set('trainingProgramsId', query.trainingProgramsId);
    }
    if (query && query.modulesId) {
      httpParams = httpParams.set('modulesId', query.modulesId);
    }
    if (query && query.chaptersId) {
      httpParams = httpParams.set('chaptersId', query.chaptersId);
    }
    if (query && query.assessmentsId) {
      httpParams = httpParams.set('assessmentsId', query.assessmentsId);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.question;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.question;
      }
      this.http.get(endpoint, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUpdateQuestion(payload) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.questionaddupdate;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.questionaddupdate;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  deleteQuestion(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(Endpoints.question + `/${id}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  reOrderList(payload) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.reorder;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.reorderQuestion;
      }
      this.http.put(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  addUserlocation(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(Endpoints.location, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  UpdateUserLocation(payload, locationId?: string) {
    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.location + `/${locationId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  reorderTraining(payload) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.reorderTraining;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.reorderTraining;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  reorderModule(payload) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.reorderModule;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.reorderModule;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  reorderChapter(payload) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.reorderChapter;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.reorderChapter;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  reorderTopic(payload) {
    return new Promise((resolve, reject) => {
      let endpoint = Endpoints.reorderTopic;
      let url = this.commonService.getRouterUrl();
      if(url && url[2] == 'sms'){
        endpoint = Endpoints.sms.reorderTopic;
      }
      this.http.post(endpoint, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
}
