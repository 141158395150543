import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { FAQService } from 'src/app/services/faq/faq.service';
declare var $: any;
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  limit: number = 10;
  faqList: Array<any>;
  faqreorderList: Array<any>;
  apiHitDone: boolean;
  currentPage: number = 1;
  entries: Entries;
  faqToBeEdited: any;
  constructor(
    private modalService: ModalService,
    private commonService: CommonService,
    private faqService: FAQService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
  ) {
    // $("#accordionn-0").attr("aria-expanded", "true");
    this.events.subscribe(EventsKey.faqSuccessfullyupdate, () => {
      this.getFaq(this.currentPage);
    })
    this.activatedRoute.queryParams.subscribe(
      ({ search, page }) => {
        this.currentPage = page || 1;
        this.getFaq(this.currentPage);
      }
    ).unsubscribe();
  }

  ngOnInit(): void {

  }

  newArr: any = [];
  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.faqreorderList, event.previousIndex, event.currentIndex);
    this.faqreorderList.forEach(e => {
      this.newArr.push({ 'faqId': e._id })
    });
  }
  openModal(id: string, detail?: any) {
    if (detail) {
      this.faqToBeEdited = detail
    } else {
      this.faqToBeEdited = null
    }
    this.events.publish(EventsKey.edit, this.faqToBeEdited);
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  getFaq(page: number) {
    this.apiHitDone = false;
    this.faqService.getFaq({ "page": page.toString(), "limit": this.limit.toString() }).then(
      ({ faqList, totalResult }) => {
        if (faqList) {
          this.faqList = faqList;
          this.entries = this.commonService.calculateEntries(this.faqList, page, this.limit, totalResult);
        } else {
          this.faqList = [];
          this.entries = this.commonService.calculateEntries(this.faqList, 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.faqList = [];
        this.entries = this.commonService.calculateEntries(this.faqList, 0, 0, 0);
      }
    ).finally(
      () => {
        this.currentPage = page;
        if (this.currentPage > 1 && this.faqList.length == 0) {
          this.currentPage = this.currentPage - 1;
          // this.getAdmins(this.currentPage);
        } else {
          let params: any = {
            "page": this.currentPage
          };

          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
          this.apiHitDone = true;
        }
      }
    )
  }
  geReordertFaq(page: number) {
    this.apiHitDone = false;
    this.faqService.getFaq({ "page": page.toString(), "limit": '1000' }).then(
      ({ faqList, totalResult }) => {
        if (faqList) {
          this.faqreorderList = faqList;

        } else {
          this.faqreorderList = [];
        }
      }
    ).finally(() => {
      this.apiHitDone = true;
    })

  }

  faqToBeDeleted: string;
  deleteModal(faqId: string) {
    this.faqToBeDeleted = faqId;
    $('#delete-offer').modal('show');
  }
  openReorderModal() {
    this.newArr = [];
    this.geReordertFaq(1)
    $('#reorder1').modal('show');
  }
  deleteFaq() {
    this.commonService.presentSpinner();
    this.faqService.deleteFaq(this.faqToBeDeleted).then(
      (res) => {

      }
    ).finally(
      () => {
        $('#delete-offer').modal('hide');
        this.getFaq(this.currentPage);
        this.commonService.dismissSpinner();
      }
    )
  }

  SaveChangeOrder() {
    this.commonService.presentSpinner();
    let postdata = {
      payload: this.newArr
    }
    console.log(postdata)
    this.faqService.reorderFaqs(postdata).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.dismissSpinner();
          $('#reorder1').modal('hide');
          this.commonService.showSuccessToastMsg(res.message.en, '');
          this.newArr = [];

        }
      }
    ).catch((error) => {
      this.newArr = [];
      this.commonService.dismissSpinner();
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.events.publish(EventsKey.faqSuccessfullyupdate, '');
    })
  }
}