import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';
import { Location } from '@angular/common';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {
  ToBeEdited: any;
  topicList: Array<any> = new Array<any>();
  apiHitDone: boolean;
  addNarritiveDescriptionForm: FormGroup;
  entries: Entries;
  page: number = 1;
  limit: number = 10;
  totalResult: any;
  searchKeyword: string;
  trainingProgramsId: string;
  modulesId: string;
  topicDetail: any;
  sendId: any = {};
  chaptersId: any;
  constructor(
    private modalService: ModalService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
    private trainingService: TrainingService,
    private commonService: CommonService,
  ) {
    this.activatedRoute.params.subscribe(
      ({ id, page, search, trainingId, chaptersId }) => {
        this.page = page || 1;
        this.trainingProgramsId = id;
        this.modulesId = trainingId;
        this.chaptersId = chaptersId;
        this.sendId = { 'trainingProgramsId': this.trainingProgramsId, 'modulesId': this.modulesId, "chaptersId": this.chaptersId }
        console.log(this.sendId)
        if (search) {
          this.searchKeyword = search
        }
        this.getTopic(this.page);
      }
    );
    this.events.subscribe(EventsKey.successfullyaddupdate, () => {
      this.topicList = [];
      this.apiHitDone = false;

      this.getTopic(this.page);
    })
  }

  ngOnInit(): void {
    this.addNarritiveDescriptionForm = new FormGroup({
      "fullNarritiveDescription": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),

    })
    $(".description p").text(function (_index, currentText) {
      var maxLength = $(this).parent().attr('data-maxlength');
      if (currentText.length >= maxLength) {
        return currentText.substr(0, maxLength) + "...";
      } else {
        return currentText
      }
    });
  }
  openModal(id: string, topicDetail?: any) {
    if (id == 'addTopics') {
      document.getElementById('addTopics').style.visibility = 'visible';
      document.getElementById('addTopics').style.opacity = '1';

    }
    if (topicDetail) {
      this.topicDetail = topicDetail;
    } else {
      this.topicDetail = null;
    }
    this.events.publish(EventsKey.edit, this.topicDetail);
    this.modalService.open(id);
  }

  openReorderModal(id:string){
    this.limit = 500
    this.getTopic(this.page)
    this.modalService.open(id);
  }

  fullNarrativeDetail: any;
  fullNarrativeDetailEn: string = '';
  fullNarrativeDetailAm: string = '';
  fullNarrativeDescriptionDetail: any
  openFullnarrativeModal(id: string, topicDetail?: any) {
    if (topicDetail.fullNarrative) {
      this.addNarritiveDescriptionForm.reset();
      this.formSubmitted = false;
      this.addNarritiveDescriptionForm.patchValue({
        fullNarritiveDescription: {
          en: topicDetail.fullNarritiveDescription.en != '' ? topicDetail.fullNarritiveDescription.en : '',
          am: topicDetail.fullNarritiveDescription.am != '' ? topicDetail.fullNarritiveDescription.am : ''
        }
      })
      this.fullNarrativeDescriptionDetail = topicDetail;
      this.modalService.open(id);
    } else {
      this.commonService.showErrorToastMsg("No full narritive description");
    }

  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  goBack() {
    this.location.back();
  }
  showText(locale: "en" | "am", list: any) {
    switch (locale) {
      case "en":
        list.readMore_en = !list.readMore_en;
        break;

      case "am":
        list.readMore_am = !list.readMore_am;
        break;
    }
  }
  getTopic(page: number) {
    // this.commonService.presentSpinner();
    this.trainingService.getAllTopic({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword, trainingProgramsId: this.trainingProgramsId, modulesId: this.modulesId, chaptersId: this.chaptersId }).then(
      ({ topicsList, totalCount }) => {
        if (topicsList) {
          this.topicList = topicsList;
          this.topicList.forEach(
            (topic) => {
              topic.readMore_en = true;
              topic.readMore_am = true;
            }
          );
          this.entries = this.commonService.calculateEntries(this.topicList, page, this.limit, totalCount);
        } else {
          this.topicList = [];
          this.totalResult = 0;
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.topicList = [];
        this.totalResult = 0;
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        // this.commonService.dismissSpinner();
        this.apiHitDone = true;
        this.page = page;
        if (this.page > 1 && this.topicList.length == 0) {
          this.page = this.page - 1;
          this.getTopic(this.page);
        } else {
          let params: any = {
            "page": this.page,
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }


          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }

  toBeDeleted: string;
  deleteModal(id: string) {
    this.toBeDeleted = id;
    $('#delete-module').modal('show');
  }

  deleteTopic() {
    this.commonService.presentSpinner();

    this.trainingService.deleteTopic(this.toBeDeleted).then(
      (res) => {

      }
    ).finally(
      () => {

        $('#delete-module').modal('hide');
        this.getTopic(this.page);
        this.commonService.dismissSpinner();
      }
    )
  }
  formSubmitted: boolean;
  save(activityDescription) {
    this.formSubmitted = true;
    if (this.addNarritiveDescriptionForm.invalid) {
      return;
    }
    let payload = {
      chaptersId: activityDescription.chaptersId,
      description: {
        en: activityDescription.description.en,
        am: activityDescription.description.am
      },
      fullNarrative: activityDescription.fullNarrative,
      fullNarritiveDescription: {
        en: this.addNarritiveDescriptionForm.value.fullNarritiveDescription.en,
        am: this.addNarritiveDescriptionForm.value.fullNarritiveDescription.am
      },
      mediaUrl: activityDescription.mediaUrl,
      modulesId: activityDescription.modulesId,
      narrative: activityDescription.narrative,
      readTime: activityDescription.readTime,
      topicsId: activityDescription._id,
      topicsName: {
        en: activityDescription.topicsName.en,
        am: activityDescription.topicsName.am
      },
      trainingProgramsId: activityDescription.trainingProgramsId,
    }
    this.commonService.presentSpinner();
    this.trainingService.addUpdateTopic(payload).then(
      (res: any) => {
      }
    ).catch((error) => {
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {
      this.addNarritiveDescriptionForm.reset();
      this.formSubmitted = false;
      // this.events.publish(EventsKey.successfullyaddupdate, '');
      this.commonService.dismissSpinner();
      this.getTopic(1);
      this.modalService.close('fullNarrative');


    })
  }

  getData(evt:any){
    this.getTopic(this.page);
  }
}

