import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
import { Location } from '@angular/common';
import { EventsService } from 'src/app/services/events/events.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { TrainingService } from 'src/app/services/training/training.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { addAssessments } from 'src/app/services/training/addmodule.model';
import { GlobalassessmentService } from 'src/app/services/AssessmentQuestion/globalassessment.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
declare var $: any;
@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {
  assessmentToBeEdited: any;
  topicList: Array<any> = new Array<any>();
  apiHitDone: boolean;
  entries: Entries;
  page: number = 1;
  limit: number = 50;
  totalResult: any;
  searchKeyword: string;
  trainingProgramsId: string;
  modulesId: string;
  chaptersId: any;
  sendId: any = {};
  formSubmitted: boolean;
  addassessmentForm: FormGroup;
  assessmentId: string;
  assessmentList: Array<any> = new Array<any>();
  reorderAssessmentList: Array<any> = new Array<any>();
  addQuestion: FormGroup;
  editQuestion: FormGroup;
  constructor(
    private modalService: ModalService,
    private location: Location,
    private events: EventsService,
    private commonService: CommonService,
    private trainingService: TrainingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private assessmentServices: GlobalassessmentService,
  ) {
    this.activatedRoute.params.subscribe(
      ({ id, page, search, trainingId, chaptersId }) => {
        this.page = page || 1;
        this.trainingProgramsId = id;
        this.modulesId = trainingId;
        this.chaptersId = chaptersId;

        this.sendId = { 'trainingProgramsId': this.trainingProgramsId, 'modulesId': this.modulesId, "chaptersId": this.chaptersId }

        if (search) {
          this.searchKeyword = search
        }
        this.getAssessment(this.page);

      }
    );

    this.activatedRoute.queryParamMap.subscribe(params => {
      this.assessmentId = params['params'].assessmentId
      this.sendId = { 'trainingProgramsId': this.trainingProgramsId, 'modulesId': this.modulesId, "chaptersId": this.chaptersId, "assessmentId": this.assessmentId }

    })
    this.events.subscribe(EventsKey.updateassessmentquestion, () => {
      this.getAssessment(this.page);
    })
    this.addassessmentForm = new FormGroup({
      "title": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "description": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "estimatedTime": new FormControl('', Validators.compose([Validators.required]))
    })
  }

  ngOnInit(): void {
  }

  toBeDeleted: string;
  deleteModal(id: string) {
    this.toBeDeleted = id;
    $('#delete-module').modal('show');
  }
  openModal(id: string, assessment?: any) {
    if (assessment) {
      this.assessmentToBeEdited = assessment;
      // this.editQuestion = this.assessmentServices.getEditAssessmentQuestionForm();
    } else {
      this.assessmentToBeEdited = null;
      // this.addQuestion = this.assessmentServices.getAddAssessmentQuestionForm();
    }
    this.events.publish(EventsKey.openassessmentmodal, this.assessmentToBeEdited);
    if (this.assessmentId) {
      this.modalService.open(id);
    } else {
      this.commonService.showErrorToastMsg("Please add assessment");
    }
  }
  closeModal(id: string) {
    this.modalService.close(id);
    this.getAssessment(this.page);
  }
  goBack() {
    this.location.back();
  }


  assessmentData: any;
  getAssessment(page: number) {
    this.trainingService.getAllAssessment({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword, trainingProgramsId: this.trainingProgramsId, modulesId: this.modulesId, chaptersId: this.chaptersId, assessmentsId: this.assessmentId }).then(
      ({ assessmentsQuestionList, totalCount, assessmentsResult }) => {
        if (assessmentsQuestionList) {
          this.assessmentList = assessmentsQuestionList;
          this.assessmentData = assessmentsResult;
          this.assessmentId = assessmentsResult._id;
          // this.assessmentData = assessmentsQuestionList[0].Assessments || '';
          // this.assessmentId = assessmentsQuestionList[0].Assessments._id;
          if (this.assessmentData != '') {
            this.addassessmentForm.patchValue(this.assessmentData)
          }

          this.assessmentList.forEach(
            (chapter) => {
              chapter.readMore_en = true;
              chapter.readMore_am = true;
            }
          );
          this.entries = this.commonService.calculateEntries(this.assessmentList, page, this.limit, totalCount);
        } else {
          this.assessmentList = [];
          this.totalResult = 0;
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.assessmentList = [];
        this.totalResult = 0;
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        this.apiHitDone = true;
        this.events.publish(EventsKey.questionlist, this.assessmentList);
        this.page = page;
        if (this.page > 1 && this.assessmentList.length == 0) {
          this.page = this.page - 1;
          // this.getAssessment(this.page);
        } else {
          let params: any = {
            "page": this.page,
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }
          if (this.assessmentId) {
            params.assessmentId = this.assessmentId;
          }


          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }
  addUpdateAssessment() {
    this.formSubmitted = true;
    if (this.addassessmentForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    let payload = new addAssessments(this.addassessmentForm.value);
    payload.assessmentsId = this.assessmentId || '';
    payload.chaptersId = this.chaptersId;
    payload.modulesId = this.modulesId;
    payload.trainingProgramsId = this.trainingProgramsId;
    this.trainingService.addUpdateAssessments(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message);
          this.assessmentId = res.result._id
          this.sendId = { 'trainingProgramsId': this.trainingProgramsId, 'modulesId': this.modulesId, "chaptersId": this.chaptersId, "assessmentId": this.assessmentId }
        }
      }
    ).catch((error) => {

    }).finally(() => {
      this.commonService.dismissSpinner();
      this.getAssessment(this.page);
    })
  }

  deleteAssessment() {
    this.commonService.presentSpinner();

    this.trainingService.deleteQuestion(this.toBeDeleted).then(
      (res) => {

      }
    ).finally(
      () => {

        $('#delete-module').modal('hide');
        this.getAssessment(this.page);
        this.commonService.dismissSpinner();
      }
    )
  }
  openReorderModal() {
    this.newArr = [];
    this.getReorderForAssessment(this.page);
    $('#reorderAssessment').modal('show');
  }
  getReorderForAssessment(page: number) {
    this.apiHitDone = false;
    this.trainingService.getAllAssessment({ page: page.toString(), limit: '1000', search: this.searchKeyword, trainingProgramsId: this.trainingProgramsId, modulesId: this.modulesId, chaptersId: this.chaptersId, assessmentsId: this.assessmentId }).then(
      ({ assessmentsQuestionList, totalCount }) => {
        if (assessmentsQuestionList) {
          this.reorderAssessmentList = assessmentsQuestionList;
        } else {
          this.reorderAssessmentList = [];
        }
      }
    ).catch(
      (error) => {
        this.reorderAssessmentList = [];
      }
    ).finally(() => {
      this.apiHitDone = true;
    })
  }
  newArr: any = [];
  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.reorderAssessmentList, event.previousIndex, event.currentIndex);
    this.reorderAssessmentList.forEach(e => {
      this.newArr.push({ 'questionId': e._id })
    });
  }
  reOrder() {
    this.commonService.presentSpinner();
    let postdata = {
      payload: this.newArr
    }

    this.trainingService.reOrderList(postdata).then(
      (res: any) => {

      }
    ).catch((error) => {

    }).finally(() => {
      this.commonService.dismissSpinner();
      this.newArr = [];
      this.getAssessment(this.page);
      // this.events.publish(EventsKey.updateassessmentquestion, '');
      $('#reorderAssessment').modal('hide');
    })
  }
}
