import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { EditFaqComponent } from './edit-faq/edit-faq.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AddAssessmentComponent } from './add-assessment/add-assessment.component';
import { EditAssessmentComponent } from './edit-assessment/edit-assessment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { AddProgramComponent } from './add-program/add-program.component';
import { EditProgramComponent } from './edit-program/edit-program.component';
import { ReorderFaqComponent } from './reorder-faq/reorder-faq.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ReorderAssessmentComponent } from './reorder-assessment/reorder-assessment.component';
import { EditChapterComponent } from './edit-chapter/edit-chapter.component';
import { AddChapterComponent } from './add-chapter/add-chapter.component';
import { NarrativeComponent } from './narrative/narrative.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AdministratorAddComponent } from './administrator-add/administrator-add.component';
import { AdministratorEditComponent } from './administrator-edit/administrator-edit.component';
import { TopicsAddComponent } from './topics-add/topics-add.component';
import { TopicsEditComponent } from './topics-edit/topics-edit.component';
import { SmsAddProgramComponent } from './sms-add-program/sms-add-program.component';
import { SmsEditProgramComponent } from './sms-edit-program/sms-edit-program.component';
import { SmsEditChapterComponent } from './sms-edit-chapter/sms-edit-chapter.component';
import { SmsAddChapterComponent } from './sms-add-chapter/sms-add-chapter.component';
import { SmsAddTopicsComponent } from './sms-add-topics/sms-add-topics.component';
import { SmsEditTopicsComponent } from './sms-edit-topics/sms-edit-topics.component';
import { SmsEditAssessmentComponent } from './sms-edit-assessment/sms-edit-assessment.component';
import { SmsAddAssessmentComponent } from './sms-add-assessment/sms-add-assessment.component';
import { SmsNarrativeComponent } from './sms-narrative/sms-narrative.component';
import { SmsReorderAssessmentComponent } from './sms-reorder-assessment/sms-reorder-assessment.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ReorderTrainingProgramsComponent } from './reorder-training-programs/reorder-training-programs.component';
import { ModuleReorderComponent } from './module-reorder/module-reorder.component';
import { ChapterReorderComponent } from './chapter-reorder/chapter-reorder.component';
import { TopicReorderComponent } from './topic-reorder/topic-reorder.component';


@NgModule({
  declarations: [
    AddUserComponent,
    EditUserComponent,
    ChangePasswordComponent,
    AddFaqComponent,
    EditFaqComponent,
    AddAssessmentComponent,
    EditAssessmentComponent,
    AddProgramComponent,
    EditProgramComponent,
    ReorderFaqComponent,
    ReorderAssessmentComponent,
    EditChapterComponent,
    AddChapterComponent,
    NarrativeComponent,
    ResetPasswordComponent,
    AdministratorAddComponent,
    AdministratorEditComponent,
    TopicsAddComponent,
    TopicsEditComponent,
    SmsAddProgramComponent,
    SmsEditProgramComponent,
    SmsEditChapterComponent,
    SmsAddChapterComponent,
    SmsAddTopicsComponent,
    SmsEditTopicsComponent,
    SmsEditAssessmentComponent,
    SmsAddAssessmentComponent,
    SmsNarrativeComponent,
    SmsReorderAssessmentComponent,
    ReorderTrainingProgramsComponent,
    ModuleReorderComponent,
    ChapterReorderComponent,
    TopicReorderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    AngularEditorModule,
    NgSelectModule,
    DragDropModule, RichTextEditorAllModule

  ],
  exports: [
    AddUserComponent,
    EditUserComponent,
    ChangePasswordComponent,
    AddFaqComponent,
    EditFaqComponent,
    AddAssessmentComponent,
    EditAssessmentComponent,
    AddProgramComponent,
    EditProgramComponent,
    ReorderFaqComponent,
    ReorderAssessmentComponent,
    EditChapterComponent,
    AddChapterComponent,
    NarrativeComponent,
    ResetPasswordComponent,
    AdministratorAddComponent,
    AdministratorEditComponent,
    TopicsAddComponent,
    TopicsEditComponent,
    SmsAddProgramComponent,
    SmsEditProgramComponent,
    SmsEditChapterComponent,
    SmsAddChapterComponent,
    SmsAddTopicsComponent,
    SmsEditTopicsComponent,
    SmsEditAssessmentComponent,
    SmsAddAssessmentComponent,
    SmsNarrativeComponent,
    SmsReorderAssessmentComponent,
    ReorderTrainingProgramsComponent,
    ModuleReorderComponent,ChapterReorderComponent,TopicReorderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
