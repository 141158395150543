<div #container2 dropListScroller cdkDropList cdkDropListLockAxis="y" cdkDropListOrientation="vertical"
class="elements dropList_box" (cdkDropListDropped)="drop($event)">
<div class="element" *ngFor="let module of list ; let i = index" cdkDrag vsDragScroll
  [vsDragScrollContainer]="container2" class="vertical-scrool">
  <div class="number">{{i + 1}}</div>
  <span>{{module?.chapterName?.en}}<br>{{module?.chapterName?.am}}</span>
</div>
<div *ngIf="apiHitDone && !list?.length">
  <h5 class="text-center">No record found!</h5>
</div>

<div class="api-inline-loader" *ngIf="!apiHitDone">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
</div>
<button class="mt-2 btn btn-warning" type="submit" 
*ngIf="list?.length" (click)="reOrderModule()">Save</button>