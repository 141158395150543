export class User {
    userId: string;
    profileImg: string;
    name: string;
    mobileNumber: string;
    roleId: string;
    schoolName: string;
    locationId: string;
    role: string;
    constructor(payload: {
        userId: string;
        profileImg: string;
        name: string;
        mobileNumber: string;
        roleId: string;
        schoolName: string;
        locationId: string;
        role: string;
    }) {
        if (payload) {
            this.userId = payload.userId;
            this.profileImg = payload.profileImg;
            this.name = payload.name;
            this.mobileNumber = payload.mobileNumber;
            this.roleId = payload.roleId;
            this.schoolName = payload.schoolName;
            this.locationId = payload.locationId;
            this.role = payload.role;
        }
    }
}
