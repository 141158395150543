import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ImageSettingsModel, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';
declare var $: any;
@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
  @ViewChild('alertMsg') rteObj: RichTextEditorComponent;
  trainingList: Array<any> = new Array<any>();
  apiHitDone: boolean;
  entries: Entries;
  page: number = 1;
  limit: number = 10;
  totalResult: any;
  searchKeyword: string;
  trainingToBeEdited: any;
  routeUrl:any;
  constructor(
    private modalService: ModalService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
    private trainingService: TrainingService,
  ) {
    this.activatedRoute.queryParams.subscribe(
      ({ page }) => {
        this.page = page || 1;
        this.getTraining(this.page);
      }
    );
    this.events.subscribe(EventsKey.successfullyaddupdate, () => {
      this.getTraining(this.page);
    })
    this.routeUrl = this.commonService.getRouterUrl();
  }

  ngOnInit(): void {
    $(".description p").text(function (_index, currentText) {
      var maxLength = $(this).parent().attr('data-maxlength');
      if (currentText.length >= maxLength) {
        return currentText.substr(0, maxLength) + "...";
      } else {
        return currentText
      }
    });

  }
  showText(locale: "en" | "am", list: any) {
    switch (locale) {
      case "en":
        list.readMore_en = !list.readMore_en;
        break;

      case "am":
        list.readMore_am = !list.readMore_am;
        break;
    }
  }
  openModal(id: string, trainingDetail?: any) {
    // this.rteObj.setContentHeight();
    document.getElementById('addProgram').style.visibility = 'visible';
    document.getElementById('addProgram').style.opacity = '1';
    if (trainingDetail) {
      this.trainingToBeEdited = trainingDetail;
    } else {
      this.trainingToBeEdited = null
    }
    this.events.publish(EventsKey.edit, this.trainingToBeEdited);

    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  openReorderModal(id:string){
    this.limit = 500
    this.getTraining(this.page)
    this.modalService.open(id);
  }

  getTraining(page: number) {
    this.trainingService.getAllTraining({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword }).then(
      ({ trainingList, totalResult }) => {
        if (trainingList) {
          this.trainingList = trainingList;
          this.trainingList.forEach(
            (training) => {
              training.readMore_en = true;
              training.readMore_am = true;
            }
          );
          this.totalResult = totalResult;
          this.entries = this.commonService.calculateEntries(this.trainingList, page, this.limit, totalResult);
        } else {
          this.trainingList = [];
          this.totalResult = 0;
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.trainingList = [];
        this.totalResult = 0;
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        this.apiHitDone = true;
        this.page = page;
        if (this.page > 1 && this.trainingList.length == 0) {
          this.page = this.page - 1;
          this.getTraining(this.page);
        } else {
          let params: any = {
            "page": this.page
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }
          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }
  trainingToBeDeleted: string;
  deleteModal(id: string) {
    this.trainingToBeDeleted = id;
    $('#delete-offer').modal('show');
  }

  deleteTraining() {
    this.commonService.presentSpinner();
    let payload = {
      status: 'Delete'
    }
    this.trainingService.deleteTraining(this.trainingToBeDeleted).then(
      (res) => {

      }
    ).finally(
      () => {
        $('#delete-offer').modal('hide');
        this.getTraining(this.page);
        this.commonService.dismissSpinner();
      }
    )
  }

  goTOModules(training) {
    
    this.router.navigate([this.routeUrl[2] == 'sms' ? '/admin/sms/sms-modules' :'/admin/modules', training._id]);
  }

  getData(evt:any){
    this.getTraining(this.page);
  }
}
