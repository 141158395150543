export const EventsKey = {
    edit: 'edit',
    faqSuccessfullyupdate: 'faqSuccessfullyupdate',
    successfullyaddupdate: "successfullyaddupdate",
    openassessmentmodal: "openassessmentmodal",
    updateassessmentquestion: "updateassessmentquestion",
    questionlist: "questionlist",
    reorderlist: "reorderlist",
    changepassword: "changepassword",
    eventforexpertmenu: "eventforexpertmenu",
}