<style>

</style>
<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Administrators</li>
            </ol>
          </div>
          <!-- {{userInfo['admin_data']?.role -->
          <div class="page-title-right2" *ngIf="userInfo['admin_data'].role == 'superadmin'">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addAdministrator');">Add</button>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter">

                    <label>
                      <input type="search" class="form-control form-control-sm" placeholder="Search.."
                        [(ngModel)]="searchKeyword" (input)="getAdmins(1)">
                    </label>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th style="width:190px;">Name</th>
                    <th>Email ID</th>
                    <th style="width:100px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let admin of adminList | paginate: {
                    id: 'adminList',
                    itemsPerPage: limit,
                    currentPage: page,
                    totalItems: entries?.total
                  }; let i = index;">
                    <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                    <td data-title="Name">{{(admin?.additionalInfo?.name || '')}}</td>
                    <td data-title="Email ID">{{admin?.additionalInfo?.email||"--"}}</td>
                    <td data-title="Actions">
                      <button type="button" (click)="openModal('resetPassword',admin);"
                        class="mr-2 text-primary btn_style" [disabled]="userInfo['admin_data'].role != 'superadmin'"><i
                          class="mdi mdi-lock-reset align-middle mr-1 font-size-18"></i>
                      </button>
                      <button type="button" class="mr-2 text-primary btn_style"
                        (click)="openModal('addAdministrator',admin);"
                        [disabled]="userInfo['admin_data'].role != 'superadmin'"><i
                          class="mdi mdi-pencil font-size-18"></i>
                      </button> <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(admin?._id)"
                        *ngIf="userInfo['admin_data'].role == 'superadmin'"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !adminList?.length">
                    <td colspan="4">
                      <h5 class="text-center">Admin not found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="adminList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
                    {{entries?.total}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="adminList"
                      (pageChange)="getAdmins($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->
<!-- Add  Administrator Popup -->
<modal id="addAdministrator">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{adminToEdit ? 'Edit' : 'Add'}} Administrator</h5>
    <button type="button" class="close" (click)="closeModal('addAdministrator');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-administrator-add></app-administrator-add>
  </div>
</modal>
<!-- End Add  Administrator Popup -->
<!-- Edit  Administrator Popup -->
<modal id="editAdministrator">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Administrator</h5>
    <button type="button" class="close" (click)="closeModal('editAdministrator');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-administrator-edit></app-administrator-edit>
  </div>
</modal>
<!-- End Edit  Administrator Popup -->
<!-- Edit User Reset Password Popup -->
<modal id="resetPassword">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reset Password</h5>
    <button type="button" class="close" (click)="closeModal('resetPassword');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-reset-password></app-reset-password>
  </div>
</modal>
<!-- End Edit User Reset Password Popup -->
<div class="modal fade alert_popup" id="delete-offer" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Admin</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteUser()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>