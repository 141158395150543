import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgApexchartsModule } from "ng-apexcharts";
import { AdminComponent } from "./admin.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from "./users/users.component";
import { AdministratorsComponent } from './administrators/administrators.component';
import { UsersDetailsComponent } from './users/users-details/users-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { HttpClientModule } from "@angular/common/http";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgSelectModule } from "@ng-select/ng-select";
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { Daterangepicker } from "ng2-daterangepicker";
import { ModalModule } from "src/app/modal/modal.module";
import { SharedModule } from "src/app/shared-components/shared.module";
import { FaqsComponent } from './faqs/faqs.component';
import { ExpertComponent } from './expert/expert.component';
import { ProgramComponent } from './program/program.component';
import { ChaptersComponent } from './chapters/chapters.component';
import { TopicsComponent } from './topics/topics.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { ModulesComponent } from './modules/modules.component';
import { SmsComponent } from './sms/sms.component';
import { SmsModulesComponent } from './sms/sms-modules/sms-modules.component';
import { SmsChaptersComponent } from './sms/sms-modules/sms-chapters/sms-chapters.component';
import { SmsTopicsComponent } from './sms/sms-modules/sms-chapters/sms-topics/sms-topics.component';
import { SmsAssessmentComponent } from './sms/sms-modules/sms-chapters/sms-assessment/sms-assessment.component';
import { NgxPaginationModule } from "ngx-pagination";
import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragScrollModule } from "cdk-drag-scroll";

const routes: Routes = [
    {
        path: '', component: AdminComponent, children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'administrators', component: AdministratorsComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'users', component: UsersComponent },
            { path: 'users/details', component: UsersDetailsComponent },
            { path: 'notifications', component: NotificationsComponent },
            { path: 'system-settings', component: SystemSettingsComponent },
            { path: 'faq', component: FaqsComponent },
            { path: 'expert', component: ExpertComponent },
            { path: 'assessment/:id/:trainingId/:chaptersId', component: AssessmentComponent },
            { path: 'program', component: ProgramComponent },
            { path: 'chapters/:id/:trainingId', component: ChaptersComponent },
            {
                path: 'topics/:id/:trainingId/:chaptersId', component: TopicsComponent
            },
            { path: 'modules/:id', component: ModulesComponent },
            // { path: 'sms', component: SmsComponent },
            {
                path: "sms", children: [
                  { path: '', redirectTo: '/dashboard', pathMatch: "full" },
                  { path: 'sms', component: SmsComponent },
                  { path: 'sms-modules/:id', component: SmsModulesComponent },
                  { path: 'sms-chapters/:id/:trainingId', component: SmsChaptersComponent },
                  { path: 'sms-topics/:id/:trainingId/:chaptersId', component: SmsTopicsComponent },
                  { path: 'sms-assessment/:id/:trainingId/:chaptersId', component: SmsAssessmentComponent },
      
                ]
              },

        ]
    }
]


@NgModule({
    declarations: [
        AdminComponent,
        DashboardComponent,
        UsersComponent,
        AdministratorsComponent,
        UsersDetailsComponent,
        NotificationsComponent,
        SystemSettingsComponent,
        FaqsComponent,
        ExpertComponent,
        ProgramComponent,
        ChaptersComponent,
        TopicsComponent,
        AssessmentComponent,
        ModulesComponent,
        SmsComponent,
        SmsModulesComponent,
        SmsChaptersComponent,
        SmsTopicsComponent,
        SmsAssessmentComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgApexchartsModule,
        HttpClientModule,
        AngularEditorModule,
        NgSelectModule,
        Daterangepicker,
        ModalModule,
        SharedModule, NgxPaginationModule, RichTextEditorAllModule, DragDropModule,
        DragScrollModule

    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AdminModule { }