import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-edit-chapter',
  templateUrl: './sms-edit-chapter.component.html',
  styleUrls: ['./sms-edit-chapter.component.scss']
})
export class SmsEditChapterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
