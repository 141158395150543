<table class="table mb-0">
    <thead class="thead-light">
      <tr>
        <th style="width:30px;">No.</th>
        <th>Question</th>
      </tr>
    </thead>
    <tbody #container2 dropListScroller cdkDropList cdkDropListLockAxis="y" cdkDropListOrientation="vertical"
    class="elements" (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let tab of elements ; let i = index"  cdkDrag vsDragScroll [vsDragScrollContainer]="container2">
        <td data-title="No.">{{i + 1}}</td>
        <td data-title="Question">
          {{tab.question.en}}<br>
          {{tab.question.am}}
        </td>
      </tr>
    </tbody>
  </table>
  