import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  password: boolean;
  confirmpassword: boolean;
  ResetPasswordForm: FormGroup;
  submitted = false;
  token: any;
  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.resetKey
    });

  }

  ngOnInit(): void {
    this.ResetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    })
  }
  get f() { return this.ResetPasswordForm.controls; }
  showPassword() {
    this.password = !this.password;
  }
  showConfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }

  ResetPassword() {

    this.submitted = true;
    if (this.ResetPasswordForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    let postdata = {
      "password": this.ResetPasswordForm.value.password,
      "confirmPassword": this.ResetPasswordForm.value.confirmpassword,
      "resetKey": this.token,

    }
    this.authService.RestPassword(postdata).then(
      (res: any) => {

        if (res) {
          if (res.code === 200) {
            this.ResetPasswordForm.reset();
            this.submitted = false;
            this.commonService.dismissSpinner();
            this.router.navigate(['/']);
            this.commonService.showSuccessToastMsg('', res.message);

          }
        }
      }
    );
  }
}
