 <!-- <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Training Programs</li>
              </ol>
            </div>
            <div class="page-title-right2">
              <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                (click)="openModal('addProgram')">Add</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card table_page">
            <div class="card-body pt-3">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter">
                    <label>
                      <input type="search" class="form-control form-control-sm" placeholder="Search..">
                    </label>
                  </div>
                </div>
              </div>
               <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="thead-light">
                    <tr>
                      <th style="width:40px;">No.</th>
                      <th style="width:120px;">Training Name</th>
                      <th>Description</th>
                      <th style="width:140px;">Modules </th>
                      <th style="width:85px;">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-title="No.">1</td>
                      <td data-title="Training Name">Lorem ipsum<br>Lorem ipsum</td>
                      <td data-title="Description" >
                        <div class="description" data-maxlength="110">
                        <p>  In publishing and graphic design, Lorem ipsum is a
                          placeholder text commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content. Lorem ipsum may be used as a placeholder before final
                          copy is available.</p></div>
                          <div class="description" data-maxlength="90">
                          <p>በኅትመት እና በሥዕላዊ ንድፍ፣ Lorem ipsum ትርጉም ባለው ይዘት ላይ ሳይደገፍ
                          የሰነድ ምስላዊ ቅርጽን ወይም የፊደል አጻጻፍን ለማሳየት በተለምዶ የሚያገለግል የቦታ ያዥ ጽሑፍ ነው። የመጨረሻው ቅጂ ከመገኘቱ በፊት Lorem ipsum
                          እንደ ቦታ ያዥ ሊያገለግል ይችላል።</p></div>
                      </td>
                      <td data-title="Modules"><span class="badge badge-dark" [routerLink]="['/admin/sms/sms-modules']">4</span></td>
                      <td data-title="Actions">
                        <button type="button" (click)="openModal('editProgram')" class="mr-2 text-primary btn_style"><i
                              class="mdi mdi-pencil font-size-18"></i>
                          </button>
                          <a [routerLink]="['/admin/sms-modules']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">2</td>
                      <td data-title="Training Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Description" >
                        <div class="description" data-maxlength="110">
                        <p>  In publishing and graphic design, Lorem ipsum is a
                          placeholder text commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content. Lorem ipsum may be used as a placeholder before final
                          copy is available.</p></div>
                          <div class="description" data-maxlength="90">
                          <p>በኅትመት እና በሥዕላዊ ንድፍ፣ Lorem ipsum ትርጉም ባለው ይዘት ላይ ሳይደገፍ
                          የሰነድ ምስላዊ ቅርጽን ወይም የፊደል አጻጻፍን ለማሳየት በተለምዶ የሚያገለግል የቦታ ያዥ ጽሑፍ ነው። የመጨረሻው ቅጂ ከመገኘቱ በፊት Lorem ipsum
                          እንደ ቦታ ያዥ ሊያገለግል ይችላል።</p></div>
                      </td><td data-title="Modules"><span class="badge badge-dark" [routerLink]="['/admin/sms-modules']">4</span></td>
                      <td data-title="Actions">
                        <button type="button" (click)="openModal('editProgram')" class="mr-2 text-primary btn_style"><i
                              class="mdi mdi-pencil font-size-18"></i>
                          </button>
                          <a [routerLink]="['/admin/sms-modules']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">3</td>
                      <td data-title="Training Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Description" >
                        <div class="description" data-maxlength="110">
                        <p>  In publishing and graphic design, Lorem ipsum is a
                          placeholder text commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content. Lorem ipsum may be used as a placeholder before final
                          copy is available.</p></div>
                          <div class="description" data-maxlength="90">
                          <p>በኅትመት እና በሥዕላዊ ንድፍ፣ Lorem ipsum ትርጉም ባለው ይዘት ላይ ሳይደገፍ
                          የሰነድ ምስላዊ ቅርጽን ወይም የፊደል አጻጻፍን ለማሳየት በተለምዶ የሚያገለግል የቦታ ያዥ ጽሑፍ ነው። የመጨረሻው ቅጂ ከመገኘቱ በፊት Lorem ipsum
                          እንደ ቦታ ያዥ ሊያገለግል ይችላል።</p></div>
                      </td><td data-title="Modules"><span class="badge badge-dark" [routerLink]="['/admin/sms-modules']">4</span></td>
                      <td data-title="Actions">
                        <button type="button" (click)="openModal('editProgram')" class="mr-2 text-primary btn_style"><i
                              class="mdi mdi-pencil font-size-18"></i>
                          </button>
                          <a [routerLink]="['/admin/sms-modules']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">4</td>
                      <td data-title="Training Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Description" >
                        <div class="description" data-maxlength="110">
                        <p>  In publishing and graphic design, Lorem ipsum is a
                          placeholder text commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content. Lorem ipsum may be used as a placeholder before final
                          copy is available.</p></div>
                          <div class="description" data-maxlength="90">
                          <p>በኅትመት እና በሥዕላዊ ንድፍ፣ Lorem ipsum ትርጉም ባለው ይዘት ላይ ሳይደገፍ
                          የሰነድ ምስላዊ ቅርጽን ወይም የፊደል አጻጻፍን ለማሳየት በተለምዶ የሚያገለግል የቦታ ያዥ ጽሑፍ ነው። የመጨረሻው ቅጂ ከመገኘቱ በፊት Lorem ipsum
                          እንደ ቦታ ያዥ ሊያገለግል ይችላል።</p></div>
                      </td><td data-title="Modules"><span class="badge badge-dark" [routerLink]="['/admin/sms-modules']">4</span></td>
                      <td data-title="Actions">
                        <button type="button" (click)="openModal('editProgram')" class="mr-2 text-primary btn_style"><i
                              class="mdi mdi-pencil font-size-18"></i>
                          </button>
                          <a [routerLink]="['/admin/sms-modules']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td data-title="No.">5</td>
                      <td data-title="Training Name">Lorem ipsum<br>Lorem ipsum</td>
                        <td data-title="Description" >
                        <div class="description" data-maxlength="110">
                        <p>  In publishing and graphic design, Lorem ipsum is a
                          placeholder text commonly used to demonstrate the visual form of a document or a typeface
                          without relying on meaningful content. Lorem ipsum may be used as a placeholder before final
                          copy is available.</p></div>
                          <div class="description" data-maxlength="90">
                          <p>በኅትመት እና በሥዕላዊ ንድፍ፣ Lorem ipsum ትርጉም ባለው ይዘት ላይ ሳይደገፍ
                          የሰነድ ምስላዊ ቅርጽን ወይም የፊደል አጻጻፍን ለማሳየት በተለምዶ የሚያገለግል የቦታ ያዥ ጽሑፍ ነው። የመጨረሻው ቅጂ ከመገኘቱ በፊት Lorem ipsum
                          እንደ ቦታ ያዥ ሊያገለግል ይችላል።</p></div>
                      </td><td data-title="Modules"><span class="badge badge-dark" [routerLink]="['/admin/sms-modules']">4</span></td>
                      <td data-title="Actions">
                        <button type="button" (click)="openModal('editProgram')" class="mr-2 text-primary btn_style"><i
                              class="mdi mdi-pencil font-size-18"></i>
                          </button>
                          <a [routerLink]="['/admin/sms-modules']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a>
                        <a href="javascript:void(0);" class="text-danger"><i
                            class="mdi mdi-trash-can font-size-18"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info">Showing 1 to 10 of 11 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div class="dataTables_paginate paging_simple_numbers">
                      <ul class="pagination pagination-rounded">
                        <li class="paginate_button page-item previous disabd"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-left"></i></a>
                        </li>
                        <li class="paginate_button page-item active"><a href="#" class="page-link">1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                        </li>
                        <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-right"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- End Page-content -->
  
  <!-- Add Program Popup -->
  <!-- <modal id="addProgram" class="big-modal">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Add Program</h5>
      <button type="button" class="close" (click)="closeModal('addProgram');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <app-sms-add-program></app-sms-add-program>
    </div>
  </modal> -->
  <!-- Edit Program Popup -->
  
  <!-- Add Program edit Popup -->
  <modal id="editProgram" class="big-modal">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Edit Program</h5>
      <button type="button" class="close" (click)="closeModal('editProgram');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <app-sms-edit-program></app-sms-edit-program>
    </div>
  </modal>
  <!-- End Edit Program Popup -->


  <app-program></app-program>
  