import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { GlobalassessmentService } from 'src/app/services/AssessmentQuestion/globalassessment.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';
declare var $: any;

@Component({
  selector: 'app-edit-assessment',
  templateUrl: './edit-assessment.component.html',
  styleUrls: ['./edit-assessment.component.scss']
})
export class EditAssessmentComponent implements OnInit {
  @Input() ids: any
  editQuestion: FormGroup;
  answertype: any;
  options: any;
  otherAdded: boolean;
  curerctansweryes: boolean = false;
  curerctanswerno: boolean = false;
  submitted = false;
  questionToEdit: any;
  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private assessmentServices: GlobalassessmentService,
    private events: EventsService,
    private trainingService: TrainingService,
    private modalService: ModalService,
  ) {
    this.events.subscribe(EventsKey.openassessmentmodal, (data) => {
      if (data) {
        // this.editQuestion = this.assessmentServices.getEditAssessmentQuestionForm();
        let frmArray = this.editQuestion.get('options') as FormArray;
        frmArray.clear();
        this.addOptionsControlForEdit();
        this.questionToEdit = { ...data };
        console.log("this.questionToEdit", this.questionToEdit);
        // let currectans = this.editQuestion.fil 
        let correctans = this.questionToEdit.options.find((x) => x.correctAnswer)
        this.editQuestion.patchValue({
          questionType: data.questionType,
          question: {
            en: data.question.en,
            am: data.question.am,
          },
          yesChoice: correctans.option.en,
          // NoChoice: this.questionToEdit.options[1].option.en,
        });

        if (data.questionType == 'multipleChoice' || data.questionType == 'singleChoice') {
          this.populateOptionsForEdit(data.options)
        }
      } else {

      }

    })
    this.editQuestion = this.assessmentServices.getEditAssessmentQuestionForm();
    this.addOptionsControlForEdit();
  }
  questionTypes = [
    // { id: 1, value: 'Short', name: 'Short answer' },
    { id: 1, value: 'multipleChoice', name: 'Multiple choice' },
    { id: 2, value: 'singleChoice', name: 'Single choice' },
    { id: 3, value: 'yesNoChoice', name: 'Yes/No' },
    // { id: 4, value: 'Integer', name: 'Integer' },
    // { id: 5, value: 'GPS', name: 'GPS' },
    // { id: 6, value: 'Text', name: 'Text' },
    // { id: 6, value: 'Dropdown', name: 'School List' },
    // { id: 6, value: 'Page_Break', name: 'Page Break' },
    // { id: 6, value: 'Media', name: 'Media' },

  ];


  ngOnInit(): void {
    this.answertype = this.questionTypes[0].value
  }
  setHeight(element: HTMLTextAreaElement) {
    element.style.height = (element.scrollHeight) + 'px';
  }

  resetHeight(element: HTMLTextAreaElement) {
    element.style.height = '48px';
    element.style['overflow-y'] = 'hidden';
    return true;
  }
  addOptionsControl(type: 'editQuestion', value?: string) {

    this[type].addControl('options', new FormArray([]));
    this.options = this[type].controls['options']['controls'];

  }
  addOptionsControlForEdit() {
    this.editQuestion.addControl('options', new FormArray([]));
    this.options = this.editQuestion.controls['options']['controls'];
  }

  createOptionsForm(index: number) {
    let text = (index > -1 ? ('Option ' + (index + 1)) : 'Other');
    return new FormGroup({
      // questionId: new FormControl(),
      "option": new FormGroup({
        "en": new FormControl(text),
        "am": new FormControl(text)
      }),
      optionType: new FormControl(),
      correctAnswer: new FormControl(false, Validators.compose([Validators.required]))
    });
  }

  addMoreOptionsFields(option: string, formType: 'addQuestion' | 'editQuestion') {
    let optionsArray = this[formType].controls['options'] as FormArray;
    let group = this.createOptionsForm(option.length ? Number(option) : optionsArray.length);
    let count = 0;
    let othersIndex = -1;
    optionsArray.controls.forEach(
      (formGroup, index) => {
        if (formGroup.value.option.en == 'Other') {
          othersIndex = index;
          count += 1;
        }
      }
    );
    if (count > 0) {
      this.otherAdded = true;
    } else {
      if (group.value.option.en == 'Other') {
        this.otherAdded = true;
      } else {
        this.otherAdded = false;
      }
    }

    if (this.otherAdded) {
      if (othersIndex >= 0 && othersIndex < optionsArray.length) {
        let othersGroup = optionsArray.get([optionsArray.length - 1])
        optionsArray.removeAt(optionsArray.length - 1);
        group.patchValue({
          // 'option': 'Option ' + (optionsArray.length + 1)
          option: {
            en: 'Option ' + (optionsArray.length + 1),
            am: 'Option ' + (optionsArray.length + 1),
          }
        })
        optionsArray.push(group);
        optionsArray.push(othersGroup);
      } else {
        optionsArray.push(group);
      }
    } else {
      optionsArray.push(group);
    }

    this.options = this[formType].controls['options']['controls'];
  }

  optionsToDelete: Array<any> = [];
  removeOptionsFields(index: number, type: 'addQuestion' | 'editQuestion') {
    let options = this[type].controls['options'] as FormArray;
    if (type === 'editQuestion' && options.controls[index].value.optionId) {
      this.optionsToDelete.push({ optionId: options.controls[index].value.optionId, questionId: options.controls[index].value.questionId });
    }
    options.removeAt(index);
    let count = 0;
    options.controls.forEach(
      (formGroup) => {
        if (formGroup.value.option == 'Other') {
          count += 1;
        }
      }
    );
    if (count > 0) {
      this.otherAdded = true;
    } else {
      this.otherAdded = false;
    }

  }

  onChangeValue(formType: 'addQuestion' | 'editQuestion', e) {

    if (e.target.value == 'Yes') {
      this.curerctansweryes = true;
      this.curerctanswerno = false;

    } else {
      this.curerctansweryes = false;
      this.curerctanswerno = true;

    }
  }
  populateOptionsForEdit(options: Array<any>) {
    this.editQuestion.addControl('options', new FormArray([]));
    let optionsAsFormArray = this.editQuestion.controls['options'] as FormArray;
    options.forEach(
      (option, index) => {
        optionsAsFormArray.push(new FormGroup({
          // questionId: new FormControl(this.questionToEdit._id),
          // option: new FormControl(option.option),
          "option": new FormGroup({
            "en": new FormControl(option.option.en),
            "am": new FormControl(option.option.am)
          }),
          _id: new FormControl(option._id),
          optionType: new FormControl(option.optionType),
          correctAnswer: new FormControl(option.correctAnswer),
          // yesNoChoice: new FormControl(option.correctAnswer)
        }));

      }
    )
    this.options = this.editQuestion.controls['options']['controls'];
  }

  onRadioValueChange(formGroup: number, formType: 'addQuestion' | 'editQuestion', event) {
    let optionsArray = this[formType].controls['options'] as FormArray;
    optionsArray.controls.forEach(
      (form, index) => {
        form.patchValue({
          correctAnswer: index == formGroup ? true : false
        });
      }
    );
  }
  resetAddQuestionForm() {
    this.submitted = false;
    let frmArray = this.editQuestion.get('options') as FormArray;
    frmArray.clear();
    frmArray.push(new FormGroup({
      "option": new FormGroup({
        "en": new FormControl('Option 1'),
        "am": new FormControl('Option 1')
      }),
      optionType: new FormControl(),
      correctAnswer: new FormControl(false, Validators.compose([Validators.required]))
    }));
    this.editQuestion.patchValue({
      question: {
        en: '',
        am: ''
      },
      yesChoice: ''
    })
  }
  updateQuestion() {
    this.submitted = true;

    if (this.editQuestion.invalid) {
      return;
    }
    if (this.editQuestion.value.questionType == 'singleChoice' || this.editQuestion.value.questionType == 'multipleChoice') {
      let options: Array<any> = this.editQuestion.value.options;
      let count = 0
      options.forEach((option) => {
        if (option.correctAnswer) {
          count += 1
        }
      });
      if (!count) {
        this.commonService.showErrorToastMsg('Please select correct answer.')
        return;
      }
    }

    this.editQuestion.value.questionId = this.questionToEdit._id;
    this.editQuestion.value.assessmentsId = this.ids.assessmentId || '';
    this.editQuestion.value.chaptersId = this.ids.chaptersId || '';
    this.editQuestion.value.modulesId = this.ids.modulesId || '';
    this.editQuestion.value.trainingProgramsId = this.ids.trainingProgramsId || '';
    if (this.editQuestion.value.questionType == 'multipleChoice') {
      this.editQuestion.value.options.forEach(e => {
        e.optionType = 'checkbox'
      });

    } else if (this.editQuestion.value.questionType == 'singleChoice') {
      this.editQuestion.value.options.forEach(e => {
        e.optionType = 'radio'
      });
    } else {

      let opt = [
        {
          "option": {
            "en": 'Yes',
            "am": "yes am"
          },
          "optionType": "radio",
          "correctAnswer": this.curerctansweryes
        },
        {
          "option": {
            "en": 'No',
            "am": "No em"
          },
          "optionType": "radio",
          "correctAnswer": this.curerctanswerno
        }
      ]
      this.editQuestion.value.options = opt
      // console.log(payload)
    }
    delete this.editQuestion.value.questionOption;
    delete this.editQuestion.value.questionOptionLabel;
    delete this.editQuestion.value.yesNoChoice;
    this.commonService.presentSpinner();
    this.trainingService.addUpdateQuestion(this.editQuestion.value).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message);
        }
      }
    ).catch((error) => {

    }).finally(() => {
      this.commonService.dismissSpinner();
      this.resetAddQuestionForm();
      this.events.publish(EventsKey.updateassessmentquestion, '');
      this.modalService.close('editAssessment');
    })
  }
}
