<form class="form-horizontal" [formGroup]="adminForm">
  <div class="form-horizontal">
    <!-- <div class="mb-4">
    <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
      <label for="userpassword3">Password</label>
      <input [type]="oldpassword ? 'text' : 'password' " [autocomplete]="'off'" class="form-control" id="userpassword3"
        placeholder="Enter old password"> <i (click)="showoldPassword()" class=" auti-custom-input-icon auti-custom-eye"
        [ngClass]="oldpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
    </div>
    <div class="invalid-feedback" style="display:none;">
      <div>Password is required</div>
    </div>
  </div> -->
    <div class="mb-4">
      <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
        <label for="userpassword">Password</label>
        <input [type]="password ? 'text' : 'password' " class="form-control" placeholder="Enter new password"
          formControlName="password"> <i (click)="showPassword()" class=" auti-custom-input-icon auti-custom-eye"
          [ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>
      </div>
      <div *ngIf="resetPasswordFormSubmitted && adminForm?.controls['password']?.errors" class="invalid-feedback"
        style="display: inline;">
        <span *ngIf="adminForm?.controls['password']?.errors?.required">Password is required</span>
        <span *ngIf="adminForm?.controls['password']?.errors?.minlength">Password should be minimum 6 digit!</span>
      </div>
    </div>
    <div class="mb-4">
      <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
        <label for="userpassword">Password</label>
        <input [type]="confirmpassword ? 'text' : 'password' " class="form-control" placeholder="Enter confirm password"
          formControlName="confirmPassword" (input)="getpassword()">
        <i (click)="showConfirmPassword()" class=" auti-custom-input-icon auti-custom-eye"
          [ngClass]="confirmpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
      </div>
      <div class="invalid-feedback" *ngIf="resetPasswordFormSubmitted && adminForm?.controls['confirmPassword']?.errors"
        style="display: inline;">
        <span *ngIf="adminForm?.controls['confirmPassword']?.errors?.required">Password is required</span>
      </div><br>
      <div *ngIf="resetPasswordFormSubmitted && adminForm?.errors" class="invalid-feedback" style="display: inline;">
        <span *ngIf="adminForm?.errors?.mismatch">Password does not
          match!</span>
      </div>
    </div>
    <div class="mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light" type="submit"
        (click)="resetPassword()">Save</button>
    </div>
  </div>
</form>