import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpUserWrapperService } from '../http/http-wrapper/http-user-wrapper.service';
import { HttpInputData, HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';
import { AddAdmin, changePasswordForAdmin, ResetPasswordForAdmin, UpdateAdmin } from './admin.mode';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpWrapperService,
    private httpUser: HttpUserWrapperService,

  ) { }

  addAdmin(payload: AddAdmin) {
    return new Promise((resolve, reject) => {
      this.httpUser.post(Endpoints.adminListOrAddAdmin, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  getAdmins(query: { page: string; limit: string; search: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query.search) {
      httpParams = httpParams.set('search', query.search.trim());
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpUser.get(Endpoints.adminListOrAddAdmin, httpInput).subscribe(
        (res) => {
          resolve(res)
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  changeUserStatus(userId: string, payload) {
    return new Promise((resolve, reject) => {
      this.httpUser.put(Endpoints.adminsupdatestatus + `${userId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateAdmin(admin_id: string, payload: UpdateAdmin) {
    return new Promise((resolve, reject) => {
      this.httpUser.put(Endpoints.adminListOrAddAdmin + `/${admin_id}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  resetPassword(payload: ResetPasswordForAdmin) {
    let admin_id = payload._id;
    delete payload._id;
    return new Promise((resolve, reject) => {
      this.httpUser.put(Endpoints.adminschangepassword + `/${admin_id}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  changePassword(payload: changePasswordForAdmin) {

    return new Promise((resolve, reject) => {
      this.httpUser.put(Endpoints.changepassword, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
}
