<form [formGroup]="adminForm">
  <div class="row">
    <!-- <div class="col-lg-12">
        <div class="form-group">
          <label>Picture</label>
          <div class="custom_file custom_img_input">
            <input type="file" class="custom_file_input">
            <div class="file_img"
              style="background-color: rgb(245, 245, 245);background-image: url(assets/images/users/admin.jpg);">
            </div>

          </div>
        </div>
      </div> -->
    <div class="col-lg-12">
      <div class="form-group">
        <label for="validationCustom01">Name</label>
        <input type="text" class="form-control" formControlName="name">
        <div *ngIf="adminFormSubmitted && adminForm?.controls['name']?.errors" class="error-msg mb-1">
          <span *ngIf="adminForm?.controls['name']?.errors?.required">Required!</span>
        </div>
      </div>

    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <label for="validationCustom01">Email</label>
        <input type="email" class="form-control" formControlName="email">
        <div *ngIf="adminFormSubmitted && adminForm?.controls['email']?.errors" class="error-msg mb-1">
          <span *ngIf="adminForm?.controls['email']?.errors?.required">Required!</span>
          <span *ngIf="adminForm?.controls['email']?.errors?.email">Invalid email!</span>
        </div>
      </div>

    </div>
    <div class="col-lg-12" *ngIf="!adminToEdit">
      <div class="form-group">
        <label for="validationCustom01">Password</label>
        <div class="password_box">
          <input [type]="password ? 'text' : 'password' " [autocomplete]="'off'" class="form-control"
            formControlName="password">
          <i (click)="showPassword()" class=" auti-custom-input-icon auti-custom-eye"
            [ngClass]="password ? 'ri-eye-fill' : 'ri-eye-line'"></i>
        </div>
        <div *ngIf="adminFormSubmitted && adminForm?.controls['password']?.errors" class="error-msg mb-1">
          <span *ngIf="adminForm?.controls['password']?.errors?.required">Required!</span>
          <span *ngIf="adminForm?.controls['password']?.errors?.minlength">Password should be minimum 6 digit!</span>
        </div>
      </div>

    </div>
    <div class="col-lg-12" *ngIf="!adminToEdit">
      <div class="form-group">
        <label for="validationCustom01">Confirm Password</label>
        <div class="password_box">
          <input [type]="confirmpassword ? 'text' : 'password' " [autocomplete]="'off'" class="form-control"
            formControlName="confirmPassword"> <i (click)="showconfirmPassword()"
            class=" auti-custom-input-icon auti-custom-eye"
            [ngClass]="confirmpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
        </div>
        <div *ngIf="adminFormSubmitted && adminForm?.controls['confirmPassword']?.errors" class="error-msg mb-1">
          <span *ngIf="adminForm?.controls['confirmPassword']?.errors?.required">Required!</span>
        </div>
        <div *ngIf="adminFormSubmitted && adminForm?.errors" class="error-msg mb-1">
          <span *ngIf="adminForm?.errors?.mismatch">Password does not
            match!</span>
        </div>
      </div>

    </div>
  </div>
  <button class="btn btn-warning" type="submit" (click)="addOrUpdateAdmin()">Save</button>
</form>