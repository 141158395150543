import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';
import { User } from 'src/app/services/user/user.model';
import { UserservicesService } from 'src/app/services/user/userservices.service';
declare var $: any;
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  userRole: any = [];
  userForm: FormGroup;
  userFormSubmitted: boolean;
  fileToUpload: File = null;
  image_upload: string = '';
  userDetail: any;
  constructor(
    private commonService: CommonService,
    private userService: UserservicesService,
    private modalService: ModalService,
    private events: EventsService,
    private trainingService: TrainingService,
  ) {
    this.getUserRole();
    this.getlocation();

    this.events.subscribe(EventsKey.edit, (data) => {
      if (data) {
        this.image_upload = '';
        this.userDetail = data;
        // let locationId = ''
        let roleId = this.userRole.find((x => x.roleName.en === (data.roleData ? (data.roleData != '' ? data.roleData.roleName.en : ''):'')))
        if (data.locationData && data.locationData._id) {
          var locationId = this.adminLocationList.find((x => x.locationName.en === data.locationData.locationName.en))
          this.userDetail.locationId = locationId._id ? locationId._id : '';
        } else {
          this.userDetail.locationId = '';
        }
        if (roleId) {

          this.userDetail.roleId = roleId._id;
        } else {
          this.userDetail.roleId = ''
        }
        // this.userForm.controls['roleId'].setValue(data.Role.roleName)
        this.image_upload = this.userDetail.additionalInfo.profileImage;
        this.userForm.patchValue({
          name: this.userDetail.additionalInfo.name,
          mobileNumber: this.userDetail.additionalInfo.mobileNumber,
          roleId: this.userDetail.additionalInfo.roleId._id,
          schoolName: this.userDetail.additionalInfo.schoolName,
          locationId: this.userDetail.additionalInfo.locationId ? this.userDetail.additionalInfo.locationId._id : '',
          role: this.userDetail.role,
        })
        // this.userForm.patchValue(this.userDetail.additionalInfo)
      } else {
        this.userForm.reset();
        this.image_upload = '';
        this.userFormSubmitted = false;
        this.userForm.patchValue({
          role: '',
          roleId: '',
          locationId: '',

        })
        this.userDetail = null
      }

    })
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      "profileImage": new FormControl(''),
      "name": new FormControl('', Validators.compose([Validators.required])),
      "mobileNumber": new FormControl('', Validators.compose([Validators.required, Validators.maxLength(15), Validators.minLength(10)])),
      "roleId": new FormControl('', Validators.compose([Validators.required])),
      "schoolName": new FormControl('', Validators.compose([Validators.required])),
      "locationId": new FormControl('', Validators.compose([Validators.required])),
      "role": new FormControl('', Validators.compose([Validators.required])),

    });
  }
  numberOnly(event, controlName?: string): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (controlName) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }
  }
  getUserRole() {
    this.userService.getUserRole().then(
      ({ adminRoleList }) => {
        if (adminRoleList) {
          this.userRole = adminRoleList;
          console.log("userRole", this.userRole)
        }
      }
    ).catch((error) => {
      this.userRole = [];
    })
  }
  adminLocationList: any = []
  getlocation() {
    this.commonService.presentSpinner();
    this.trainingService.getLocationData({ "page": '1', "limit": '1000' }).then(
      ({ adminLocationList }) => {
        if (adminLocationList) {
          this.adminLocationList = adminLocationList;
        } else {
          this.adminLocationList = [];
        }
      }
    ).finally(() => {
      this.commonService.dismissSpinner();
    })
  }

  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  selectImage(event) {

    this.imageErrMsg = '';
    var file: File = event.target.files[0];
    this.currentFile = file;
    var imagetype = this.currentFile.type.split('/');
    var sizeInMB = (Math.round(this.currentFile.size * 100 / (1024 * 1024)) / 100).toString();
    if (parseInt(sizeInMB) > 10) {
      this.commonService.showErrorToastMsg('', 'Only 10 MB image allowed');
      return
    }
    let ValidImageExt = ["jpeg", "png", "jpg", "webp", "gif", "x-icon"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Only jpg, jpeg ,webp,gif & x-icon image formats are allowed";
      this.commonService.showErrorToastMsg('', this.imageErrMsg);
      this.userForm.controls['profileImage'].setValue('');
      return false;
    }
    this.fileToUpload = this.currentFile;
    var frmData = new FormData();
    frmData.append("profileImg", this.fileToUpload);

    this.uploadImage(frmData)
  }

  uploadImage(frmData) {
    this.commonService.presentSpinner();
    // var frmData = new FormData();
    // frmData.append("profileImg", this.fileToUpload);
    this.userService.imageUpload(frmData).then(
      (res: any) => {
        if (res.code == 200) {
          this.image_upload = res.image_upload
        }
      }
    ).finally(() => {

      this.commonService.dismissSpinner();

    })
  }
  addOrUpdateUser() {
    let promise: Promise<any> = null;
    let payload = null

    this.userFormSubmitted = false;
    if (this.userForm.invalid) {
      this.userFormSubmitted = true;
      return;
    }
    payload = new User(this.userForm.value);
    if (this.userDetail) {
      payload.userId = this.userDetail._id;
    } else {
      payload.userId = '';
    }
    payload.profileImage = this.image_upload;
    // console.log("payload", payload)

    this.commonService.presentSpinner();
    this.userService.addUser(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.modalService.close('addUser');
        } else {

        }
      }
    ).catch((error) => {
      if (error.error.code == 400) {
        // this.commonService.showErrorToastMsg("Mobile number minimum length should be 10  digit");
        this.commonService.showErrorToastMsg(error.error.message);
      } else {
        this.commonService.showErrorToastMsg("Mobile number already exist");

      }
    }).finally(() => {
      this.image_upload = ''
      this.events.publish(EventsKey.faqSuccessfullyupdate, '');
      this.commonService.dismissSpinner();

    })
  }
}
