<div class="page-content">

  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Training Programs</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
            (click)="openReorderModal('traningModal')">Reorder</button>
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addProgram')">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter">
                  <label>
                    <input type="search" class="form-control form-control-sm" placeholder="Search.."
                      [(ngModel)]="searchKeyword" (input)="getTraining(1)">
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th style="width:100px;">Picture</th>
                    <th style="width:120px;">Training Name</th>
                    <th style="width:120px;">Description</th>
                    <th style="width:140px;">Modules </th>
                    <th style="width:85px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let training of trainingList | paginate: {
                    id: 'trainingList',
                    itemsPerPage: limit,
                    currentPage: page,
                    totalItems: entries?.total
                  }; let i = index;">
                    <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                    <td data-title="Picture">
                      <div class="training-img">
                        <div class="bg_img"
                          style="background-color: rgb(248, 249, 250); background-image: url({{training?.trainingImage}});">
                        </div>
                        <!-- <div class="bg_img"
                          style="background-color: rgb(248, 249, 250); background-image: url(assets/images/users/user-1.jpg);">
                        </div> -->
                      </div>
                    </td>
                    <td data-title="Training Name">{{training?.trainingName?.en}}<br>{{training?.trainingName?.am}}</td>
                    <td data-title="Description">
                      <div class="description" style="text-align: justify;" title="{{training?.description?.en}}">
                        <a (click)="showText('en', training)">
                          <p class="description-text" [innerHTML]="training?.description?.en"
                            [ngClass]="{'limitTextHeight': training?.readMore_en}">...
                          </p>
                        </a>

                      </div>
                      <div class="description" style="text-align: justify;" title="{{training?.description?.am}}">
                        <a (click)="showText('am', training)">
                          <p class="description-text" [innerHTML]="training?.description?.am"
                            [ngClass]="{'limitTextHeight': training?.readMore_am}">
                          </p>
                        </a>
                      </div>
                      <!-- <div class="description" data-maxlength="110">
                        <p [innerHtml]="training.description.en"></p>
                      </div>
                      <div class="description" data-maxlength="90">
                        <p [innerHtml]="training.description.am"></p>
                      </div> -->
                    </td>
                    <td data-title="Modules"><span class="badge badge-dark"
                        (click)="goTOModules(training)">{{training?.modulesCount}}</span>
                    </td>
                    <td data-title="Actions">
                      <button type="button" (click)="openModal('addProgram',training)"
                        class="mr-2 text-primary btn_style"><i class="mdi mdi-pencil font-size-18"></i>
                      </button>
                      <a (click)="goTOModules(training)" class="mr-2 text-solitud"><i
                          class="fas fa-eye font-size-18"></i></a>
                      <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(training?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !trainingList?.length">
                    <td colspan="4">
                      <h5 class="text-center">Training programs not found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && trainingList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
                    {{entries?.total}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="trainingList"
                      (pageChange)="getTraining($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->

<!-- Add Program Popup -->
<modal id="addProgram" class="big-modal" style="display: block;opacity: 0;visibility: hidden;">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{trainingToBeEdited ? 'Edit' : 'Add'}} Program</h5>
    <button type="button" class="close" (click)="closeModal('addProgram');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-program></app-add-program>
  </div>
</modal>
<!-- Edit Program Popup -->

<!-- Add Program edit Popup -->
<modal id="editProgram" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Program</h5>
    <button type="button" class="close" (click)="closeModal('editProgram');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-program></app-edit-program>
  </div>
</modal>
<!-- End Edit Program Popup -->

<!-- Add Training Popup -->
<modal id="traningModal" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reorder Training</h5>
    <button type="button" class="close" (click)="closeModal('traningModal');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-reorder-training-programs [trainingList]="trainingList"  (trainingReorderSuccess)="getData($event)"></app-reorder-training-programs>
  </div>
</modal>
<!-- Edit Training Popup -->


<div class="modal fade alert_popup" id="delete-offer" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Training</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteTraining()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>