import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
declare var $: any;
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend
} from "ng-apexcharts";
import { CommonService } from 'src/app/services/common/common.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AuthService } from 'src/app/services/auth/auth.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions1: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;


  constructor(
    private daterangepickerOptions: DaterangepickerConfig,
    private commonService: CommonService,
    private authService: AuthService,
  ) {
    this.appUserOrSmsUserGraph();

    this.chartOptions2 = {
      series: [
        {
          name: "Number of SMS Training",
          color: '#354d9b',
          data: [28, 29, 33, 36, 40, 45, 50]

        },
        {
          name: "Number of SMS",
          color: '#da1773',
          data: [50, 45, 40, 30, 25, 30, 50]

        }
      ],
      chart: {
        height: 300,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "smooth"
      },

      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
      yaxis: {
        title: {
          text: "Temperature"
        },
        min: 5,
        max: 40
      },

    };


    this.daterangepickerOptions.settings = {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      "opens": "right",
      ranges: {
        'Today': [moment(), moment()],
        // 'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    };

  }
  public picker1 = {
    opens: 'left',
    startDate: moment().subtract(5, 'day'),
    endDate: moment(),
    isInvalidDate: function (date: any) {
      if (date.isSame('2017-09-26', 'day'))
        return 'mystyle';
      return false;
    }
  }
  public chosenDate: any = {
    start: moment().subtract(6, 'days'),
    end: moment().add(0, 'days'),
  };
  ShowdStardate = '';
  ShowdEndate = '';
  public selectedDate(value: any, dateInput: any): void {

    dateInput.start = value.start;
    dateInput.end = value.end;
    this.ShowdStardate = value.start.format('YYYY-MM-DD');
    this.ShowdEndate = value.end.format('YYYY-MM-DD');
    this.getDashboardData();
  }
  ngOnInit(): void {
    this.getDashboardData();

  }


  yAxisAppUser: any = []
  yAxisSMSUser: any = []
  xAxis: any = []
  dbResponse: any;
  getDashboardData() {
    let timePeriod = "0";
    this.ShowdStardate = this.chosenDate.start.format('YYYY-MM-DD');
    this.ShowdEndate = this.chosenDate.end.format('YYYY-MM-DD');
    this.commonService.presentSpinner();
    this.authService.getDashboardData(timePeriod, this.ShowdStardate, this.ShowdEndate).then(
      (res: any) => {
        if (res.code == 200) {
          this.dbResponse = res;
          this.yAxisAppUser = this.dbResponse.yAxisAppUser;
          this.yAxisSMSUser = this.dbResponse.yAxisSMSUser;
          this.xAxis = this.dbResponse.xAxis;

        } else {

        }
      }
    ).catch(
      (error) => {
        if (error.error.code == 400) {
          this.chosenDate.Start;
          this.chosenDate.end;
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.appUserOrSmsUserGraph()
      }
    ).finally(
      () => {
        // if (this.timePeriod) {
        // 	let periodExist = this.periodOptions.find((option) => { return option.value == this.timePeriod })
        // 	this.router.navigate([], { queryParams: { period: periodExist.name } });
        // } else {
        // 	this.location.replaceState('/admin/dashboard')
        // }

      }
    )
  }
  appUserOrSmsUserGraph() {
    this.chartOptions1 = {
      series: [
        {
          name: "Number of Users",
          color: '#354d9b',
          data: this.yAxisAppUser

        },
        {
          name: "Number of SMS Users",
          color: '#da1773',
          data: this.yAxisSMSUser

        }

      ],
      chart: {
        // height: 300,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "smooth"
      },

      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: this.xAxis,
      },
      yaxis: {
        title: {
          text: "Temperature"
        },
        // min: 5,
        // max: 40
      },

    };
  }
}
