import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-details',
  templateUrl: './users-details.component.html',
  styleUrls: ['./users-details.component.scss']
})
export class UsersDetailsComponent implements OnInit {

  forgotpassword: boolean;
  confirmforgotpassword: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }
 
  showforgotPassword() {
    this.forgotpassword = !this.forgotpassword;
  }
  showConfirmforgotPassword() {
    this.confirmforgotpassword = !this.confirmforgotpassword;
  }

}
