<div class="form">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Description (English)</label>
        <angular-editor id="editor1"></angular-editor>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Description (Amharic)</label>
        <angular-editor id="editor1"></angular-editor>
      </div>
    </div>
  </div>
  <button class="btn btn-warning" type="submit">Save</button>
</div>