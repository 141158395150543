import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/terms-condition/terms-condition.component';
import { AuthGuard } from './guards/auth.guard';
import { Storage_Keys } from './services/storage/storage.keys';
class RedirectUrl {
  /**
   * 
   * @param path parameter accepts only```''``` (empty path) or ```'**'``` (wild route)
   * @returns  an angular ```Route```
   */
  static getRedirectUrl(path: '' | '**'): Route {
    const savedData = JSON.parse(JSON.parse(localStorage.getItem(Storage_Keys.token)));
    let route: Route = null;
    if (savedData) {
      route = { "path": path, redirectTo: '/admin/dashboard', pathMatch: 'full' };
    } else {
      route = { "path": path, redirectTo: "/login", pathMatch: 'full' };
    }
    return route;
  }
}
const routes: Routes = [
  RedirectUrl.getRedirectUrl(''),
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ForgotPasswordComponent },
  { path: 'privacy-policy/:language', component: PrivacyPolicyComponent },
  { path: 'terms-condition/:language', component: TermsConditionComponent },
  { path: 'about-us/:language', component: AboutUsComponent },
  RedirectUrl.getRedirectUrl('**'),
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
