import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { StorageService } from 'src/app/services/storage/storage.service';
declare var $: any;
@Component({
  selector: 'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.scss']
})
export class AdministratorsComponent implements OnInit {
  adminList: Array<any> = new Array<any>();
  apiHitDone: boolean;
  entries: Entries;
  page: number = 1;
  limit: number = 10;
  totalResult: any;
  searchKeyword: string;
  adminToEdit: any;
  userInfo: any;
  constructor(
    private modalService: ModalService,
    private commonService: CommonService,

    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
    private adminService: AdminService,
    private storage: StorageService,
  ) {
    this.userInfo = this.storage.getSavedData();
    this.activatedRoute.queryParams.subscribe(
      ({ page }) => {
        this.page = page || 1;
        this.getAdmins(this.page);
      }
    );
    this.events.subscribe(EventsKey.successfullyaddupdate, () => {
      this.getAdmins(this.page);
    })
  }

  ngOnInit(): void {
  }

  openModal(id: string, admindetail?: any) {
    if (admindetail) {
      this.adminToEdit = admindetail;
    } else {
      this.adminToEdit = null
    }
    this.events.publish(EventsKey.edit, this.adminToEdit);
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  getAdmins(page: number) {
    this.adminService.getAdmins({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword }).then(
      ({ adminList, totalResult }) => {
        if (adminList) {
          this.adminList = adminList;
          this.totalResult = totalResult;
          this.entries = this.commonService.calculateEntries(this.adminList, page, this.limit, totalResult);
        } else {
          this.adminList = [];
          this.totalResult = 0;
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.adminList = [];
        this.totalResult = 0;
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        this.apiHitDone = true;
        this.page = page;
        if (this.page > 1 && this.adminList.length == 0) {
          this.page = this.page - 1;
          this.getAdmins(this.page);
        } else {
          let params: any = {
            "page": this.page
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }
          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }
  userToBeDeleted: string;
  deleteModal(userId: string) {
    this.userToBeDeleted = userId;
    $('#delete-offer').modal('show');
  }

  deleteUser() {
    this.commonService.presentSpinner();
    let payload = {
      status: 'Delete'
    }
    this.adminService.changeUserStatus(this.userToBeDeleted, payload).then(
      (res) => {

      }
    ).finally(
      () => {
        $('#delete-offer').modal('hide');
        this.getAdmins(this.page);
        this.commonService.dismissSpinner();
      }
    )
  }
}