import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-narrative',
  templateUrl: './sms-narrative.component.html',
  styleUrls: ['./sms-narrative.component.scss']
})
export class SmsNarrativeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
