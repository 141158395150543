<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Users</li>
            </ol>
          </div>
          <!-- <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addUser')">Add</button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter">
                    <label>
                      <select id="selectType" class="custom-select custom-select-sm form-control" [(ngModel)]="role"
                        (change)="getUserList(1)">
                        <option value="" selected>Select Type</option>
                        <option value="trainee">Trainee</option>
                        <option value="guest">Guest</option>
                      </select>
                      <select id="selectRole" class="custom-select custom-select-sm form-control" [(ngModel)]="roleId"
                        (change)="getUserList(1)">
                        <option value="" selected>Select Role</option>
                        <option [value]="role._id" *ngFor="let role of userRole"> {{role?.roleName?.en}}
                        </option>

                      </select>
                      <input type="search" class="form-control form-control-sm" placeholder="Search.."
                        [(ngModel)]="search" (input)="getUserList(1)">
                    </label>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th style="width:40px;">Picture</th>
                    <th style="width:90px;">User Detail</th>
                    <th style="width:90px;">Role</th>
                    <th style="width:135px;">School</th>
                    <th style="width:135px;">Location</th>
                    <th style="width:75px;">User Type</th>
                    <!-- <th style="width:85px;">% Complete</th> -->
                    <th style="width:78px;">User Status</th>
                    <th style="width:55px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let user of userList | paginate: {
                    id: 'userList',
                    itemsPerPage: limit,
                    currentPage: currentPage,
                    totalItems: entries?.total
                  };
                  ; let i = index;">
                    <td data-title="No.">{{ limit * (currentPage - 1) + i + 1 }}</td>
                    <td data-title="Picture">
                      <div class="bg_img"
                        style="background-color: rgb(248, 249, 250); background-image: url(assets/images/users/admin.jpg);"
                        [ngStyle]="{'background-image': user?.additionalInfo?.profileImage ? 'url('+user?.additionalInfo?.profileImage+')' : 'url(assets/images/users/admin.jpg)'}">
                      </div>
                      <!-- <div class="bg_img"
                        style="background-color: rgb(248, 249, 250); background-image: url({{user?.additionalInfo?.profileImage}});">
                      </div> -->
                    </td>
                    <td data-title="User Detail">{{user?.additionalInfo?.name}}<br>{{user?.additionalInfo?.mobileNumber
                      || ''}}
                      <br>{{user?.additionalInfo?.email || ''}}
                    </td>
                    <td data-title="Role">
                      {{user?.additionalInfo?.roleId?.roleName?.en}}<br>{{user?.additionalInfo?.roleId?.am}}</td>
                    <td data-title="School">{{user?.additionalInfo?.schoolName}}</td>
                    <td data-title="Location">
                      {{user?.additionalInfo?.locationId?.locationName?.en}}<br>{{user?.additionalInfo?.locationId?.locationName?.am}}
                    </td>
                    <td data-title="User Type">{{user?.role}} </td>
                    <!-- <td data-title="% Complete"><span class="badge badge-dark">50 %</span></td> -->
                    <td data-title="Verify User">
                      <label class="switch">
                        <input type="checkbox" id="verify" (change)="changeUserStatus(user)"
                          [checked]="user?.status == 'Active' ? true: false"><span class="slider round"></span>
                      </label>
                    </td>
                    <td data-title="Actions">
                      <button type="button" (click)="openModal('addUser', user)" class="mr-2 text-primary btn_style"><i
                          class="mdi mdi-pencil font-size-18"></i>
                      </button> <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(user?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !userList?.length">
                    <td colspan="4">
                      <h5 class="text-center">No record found!</h5>
                    </td>
                  </tr>

                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && userList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of {{entries?.total}}
                    entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="userList"
                      (pageChange)="getUserList($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->

<!-- Add User Popup -->
<modal id="addUser">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{userToBeEdited ? 'Edit' : 'Add'}} User</h5>
    <button type="button" class="close" (click)="closeModal('addUser');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-user></app-add-user>
  </div>
</modal>
<!-- End Add User Popup -->

<!-- Edit  User Popup -->
<modal id="editUser">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit User</h5>
    <button type="button" class="close" (click)="closeModal('editUser');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-user></app-edit-user>
  </div>
</modal>
<!-- End Edit  User Popup -->

<div class="modal fade alert_popup" id="delete-offer" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete User</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteUser()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>