import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpUserWrapperService } from '../http/http-wrapper/http-user-wrapper.service';
import { HttpInputData, HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class UserservicesService {

  constructor(
    private http: HttpWrapperService,
    private commonService: CommonService,
    private httpUser: HttpUserWrapperService,
  ) { }

  getUserList(query?: { page?: string; limit?: string; search?: string, role?: string, roleId?: string }) {

    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    if (query && query.role) {
      httpParams = httpParams.set('role', query.role);
    }
    if (query && query.roleId) {
      httpParams = httpParams.set('roleId', query.roleId);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpUser.get(Endpoints.users, httpInput).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          // this.commonService.showErrorMessageForApiResponse(err);
          reject(err);
        }
      );
    });
  }

  changeUserStatus(userId: string, payload) {

    return new Promise((resolve, reject) => {
      this.httpUser.put(Endpoints.usersstatus + `/${userId}`, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addUser(payload) {
    return new Promise((resolve, reject) => {
      this.httpUser.post(Endpoints.addupdate, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          // this.commonService.showErrorMessageForApiResponse(err);
          reject(err);
        }
      );
    });
  }
  imageUpload(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(Endpoints.imageupload, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          // this.commonService.showErrorMessageForApiResponse(err);
          reject(err);
        }
      );
    });
  }

  getUserRole() {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.role).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          // this.commonService.showErrorMessageForApiResponse(err);
          reject(err);
        }
      );
    });
  }
}
