<div class="page-content details-page">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a [routerLink]="['/admin/users']">User</a>
                </li>
                <li class="breadcrumb-item active">Details</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div class="profile-content">
                <div class="profile-details">
                  <div class="user-img">
                    <div class="user_img"
                      style="background-color: rgb(245, 245, 245); background-image: url(assets/images/users/admin.jpg);">
                    </div>
                  </div>
                </div>
                <div class="edit_mode">
                  <div class="switch_profile">
                    <label class="label-text">Active</label>
                    <label class="switch">
                      <input type="checkbox" id="verify" value="1" checked> <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="edit_user">
                    <!-- <button type="button" data-target=".forgot_password" data-toggle="modal"
                      class="mr-2 text-primary btn_style"><i
                        class="mdi mdi-lock-reset align-middle mr-1 font-size-18"></i>
                    </button> -->
                    <button type="button" data-target=".editModal" data-toggle="modal"
                      class="mr-2 text-primary btn_style"><i class="mdi mdi-pencil font-size-18"></i>
                    </button>
                  </div>
                </div>
              </div>
              <table class="table mb-0 basic_profile">
                <tbody>
                  <tr>
                    <td>
                      <h6>Name:</h6>
                    </td>
                    <td style="text-align: end;">Alyse P. Garcia</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Email:</h6>
                    </td>
                    <td style="text-align: end;">AlysePGarcia@jourrapide.com
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Phone No.:</h6>
                    </td>
                    <td style="text-align: end;">9876543201</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Address:</h6>
                    </td>
                    <td style="text-align: end;">Rammurthy nagar, Bangalore-560016</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Total Revenue	:</h6>
                    </td>
                    <td style="text-align: end;">QAR 200.00	</td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Chat History :</h6>
                    </td>
                    <td style="text-align: end;"><a href="javascript:void(0);" class="mr-2 text-solitud" data-toggle="modal"
                      data-target=".chat-history">View</a>	</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card table_page">
            <div class="card-body pt-3">
              <div class="table-responsive">
                <div class="row">
                  <div class="col-md-6"><h4 class="card-title mb-4">Orders List</h4>
                  </div>
                  <div class="col-md-6">
                    <div class="dataTables_filter">
                      <label>
                        <input type="search" class="form-control form-control-sm" placeholder="Search..">
                      </label>
                    </div>
                  </div>
                </div>
  
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th style="width:30px;">No.</th>
                      <th  >Order#</th> 
                      <th style="width: 100px;">Order Date</th>
                      <th style="width:130px;">Product Quantity	</th>
                       <th style="width: 80px;">Amount</th>
                      <th style="width: 100px;">Status</th>
                      <th style="width:55px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-title="No.">1</td>
                      <td data-title="Order#">#0976543</td>
                      <td data-title="Order Date">Apr 21, 2021</td>
<td data-title="Product Quantity"><span class="badge badge-dark">4</span></td>                      <td data-title="Amount">SAR 25.99</td>
                      <td data-title="Status">Pending </td>
                      <td data-title="Action"> <a href="javascript:void(0)" class="mr-2 text-solitud"
                          [routerLink]="['/admin/orders/details']" routerLinkActive="router-link-active"><i
                            class="fas fa-eye font-size-18"></i></a>
                      </td>
                    </tr>
                    <tr>
                        <td data-title="No.">2</td>
                        <td data-title="Order#">#0976543</td>
                        <td data-title="Order Date">Apr 21, 2021</td>
  <td data-title="Product Quantity"><span class="badge badge-dark">4</span></td>                      <td data-title="Amount">SAR 25.99</td>
                        <td data-title="Status">Delivered</td>
                        <td data-title="Action"> <a href="javascript:void(0)" class="mr-2 text-solitud"
                            [routerLink]="['/admin/orders/details']" routerLinkActive="router-link-active"><i
                              class="fas fa-eye font-size-18"></i></a>
                        </td>
                      </tr>
                      <tr>
                        <td data-title="No.">3</td>
                        <td data-title="Order#">#0976543</td>
                        <td data-title="Order Date">Apr 21, 2021</td>
  <td data-title="Product Quantity"><span class="badge badge-dark">4</span></td>                      <td data-title="Amount">SAR 25.99</td>
                        <td data-title="Status">Accepted</td>
                        <td data-title="Action"> <a href="javascript:void(0)" class="mr-2 text-solitud"
                            [routerLink]="['/admin/orders/details']" routerLinkActive="router-link-active"><i
                              class="fas fa-eye font-size-18"></i></a>
                        </td>
                      </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info">Showing 1 to 10 of 11 entries</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div class="dataTables_paginate paging_simple_numbers">
                      <ul class="pagination pagination-rounded">
                        <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-left"></i></a>
                        </li>
                        <li class="paginate_button page-item active"><a href="#" class="page-link">1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                        </li>
                        <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                              class="mdi mdi-chevron-right"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- container-fluid -->
  </div>
  <!-- page-content -->
  <!-- Edit  User Popup -->
  <div class="modal fade editModal" tabindex="-1" id="editModal" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mt-0">Edit User</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <!-- <div class="col-lg-12">
                <div class="form-group">
                  <label>Picture</label>
                  <div class="custom_file custom_img_input">
                    <input type="file" class="custom_file_input" formControlName="userImage">
                    <div class="file_img"
                      style="background-color: rgb(245, 245, 245);background-image: url(assets/images/users/admin.jpg);">
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Name</label>
                  <input type="name" class="form-control">
                </div>
              </div>
              <!-- <div class="col-lg-12">
                <div class="form-group">
                  <label>Email</label>
                  <input type="email" class="form-control">
                </div>
              </div> -->
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Phone Number</label>
                  <input type="tel" class="form-control">
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Address</label>
                  <input type="name" class="form-control">
                </div>
              </div>
            </div>
            <button class="btn btn-warning" type="submit">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- End Edit  User Popup -->
  <!-- Edit User Reset Password Popup -->
  <div class="modal fade forgot_password show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mt-0">Reset Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form-horizontal" action="index.html">
            <div class="mb-4"> 
            <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
              <label for="userpassword5">Password</label>
              <input [type]="forgotpassword ? 'text' : 'password' " [autocomplete]="'off'" class="form-control"
                id="userpassword5" placeholder="Enter new password"> <i (click)="showforgotPassword()"
                class=" auti-custom-input-icon auti-custom-eye"
                [ngClass]="forgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
            </div>
            <div class="invalid-feedback" style="display:none;">
              <div>Password is required</div>
            </div>
          </div>
          <div class="mb-4"> 
            <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
              <label for="userpassword6">Password</label>
              <input [type]="confirmforgotpassword ? 'text' : 'password' " [autocomplete]="'off'" class="form-control"
                id="userpassword6" placeholder="Enter confirm password"> <i (click)="showConfirmforgotPassword()"
                class=" auti-custom-input-icon auti-custom-eye"
                [ngClass]="confirmforgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
            </div>
            <div class="invalid-feedback" style="display:none;">
              <div>Password is required</div>
            </div>
          </div>
            <div class="mt-4">
              <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog --> 
  </div>
  <!-- End Edit User Reset Password Popup -->

  <div class="modal fade chat-history scroll-style " id="chat-history" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mt-0">Chat History</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="user-chats">
            <div class="chat-conversation">
              <ul class="list-unstyled pr-3">
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hey! I am available</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle mr-1"></i>
                        12:09</p>
                    </div>
                  </div>
                </li>
                <li class="right">
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">Mitsue Tollner</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hi, How are you? What about our next meeting?</p>
                      </div>
                      <p class="chat-time mb-0"><i class="bx bx-time-five align-middle mr-1"></i> 10:02</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="chat-day-title"> <span class="title">Today</span>
                  </div>
                </li>
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hello!</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:00</p>
                    </div>
                  </div>
                </li>
                <li class="right">
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">Mitsue Tollner</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hi, How are you? What about our next meeting?</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:02</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Yeah everything is fine</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:06</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">&amp; Next meeting tomorrow 10.00AM</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:06</p>
                    </div>
                  </div>
                </li>
                <li class="right">
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">Mitsue Tollner</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Wow that's great</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:07</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hey! I am available</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline align-middle mr-1"></i>
                        12:09</p>
                    </div>
                  </div>
                </li>
                <li class="right">
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">Mitsue Tollner</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hi, How are you? What about our next meeting?</p>
                      </div>
                      <p class="chat-time mb-0"><i class="bx bx-time-five align-middle mr-1"></i> 10:02</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="chat-day-title"> <span class="title">Today</span>
                  </div>
                </li>
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hello!</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:00</p>
                    </div>
                  </div>
                </li>
                <li class="right">
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">Mitsue Tollner</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Hi, How are you? What about our next meeting?</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:02</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Yeah everything is fine</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:06</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="conversation-list">
                    <div class="chat-avatar">
                      <img src="assets/images/users/avatar-2.jpg" alt="">
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">Frank Vickery</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">&amp; Next meeting tomorrow 10.00AM</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:06</p>
                    </div>
                  </div>
                </li>
                <li class="right">
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">Mitsue Tollner</div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">Wow that's great</p>
                      </div>
                      <p class="chat-time mb-0"><i class="mdi mdi-clock-outline mr-1"></i> 10:07</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  