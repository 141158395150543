import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { FAQService } from 'src/app/services/faq/faq.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {
  addFaqForm: FormGroup;
  faqFormSubmitted: boolean;
  faqDetail: any;
  faqToBeEdited: any;
  constructor(
    private commonService: CommonService,
    private faqService: FAQService,
    private modalService: ModalService,
    private events: EventsService,
  ) {
    this.events.subscribe(EventsKey.edit, (data) => {
      if (data) {
        this.faqDetail = data
        this.addFaqForm.patchValue({
          "question": {
            "en": this.faqDetail.question.en,
            "am": this.faqDetail.question.am
          },
          "answer": {
            "en": this.faqDetail.answer.en,
            "am": this.faqDetail.answer.am
          },
        })
      } else {
        this.faqFormSubmitted = false;
        this.addFaqForm.reset();
        this.faqDetail = null
      }

    })
    this.addFaqForm = new FormGroup({
      "question": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "answer": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),

    })
  }

  ngOnInit(): void {
  }


  addFaq() {
    this.faqFormSubmitted = true;
    if (this.addFaqForm.invalid) {
      return;
    }
    let payload = {}
    payload = {
      "faqId": '',
      "question": {
        "en": this.addFaqForm.value.question.en,
        "am": this.addFaqForm.value.question.am
      },
      "answer": {
        "en": this.addFaqForm.value.answer.en,
        "am": this.addFaqForm.value.answer.am
      },
    }

    this.commonService.presentSpinner();
    this.faqService.addFaq(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message);
        }
      }
    ).catch((error) => {
      console.log("error", error)
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {
      this.events.publish(EventsKey.faqSuccessfullyupdate, '');
      this.commonService.dismissSpinner();
      this.faqFormSubmitted = false;
      this.addFaqForm.reset();
      this.modalService.close('addFaq');
    })
  }

  updateFaq() {
    this.faqFormSubmitted = true;
    if (this.addFaqForm.invalid) {
      return;
    }
    let payload = {}
    payload = {
      "faqId": this.faqDetail._id,
      "question": {
        "en": this.addFaqForm.value.question.en,
        "am": this.addFaqForm.value.question.am
      },
      "answer": {
        "en": this.addFaqForm.value.answer.en,
        "am": this.addFaqForm.value.answer.am
      },
    }

    this.commonService.presentSpinner();
    this.faqService.addFaq(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message);
        }
      }
    ).catch((error) => {
      console.log("error", error)
    }).finally(() => {
      this.events.publish(EventsKey.faqSuccessfullyupdate, '');
      this.commonService.dismissSpinner();
      this.faqFormSubmitted = false;
      this.addFaqForm.reset();
      this.modalService.close('addFaq');
    })
  }
  pagechange(e) {
    console.log(e)
  }
}
