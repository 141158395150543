<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">System Settings</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-sm-6">
        <div class="card table_page">

          <div class="card-body pt-3">
            <h4 class="card-title mb-3">About Us Content (English)</h4>
            <angular-editor id="editor1" [config]="editorConfig" [(ngModel)]="aboutus">
            </angular-editor>
            <div class="two-btn mt-2">
              <div class="text-right">
                <button type="submit" class="btn btn-sm btn-warning" (click)="enableEditing('editorConfig')"
                  *ngIf="!editorConfig?.showToolbar">Enable
                  editing</button>
              </div>
              <div class="text-right" *ngIf="editorConfig?.showToolbar">
                <button type="submit" class="btn btn-sm btn-warning mr-2"
                  (click)="updateContent(aboutDetail._id,'aboutUs')">Save</button>
                <button type="submit" class="btn btn-sm btn-danger"
                  (click)="enableEditing('editorConfig')">cancel</button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6">
        <div class="card table_page">
          <div class="card-body pt-3">
            <h4 class="card-title mb-3">About Us Content (Amharic)</h4>
            <angular-editor id="editor1" [config]="editorConfigAr" [(ngModel)]="aboutusAm"></angular-editor>
            <div class="two-btn mt-2">
              <div class="text-right">
                <button type="submit" class="btn btn-sm btn-warning" (click)="enableEditing('editorConfigAr')"
                  *ngIf="!editorConfigAr?.showToolbar">Enable
                  editing</button>
              </div>
              <div class="text-right" *ngIf="editorConfigAr?.showToolbar">
                <button type="submit" class="btn btn-sm btn-warning mr-2"
                  (click)="updateContent(aboutDetail._id,'aboutUs')">Save</button>
                <button type="submit" class="btn btn-sm btn-danger"
                  (click)="enableEditing('editorConfigAr')">cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="card table_page">

          <div class="card-body pt-3">
            <h4 class="card-title mb-3">Privacy Policy Content (English)</h4>
            <angular-editor id="editor1" [config]="privacypolicyConfig" [(ngModel)]="privacypolicy"></angular-editor>
            <div class="two-btn mt-2">
              <div class="text-right">
                <button type="submit" class="btn btn-sm btn-warning" (click)="enableEditing('privacypolicyConfig')"
                  *ngIf="!privacypolicyConfig?.showToolbar">Enable
                  editing</button>
              </div>
              <div class="text-right" *ngIf="privacypolicyConfig?.showToolbar">
                <button type="submit" class="btn btn-sm btn-warning mr-2"
                  (click)="updateContent(privacypolicyDetail._id,'privacy')">Save</button>
                <button type="submit" class="btn btn-sm btn-danger"
                  (click)="enableEditing('privacypolicyConfig')">cancel</button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6">
        <div class="card table_page">
          <div class="card-body pt-3">
            <h4 class="card-title mb-3">Privacy Policy Content (Amharic)</h4>
            <angular-editor id="editor1" [config]="privacypolicyConfigAr" [(ngModel)]="privacyAm"></angular-editor>
            <div class="two-btn mt-2">
              <div class="text-right">
                <button type="submit" class="btn btn-sm btn-warning" (click)="enableEditing('privacypolicyConfigAr')"
                  *ngIf="!privacypolicyConfigAr?.showToolbar">Enable
                  editing</button>
              </div>
              <div class="text-right" *ngIf="privacypolicyConfigAr?.showToolbar">
                <button type="submit" class="btn btn-sm btn-warning mr-2"
                  (click)="updateContent(privacypolicyDetail._id,'privacy')">Save</button>
                <button type="submit" class="btn btn-sm btn-danger"
                  (click)="enableEditing('privacypolicyConfigAr')">cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card table_page">

          <div class="card-body pt-3">
            <h4 class="card-title mb-3">Terms & Condition Content (English)</h4>
            <angular-editor id="editor1" [config]="editorConfigtrmEn" [(ngModel)]="termsCondition"></angular-editor>
            <div class="two-btn mt-2">
              <div class="text-right">
                <button type="submit" class="btn btn-sm btn-warning" (click)="enableEditing('editorConfigtrmEn')"
                  *ngIf="!editorConfigtrmEn?.showToolbar">Enable
                  editing</button>
              </div>
              <div class="text-right" *ngIf="editorConfigtrmEn?.showToolbar">
                <button type="submit" class="btn btn-sm btn-warning mr-2"
                  (click)="updateContent(termDetail._id,'term')">Save</button>
                <button type="submit" class="btn btn-sm btn-danger"
                  (click)="enableEditing('editorConfigtrmEn')">cancel</button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-6">
        <div class="card table_page">
          <div class="card-body pt-3">
            <h4 class="card-title mb-3">Terms & Condition Content (Amharic)</h4>
            <angular-editor id="editor1" [config]="editorConfigtrmAr" [(ngModel)]="termsAm"></angular-editor>
            <div class="two-btn mt-2">
              <div class="text-right">
                <button type="submit" class="btn btn-sm btn-warning" (click)="enableEditing('editorConfigtrmAr')"
                  *ngIf="!editorConfigtrmAr?.showToolbar">Enable
                  editing</button>
              </div>
              <div class="text-right" *ngIf="editorConfigtrmAr?.showToolbar">
                <button type="submit" class="btn btn-sm btn-warning mr-2"
                  (click)="updateContent(termDetail._id,'term')">Save</button>
                <button type="submit" class="btn btn-sm btn-danger"
                  (click)="enableEditing('editorConfigtrmAr')">cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <h4 class="card-title mb-4">User Role</h4>
                </div>
                <div class="col-sm-12 col-md-6" style="text-align: right;">
                  <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                    (click)="openModal('userRoleModel');">Add</button>
                </div>

              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th>Role Name</th>
                    <th style="width:40px;text-align: right;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of adminRoleList | paginate: {
                    id: 'adminRoleList',
                    itemsPerPage: limit,
                    currentPage: currentPage,
                    totalItems: entries?.total
                  };
                  ; let i = index;">
                    <td data-title="No.">{{ limit * (currentPage - 1) + i + 1 }}</td>
                    <td data-title="Module Name">{{role?.roleName?.en ? role?.roleName?.en :
                      role?.roleName}}<br>{{role?.roleName?.am ? role?.roleName?.am : role?.roleName }}</td>
                    <!-- <td data-title="Module Name">{{role?.roleName | uppercase}}</td> -->


                    <td data-title="Actions" style="text-align: right;">
                      <button type="button" class="mr-2 text-primary btn_style"
                        (click)="openModal('userRoleModel',role);"><i class="mdi mdi-pencil font-size-18"></i>
                      </button>

                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !adminRoleList?.length">
                    <td colspan="4">
                      <h5 class="text-center">No record found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && adminRoleList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info"></div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="adminRoleList"
                      (pageChange)="getUserRole($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <h4 class="card-title mb-4">Location</h4>
                </div>
                <div class="col-sm-12 col-md-6" style="text-align: right;">
                  <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                    (click)="openLocationModal('location');">Add</button>
                </div>

              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th>Language</th>
                    <th style="width:40px;text-align: right;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of adminLocationList | paginate: {
                        id: 'adminLocationList',
                        itemsPerPage: 10,
                        currentPage: locationCurrentPage,
                        totalItems: locationEntries?.total
                      };
                      ; let i = index;">
                    <td data-title="No.">{{ limit * (locationCurrentPage - 1) + i + 1 }}</td>
                    <td data-title="Module Name">{{list?.locationName?.en}}<br>{{list?.locationName?.am}}</td>
                    <!-- <td data-title="Module Name">{{role?.roleName | uppercase}}</td> -->


                    <td data-title="Actions" style="text-align: right;">
                      <button type="button" class="mr-2 text-primary btn_style"
                        (click)="openLocationModal('location',list);"><i class="mdi mdi-pencil font-size-18"></i>
                      </button>

                    </td>
                  </tr>
                  <tr *ngIf="locationApiHitDone && !adminLocationList?.length">
                    <td colspan="4">
                      <h5 class="text-center">No record found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!locationApiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="locationApiHitDone && adminLocationList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info"></div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="adminLocationList"
                      (pageChange)="getlocation($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal id="userRoleModel">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{userrole ? 'Edit' : 'Add'}} Role</h5>
    <button type="button" class="close" (click)="closeModal('userRoleModel');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form">
      <form [formGroup]="roleForm">
        <div class="row">
          <div class="col-md-12" formGroupName="roleName">
            <div class="form-group">
              <label>Role Name English</label>
              <input type="name" class="form-control" formControlName="en">
              <div *ngIf="FormSubmitted && !roleForm?.value?.roleName?.en" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
            <div class="form-group">
              <label>Role Name Amharic</label>
              <input type="name" class="form-control" formControlName="am">
              <div *ngIf="FormSubmitted && !roleForm?.value?.roleName?.am" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
          </div>

        </div>
        <button type="submit" class="btn btn-warning" (click)="saveRole()">Save</button>
      </form>
    </div>
  </div>
</modal>
<modal id="location">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{location ? 'Edit' : 'Add'}} Location Name</h5>
    <button type="button" class="close" (click)="closeModal('location');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form">
      <form [formGroup]="locationForm">
        <div class="row">
          <div class="col-md-12" formGroupName="locationName">
            <div class="form-group">
              <label>Location English</label>
              <input type="name" class="form-control" formControlName="en">
              <div *ngIf="locationFormSubmitted && !locationForm?.value?.locationName?.en" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
            <div class="form-group">
              <label>Location Amharic</label>
              <input type="name" class="form-control" formControlName="am">
              <div *ngIf="locationFormSubmitted && !locationForm?.value?.locationName?.am" class="error-msg">
                <span>Required!</span>
              </div>
            </div>
          </div>

        </div>
        <button type="submit" class="btn btn-warning" (click)="saveLocation()">Save</button>
      </form>
    </div>
  </div>
</modal>