import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { UserservicesService } from 'src/app/services/user/userservices.service';
declare var $: any;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  apiHitDone: boolean = false;
  currentPage: number;
  search: string;
  role: string = '';
  roleId: string = '';
  limit: number = 10;
  userList: any[];
  entries: Entries;
  userToBeEdited: any;
  userRole: any = [];
  constructor(
    private modalService: ModalService,
    private commonService: CommonService,
    private userService: UserservicesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
  ) {
    this.getUserRole();
    this.events.subscribe(EventsKey.faqSuccessfullyupdate, () => {
      this.getUserList(this.currentPage);
    })
    this.activatedRoute.queryParams.subscribe(
      ({ search, page }) => {
        this.currentPage = page || 1;
        this.getUserList(this.currentPage);
      }
    ).unsubscribe();
  }

  ngOnInit(): void {
  }

  openModal(id: string, userDetail?: any) {
    if (userDetail) {
      this.userToBeEdited = userDetail
    } else {
      this.userToBeEdited = null
    }
    this.events.publish(EventsKey.edit, this.userToBeEdited);
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  getUserList(currentPage: number) {
    this.apiHitDone = false;
    this.userService.getUserList({ "page": currentPage.toString(), "limit": this.limit.toString(), "search": this.search, "role": this.role, "roleId": this.roleId }).then(
      ({ userList, totalCount }) => {
        if (userList) {
          this.userList = userList;
          this.entries = this.commonService.calculateEntries(this.userList, currentPage, this.limit, totalCount);

        } else {
          this.userList = [];
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.userList = [];
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        this.apiHitDone = true;
        this.currentPage = currentPage;
        if (this.currentPage > 1 && this.userList.length == 0) {
          this.currentPage = this.currentPage - 1;
          this.getUserList(this.currentPage);
        } else {
          let params: any = {
            "page": this.currentPage
          };
          if (this.search) {
            params.search = this.search;
          }
          if (this.role) {
            params.role = this.role;
          }
          if (this.roleId) {
            params.roleId = this.roleId;
          }
          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
          this.apiHitDone = true;

        }
      }
    );
  }
  changeUserStatus(user: any) {
    this.commonService.presentSpinner();
    let payload = {};
    // let status = user.status === 'Active' ? 'Inactive' : 'Active';
    var status = '';
    if (user.status == 'Active') {
      status = 'Inactive';
    } else {
      status = 'Active';
    }
    payload = {
      status: status
    }

    this.userService.changeUserStatus(user._id, payload).then(
      (res) => {

      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.getUserList(this.currentPage);
      }
    )
  }
  userToBeDeleted: string;
  deleteModal(userId: string) {
    this.userToBeDeleted = userId;
    $('#delete-offer').modal('show');
  }

  deleteUser() {
    this.commonService.presentSpinner();
    let payload = {
      status: 'Delete'
    }
    this.userService.changeUserStatus(this.userToBeDeleted, payload).then(
      (res) => {

      }
    ).finally(
      () => {
        $('#delete-offer').modal('hide');
        this.getUserList(this.currentPage);
        this.commonService.dismissSpinner();
      }
    )
  }
  getUserRole() {
    this.userService.getUserRole().then(
      ({ adminRoleList }) => {
        if (adminRoleList) {
          this.userRole = adminRoleList;
          console.log("userRole", this.userRole)
        }
      }
    ).catch((error) => {
      this.userRole = [];
    })
  }
}
