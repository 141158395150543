import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-sms-add-assessment',
  templateUrl: './sms-add-assessment.component.html',
  styleUrls: ['./sms-add-assessment.component.scss']
})
export class SmsAddAssessmentComponent implements OnInit {

  answertype:any
  constructor() { } 
  questionTypes = [
    // { id: 1, value: 'Short', name: 'Short answer' },
    { id: 1, value: 'Checkboxes', name: 'Multiple choice' },
    { id: 2, value: 'Multiple', name: 'Single choice' },
    { id: 3, value: 'yes-no', name: 'Yes/No' },
    // { id: 4, value: 'Integer', name: 'Integer' },
    // { id: 5, value: 'GPS', name: 'GPS' },
    // { id: 6, value: 'Text', name: 'Text' },
    // { id: 6, value: 'Dropdown', name: 'School List' },
    // { id: 6, value: 'Page_Break', name: 'Page Break' },
    // { id: 6, value: 'Media', name: 'Media' },

  ];
  

  ngOnInit(): void {
    this.answertype = this.questionTypes[0].value

    
  }
  setHeight(element: HTMLTextAreaElement) {
    element.style.height = (element.scrollHeight) + 'px';
  }

  resetHeight(element: HTMLTextAreaElement) {
    element.style.height = '48px';
    element.style['overflow-y'] = 'hidden';
    return true;
  }

}
