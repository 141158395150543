<div class="form program-editor">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Training Name (English)</label>
          <input type="name" class="form-control">
        </div>
        <div class="form-group">
          <label>Description (English)</label>
          <angular-editor id="editor1"></angular-editor>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Training Name (Amharic)</label>
          <input type="name" class="form-control">
        </div>
        <div class="form-group">
          <label>Description (Amharic)</label>
          <angular-editor id="editor1"></angular-editor>
        </div>
      </div>
      
    </div>
    <button type="submit" class="btn btn-warning">Save</button>
  </div>
  