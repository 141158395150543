export const Endpoints = {
  login: "/auth/admin-login",
  dashboard: "/dashboard",
  logout: "/auth/logout",
  changePassword: "/change-password",
  addupdateuserrole: "/role",
  addupdatefaq: "/faqs/add-update-faqs",
  getfaqs: "/faqs",
  reorderfaqs: "/faqs/reorder-faqs",
  users: "/users",
  usersstatus: "/users/status",
  addupdate: "/users/add-update",
  role: "/role",
  imageupload: "/users/profile-img",
  adminListOrAddAdmin: "/admins",
  adminsupdatestatus: "/admins/update-status/",
  adminschangepassword: "/admins/change-password",
  trainingaddupdate: "/training/add-update",
  tags: "/tags",
  getTraining: "/Training",
  deletetraining: "/training",
  modulesaddupdate: "/modules/add-update",
  getmodules: "/modules",
  chapters: "/chapters",
  topics: "/topics",
  narrative: "/topics/narrative",
  systemsetting: "/content",
  userrole: "/role",
  location: "/home/location",
  updatesystemsetting: (content_id: string,) => { return `/content?contentId=${content_id}` },
  assessments: '/assessments/add-update',
  question: '/question',
  questionaddupdate: '/question/add-update',
  reorder: '/question/reorder-question',
  askmessagelist: "/ask/message-list",
  userchatmessage: "/ask/message",
  replymessage: "/ask/reply-message",
  updatestatus: "/ask/update-status",
  changepassword: "/admins-change-password",
  forgotpassword: "/auth/forgot-password",
  resetpassword: "/auth/reset-password",
  reorderTraining:"/training/reorder",
  reorderModule:"/modules/reorder",
  reorderChapter:"/chapters/reorder",
  reorderTopic:"/topics/reorder",
  "sms":{
    getSmsTraining: "/sms/Training",
    smsTrainingaddupdate: "/sms/training/add-update",
    getSmsmodules: "/sms/modules",
    smsModulesaddupdate: "/sms/modules/add-update",
    smsChapters: "/sms/chapters",
    smstopics: "/sms/topics",
    question: '/sms/question',
    assessments: '/sms/assessments/add-update',
    questionaddupdate: '/sms/question/add-update',
    reorderTraining:"/sms/training/reorder",
    reorderModule:"/sms/modules/reorder",
    reorderChapter:"/sms/chapters/reorder",
    reorderTopic:"/sms/topics/reorder",
    reorderQuestion: '/sms/question/reorder-question',
    narrative: "/sms/topics/narrative",
  }
}
