import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ImageSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { ImageCompressService } from 'src/app/services/image-compress/image-compress.service';
import { Topic } from 'src/app/services/training/addmodule.model';
import { TrainingService } from 'src/app/services/training/training.service';
import { UserservicesService } from 'src/app/services/user/userservices.service';
declare var $: any;
@Component({
  selector: 'app-topics-add',
  templateUrl: './topics-add.component.html',
  styleUrls: ['./topics-add.component.scss']
})
export class TopicsAddComponent implements OnInit {
  @Input() ids: any
  addForm: FormGroup;
  formSubmitted: boolean;
  topicDetail: any;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  fileToUpload: File = null;
  routeUrl: any;
  remainingEn: number = 0
  messagesEn: number = 0;
  remainingAm: number = 0
  messagesAm: number = 0;
  image_upload: string = '';
  showFullnaritiveDescription: boolean = false;
  public insertImageSettings: ImageSettingsModel = { allowedTypes: ['.jpeg', '.jpg', '.png'], display: 'inline', width: 'auto', height: 'auto', saveFormat: 'Base64', saveUrl: null, path: null, }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,

    height: '15rem',
    minHeight: '5rem',
    // placeholder: 'Enter text here...',
    // translate: 'yes',
    // defaultParagraphSeparator: 'p',
    // showToolbar: true,
    // defaultFontName: 'Roboto',
    // defaultFontSize: '2',

    toolbarHiddenButtons: [
      [
        'insertVideo',
        'insertImage',
        'fontName',
        'fontSize',
        'heading',
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName',
        'link',
        'unlink',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };
  constructor(
    private events: EventsService,
    private trainingService: TrainingService,
    private commonService: CommonService,
    private modalService: ModalService,
    private userService: UserservicesService,
    @Inject(DOCUMENT) private document: Document,
    private compressService: ImageCompressService
  ) {
    this.routeUrl = this.commonService.getRouterUrl();
    this.events.subscribe(EventsKey.edit, (data) => {
      if (data) {
        this.image_upload = '';
        this.topicDetail = data;
        // this.image_upload = this.topicDetail.mediaUrl;
        if (this.topicDetail.fullNarritiveDescription) {
          this.formSubmitted = false;
          this.addForm.patchValue({
            fullNarritiveDescription: {
              en: this.topicDetail.fullNarritiveDescription.en,
              am: this.topicDetail.fullNarritiveDescription.am
            },
            fullNarrative: this.topicDetail.fullNarrative,
          })
          // if (this.topicDetail.fullNarrative) {
          //   this.showFullnaritiveDescription = true
          // }
        }
        this.addForm.patchValue({
          topicsName: {
            en: this.topicDetail.topicsName.en,
            am: this.topicDetail.topicsName.am
          },
          description: {
            en: this.topicDetail.description.en,
            am: this.topicDetail.description.am
          },

          readTime: this.topicDetail.readTime,
          narrative: this.topicDetail.narrative,
          fullnarrative: this.topicDetail.fullnarrative
        })
      } else {
        this.topicDetail = null;
        this.image_upload = '';

        this.addForm.reset();
        this.formSubmitted = false;
        this.addForm.patchValue({
          narrative: '',
          fullNarrative: '',
        })
      }
    });
    this.addForm = new FormGroup({
      // "mediaUrl": new FormControl(''),
      "topicsName": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "description": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
      "fullNarritiveDescription": new FormGroup({
        "en": new FormControl(''),
        "am": new FormControl('')
      }),
      "readTime": new FormControl('', Validators.compose([Validators.required])),
      "narrative": new FormControl('', Validators.compose([Validators.required])),
      "fullNarrative": new FormControl('', Validators.compose([Validators.required]))
    })
  }

  ngOnInit(): void {
  }


  smsCount(action) {
    let count;
    if (action == 'en') {
      count = this.commonService.messageCount(this.addForm.value.description.en.length)
      this.messagesEn = count.messages;
      this.remainingEn = count.remaining;
    } else {
      count = this.commonService.messageCount(this.addForm.value.description.am.length)
      this.messagesAm = count.messages;
      this.remainingAm = count.remaining;
    }
  }

  selectImage(event) {
    this.imageErrMsg = '';
    var Filetype = event.target.files;
    var file: File = event.target.files[0];
    this.currentFile = file;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = ["jpeg", "png", "jpg"];
    this.imgtype = imagetype[1];
    if ($.inArray(imagetype[1], ValidImageExt) < 0) {
      this.imageErrMsg = "Only JPG, JPEG & PNG image formats are allowed";
      this.commonService.showErrorToastMsg('', this.imageErrMsg);
      this.addForm.controls['mediaUrl'].setValue('');
      return false;
    }
    this.fileToUpload = this.currentFile;
    var frmData = new FormData();
    frmData.append("profileImg", this.fileToUpload);
    this.upLoadImage(frmData)
  }
  numberOnly(event, controlName?: string): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (controlName) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }
  }
  upLoadImage(frmData) {
    this.commonService.presentSpinner();
    this.userService.imageUpload(frmData).then(
      (res: any) => {
        if (res.code == 200) {
          this.image_upload = res.image_upload;
          this.commonService.dismissSpinner();
        }
      }
    ).finally(() => {

      this.commonService.dismissSpinner();

    })
  }

  fullNarrative(e) {
    if (e.target.value == "true") {
      this.showFullnaritiveDescription = true;
    } else {
      this.showFullnaritiveDescription = false;
      this.addForm['controls'].fullNarritiveDescription['controls'].en.setValue('');
      this.addForm['controls'].fullNarritiveDescription['controls'].am.setValue('');
    }
  }
  saveTopic() {
    this.formSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }

    let payload = new Topic(this.addForm.value);
    payload.trainingProgramsId = this.ids.trainingProgramsId;
    payload.mediaUrl = this.image_upload;
    payload.modulesId = this.ids.modulesId;
    payload.chaptersId = this.ids.chaptersId;
    // payload.fullNarritiveDescription.en = ''
    // payload.fullNarritiveDescription.am = ''
    if (this.topicDetail) {
      payload.topicsId = this.topicDetail._id;
    } else {
      payload.topicsId = '';
    }
    this.commonService.presentSpinner();
    this.trainingService.addUpdateTopic(payload).then(
      (res: any) => {

      }
    ).catch((error) => {
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {
      this.events.publish(EventsKey.successfullyaddupdate, '');
      this.commonService.dismissSpinner();
      this.modalService.close('addTopics');
      this.topicDetail = null;
      this.image_upload = '';
    })
  }

  onImageSelect(files: FileList, lang: 'en' | 'am') {

    for (let index = 0; index < files.length; index++) {
      const file = files.item(index);
      if(!file || !file.type.startsWith('image/')) {
        return;
      }
      
      let reader = new FileReader();

      reader.onload = async (event) => {
        let image: any = event.target.result;
        let compressedDataUrl: any = await this.compressService.compressFile(image, file, "dataUrl");
        let compressedFile: any = await this.compressService.compressFile(image, file, "file");

        let imageElement = this.document.createElement('img');
        imageElement.src = compressedDataUrl;

        console.log(file.size/1024)
        console.log(compressedFile.compressedImage.size/1024);
        console.log(compressedDataUrl);
        

        switch (lang) {
          case "en":
            this.addForm.patchValue({
              description: {
                "am": this.addForm.value.description.am,
                "en": (this.addForm.value.description.en || '') + imageElement.outerHTML
              }
            })
            break;

          case "am":
            this.addForm.patchValue({
              description: {
                "am": (this.addForm.value.description.am || '') + imageElement.outerHTML,
                "en": this.addForm.value.description.en
              }
            })
            break;
        }
      }
      reader.readAsDataURL(file);

    }

  }
}
