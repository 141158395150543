<div class="type_qauestions">
    <div class="question_row">
      <!-- <div class="item-dlg-dragHandle"></div> -->
      <div class="itemShowOnExpand">
        <div class="ItemTitleRow">
          <div class="ItemTitleInput">
          <div class="textarea-two">
            <textarea placeholder="Question (English)" id="text-area" #add_question_input (input)="setHeight(add_question_input)"
            style="height:50px;overflow-y:hidden;" class="form-control ItemTitle"></textarea>
            <textarea placeholder="Question (Amharic)" id="text-area" #add_question_input (input)="setHeight(add_question_input)"
            style="height:50px;overflow-y:hidden;" class="form-control ItemTitle"></textarea>
          </div>
  
          </div>
          <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
          <!-- Example single danger button -->
          <div class="ng_select_option">
            <ng-select [(ngModel)]="answertype" [items]="questionTypes" bindLabel="name" bindValue="value"
              placeholder="Select city">
              <ng-template ng-label-tmp let-item="item">
                <span class="ng-value-label" data-title="{{item.name}}">{{item.name}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <span class="ng-option-label" data-title="{{item.name}}">{{item.name}}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="ViewMediaImagePos" style="display: none;">
        <span class="remove-exportLabel"></span>
        <img class="ViewMediaImage" src="/assets/images/users/user-1.jpg" style="width:100%;">
      </div>
      <!-- <div class="short-answer box-input" *ngIf="answertype == 'Short'">
        <ul>
          <li>
            <div class="input-row"><input type="text" class="input" value="Short answer text" disabled>
            </div>
          </li>
  
        </ul>
      </div> -->
      
      <div class="checkboxes-choice box-input" *ngIf="answertype == 'Checkboxes'">
        <ul>
          <li>
            <label class="checkbox-box checkbox-true"><input type="checkbox" id="infrasonic"
                name="infrasonic"><span>true</span></label>
            <div class="input-row">
  
              <div class="d-flex">
              <div class="input">
      
  <input type="text" value="Option 1">
  <input type="text" value="Option 2">
  </div>
                <div class="icon-box">
                  <div class="input-action">
                    <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                    <span class="iconbuttonContentSpan"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path fill="#5f6368"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg></span>
                  </div>
                </div>
              </div>
  
            </div>
            <div class="ViewMediaImagePos d-none">
              <span class="remove-exportLabel"></span>
              <img class="ViewMediaImage" src="/assets/images/users/user-1.jpg" style="width:100%;">
            </div>
          </li>
          <li>
            <label class="checkbox-box checkbox-true"><input type="checkbox" id="infrasonic"
                name="infrasonic"><span>true</span></label>
            <div class="input-row">
              <div class="d-flex">
              <div class="input">
      
  <input type="text" value="Option 1">
  <input type="text" value="Option 2">
  </div>
                <div class="icon-box">
                  <div class="input-action">
                    <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                    <span class="iconbuttonContentSpan"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path fill="#5f6368"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg></span>
                  </div>
                </div>
              </div>
  
            </div>
          </li>
          <li class="add-option"><input type="text" class="input" placeholder="Add option"></li>
        </ul>
      </div>
      <div class="multiple-choice box-input" *ngIf="answertype == 'Multiple'">
        <ul>
          <li>
            <label class="radio-box"><input type="radio" name="radio" id="radio"><span>radio</span></label>
            <div class="input-row">
  
              <div class="d-flex">
              <div class="input">
      
  <input type="text" value="Option 1">
  <input type="text" value="Option 2">
  </div>
                <div class="icon-box">
                  <div class="input-action">
                    <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                    <span class="iconbuttonContentSpan"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path fill="#5f6368"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg></span>
                  </div>
                </div>
              </div>
  
            </div>
            <div class="ViewMediaImagePos d-none">
              <span class="remove-exportLabel"></span>
              <img class="ViewMediaImage" src="/assets/images/users/user-1.jpg" style="width:100%;">
            </div>
          </li>
          <li>
            <label class="radio-box"><input type="radio" name="radio" id="radio"><span>radio</span></label>
            <div class="input-row">
              <div class="d-flex">
              <div class="input">
      
  <input type="text" value="Option 1">
  <input type="text" value="Option 2">
  </div>
                <div class="icon-box">
                  <div class="input-action">
                    <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                    <span class="iconbuttonContentSpan"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path fill="#5f6368"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg></span>
                  </div>
                </div>
              </div>
  
            </div>
          </li>
          <li class="add-option"><input type="text" class="input" placeholder="Add option"></li>
        </ul>
      </div>
      <div class="multiple-choice box-input" *ngIf="answertype == 'yes-no'">
        <ul>
          <li>
            <label class="radio-box"><input type="radio" name="radio" id="radio"><span>radio</span></label>
            <div class="input-row">
  
              <div class="d-flex">
                <input type="text" class="input" value="Yes" disabled>
                <div class="icon-box d-none">
                  <div class="input-action">
                    <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                    <span class="iconbuttonContentSpan"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path fill="#5f6368"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg></span>
                  </div>
                </div>
              </div>
  
            </div>
            <div class="ViewMediaImagePos d-none">
              <span class="remove-exportLabel"></span>
              <img class="ViewMediaImage" src="/assets/images/users/user-1.jpg" style="width:100%;">
            </div>
          </li>
          <li>
            <label class="radio-box"><input type="radio" name="radio" id="radio"><span>radio</span></label>
            <div class="input-row">
              <div class="d-flex">
                <input type="text" class="input" value="No" disabled>
                <div class="icon-box d-none">
                  <div class="input-action">
                    <!-- <div class="upload_file"><input type="file" id="customFile" class="custom_file_input"></div> -->
                    <span class="iconbuttonContentSpan"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24">
                        <path fill="#5f6368"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg></span>
                  </div>
                </div>
              </div>
  
            </div>
          </li>
         </ul>
      </div>
      <button type="submit" class="btn btn-warning">Save</button>
    </div>
  </div>
  