import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sms-add-chapter',
  templateUrl: './sms-add-chapter.component.html',
  styleUrls: ['./sms-add-chapter.component.scss']
})
export class SmsAddChapterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
