import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-reorder-training-programs',
  templateUrl: './reorder-training-programs.component.html',
  styleUrls: ['./reorder-training-programs.component.scss']
})
export class ReorderTrainingProgramsComponent implements OnInit {
  @Input() trainingList;
  @Output() trainingReorderSuccess: EventEmitter<any> = new EventEmitter();
  list: Array<any> = [];
  apiHitDone: boolean;
  constructor(
    private commonService: CommonService,
    private trainingService: TrainingService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.list = this.trainingList;
    this.apiHitDone = false;
    if (this.list.length) {
      this.apiHitDone = true;
    }
  }

  showText(locale: "en" | "am", list: any) {
    switch (locale) {
      case "en":
        list.readMore_en = !list.readMore_en;
        break;

      case "am":
        list.readMore_am = !list.readMore_am;
        break;
    }
  }

  newArr: any = [];
  async drop(event: CdkDragDrop<string[]>) {
    this.newArr = []
    moveItemInArray(this.trainingList, event.previousIndex, event.currentIndex);
    let index = 0;
    for await (let e of this.trainingList) {
      index++;
      this.newArr.push({ 'trainingId': e._id, 'position': index })
    }
  }

  reOrderTraining() {
    let postdata = {
      payload: this.newArr
    }
    this.commonService.presentSpinner();
    this.trainingService.reorderTraining(postdata).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message.en);
        }
      }
    ).catch((error) => {
      this.commonService.showErrorToastMsg(error.error.message);
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.newArr = [];
      this.modalService.close('traningModal');
      this.trainingReorderSuccess.emit('reorder');
    })

  }
}
