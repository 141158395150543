import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-sms-reorder-assessment',
  templateUrl: './sms-reorder-assessment.component.html',
  styleUrls: ['./sms-reorder-assessment.component.scss']
})
export class SmsReorderAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  elements = [
    {
      question: {
        en: " What does LOREM mean?",
        am: " LOREM ማለት ምን ማለት ነው?"
      },
    },
    {
      question: {
        en: "Where can I subscribe to your newsletter?",
        am: "ለጋዜጣዎ የት ነው መመዝገብ የምችለው?"
      },
    },
    {
      question: {
        en: " Can I order a free copy of a magazine to sample?",
        am: "ለናሙና የሚሆን የመጽሔት ቅጂ ማዘዝ እችላለሁ?"
      },
    },
    {
      question: {
        en: " What does LOREM mean?",
        am: " LOREM ማለት ምን ማለት ነው?"
      },
    },
    {
      question: {
        en: "Where can I subscribe to your newsletter?",
        am: "ለጋዜጣዎ የት ነው መመዝገብ የምችለው?"
      },
    },
    {
      question: {
        en: " Can I order a free copy of a magazine to sample?",
        am: "ለናሙና የሚሆን የመጽሔት ቅጂ ማዘዝ እችላለሁ?"
      },
    }

  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.elements, event.previousIndex, event.currentIndex);
  }
}

