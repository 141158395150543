<div class="form">
  <form [formGroup]="addFaqForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" formGroupName="question">
          <label>Question (English)</label>
          <input type="name" class="form-control" formControlName="en">
          <div *ngIf="faqFormSubmitted && !addFaqForm?.value?.question?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="answer">
          <label>Answer (English)</label>
          <!-- <angular-editor id="editor1" formControlName="en"></angular-editor> -->
          <textarea name="" id="en" cols="30" rows="10" formControlName="en" class="form-control"></textarea>
          <div *ngIf="faqFormSubmitted && !addFaqForm?.value?.answer?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" formGroupName="question">
          <label>Question (Amharic)</label>
          <input type="name" class="form-control" formControlName="am">
          <div *ngIf="faqFormSubmitted && !addFaqForm?.value?.question?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
        <div class="form-group" formGroupName="answer">
          <label>Answer (Amharic)</label>
          <!-- <angular-editor id="editor1" formControlName="am"></angular-editor> -->
          <textarea name="" id="am" cols="30" rows="10" formControlName="am" class="form-control"></textarea>
          <div *ngIf="faqFormSubmitted && !addFaqForm?.value?.answer?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-warning" type="submit" (click)="faqDetail ? updateFaq() : addFaq()">Save</button>
  </form>
</div>