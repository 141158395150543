<div #scrollEl cdk-scrollable style="height:400px; overflow-y:scroll" class="table-scroll">
  <table class="table mb-0">
    <thead class="thead-light">
      <tr>
        <th style="width:30px;">No.</th>
        <th>Question</th>
      </tr>
    </thead>
    <tbody cdkDropList cdkDropListOrientation="vertical" dropListScroller (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let tab of faqList ; let i = index" cdkDrag>
        <td data-title="No.">{{i + 1}}</td>
        <td data-title="Question">
          <span [innerHtml]="tab.question.en"></span><br>
          <span [innerHtml]="tab.question.am"></span>
        </td>
      </tr>
      <tr class="card mb-1 shadow-none" *ngIf="apiHitDone && !faqList?.length" style="width: 350px;">
        <!-- <td> -->
        <h5 class="text-center">No record found!</h5>
        <!-- </td> -->
      </tr>
    </tbody>
  </table>
</div>
<button class="btn btn-warning" type="submit" (click)="SaveChangeOrder()" *ngIf="faqList?.length">Save</button>
<div class="api-inline-loader" *ngIf="!apiHitDone">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>