<!-- Begin page -->
<div id="layout-wrapper" [ngClass]="{'sidebar-enable vertical-collpsed': sidePanelEnabled}">
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a class="logo logo-dark"> <span class="logo-sm">
              <!-- <img src="assets/images/logo-sm-dark.png" alt="" height="22"> -->
            </span>
            <span class="logo-lg">
              <!-- <img src="assets/images/logo-dark.png" alt="" height="20"> -->
            </span>
          </a>
          <a class="logo logo-light"> <span class="logo-sm">
              <img src="assets/images/logo-sm-light.png" alt="" height="35">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="45">
            </span>
          </a>
        </div>
        <button type="button" (click)="enableSidePanel()" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn"> <i class="ri-menu-2-line align-middle"></i>
        </button>
      </div>
      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block ml-1" style="display: none !important;">
          <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen"> <i
              class="ri-fullscreen-line"></i>
          </button>
        </div>
        <div class="dropdown d-inline-block user-dropdown">
          <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="rounded-circle header-profile-user" src="assets/images/users/admin.jpg" alt="Header Avatar">
            <span class="d-none d-xl-inline-block ml-1">{{userInfo['admin_data']['userInfo']?.name |
              uppercase}}<br>
              {{userInfo['admin_data']['userInfo']?.email}}
            </span>

            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <!-- item-->
            <a class="dropdown-item" (click)="navigateTo({route: '/admin/administrators'})"> <i
                class="ri-user-add-line align-middle mr-1"></i>Administrators</a>
            <!-- item--> <a class="dropdown-item" (click)="openModal('changePassword')"><i
                class="mdi mdi-lock-reset align-middle mr-1"></i> Change
              Password</a>
            <!-- <a class="dropdown-item" (click)="navigateTo({route: '/forgot-password'})"><i
                            class="mdi mdi-lock-reset align-middle mr-1"></i> Forgot Password</a> -->
            <div class="dropdown-divider"></div>
            <!-- <a class="dropdown-item text-danger"
              (click)="navigateTo({route: '/login'})" (click)="logOut()"><i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
              Logout</a> -->
            <a class="dropdown-item text-danger" (click)="logOut()"><i
                class="ri-shut-down-line align-middle mr-1 text-danger"></i>
              Logout</a>
          </div>
        </div>
        <div class="dropdown d-inline-block" style="display: none !important;">
          <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect"> <i
              class="ri-settings-2-line"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
  <modal id="changePassword">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Change Password</h5>
      <button type="button" class="close" (click)="closeModal('changePassword');"> <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <app-change-password></app-change-password>
    </div>
  </modal>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <div data-simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li class=" menu-title">Menu</li>
          <!-- <li *ngFor="let menu of menus;" [routerLinkActive]="['active']">
						<a [routerLink]="menu?.route ? [menu?.route] : null" [class]="menu?.submenu?.length ? 'waves-effect has-arrow': 'waves-effect'"> <i [class]="menu?.icon"></i>
							<span>{{menu?.name}}</span>
						</a>
						<ul *ngIf="menu?.submenu?.length" class="sub-menu" >
							<li *ngFor="let submenu of menu?.submenu;" [routerLinkActive]="['active']"> <a [routerLink]="[submenu?.route]">{{submenu?.subname}}</a>
							</li>
						</ul>
					</li> -->
          <!-- <li>
                          <a (click)="navigateTo('/admin/dashboard')" class=" waves-effect">
                            <i class="ri-settings-2-line"></i>
                            <span>Dashboard</span>
                          </a>
                        </li> -->
          <li [routerLinkActive]="['active']" [routerLink]="['/admin/dashboard']">
            <a>
              <i class="ri-dashboard-line"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <li [routerLinkActive]="['active']" [routerLink]="['/admin/users']">
            <a>
              <i class="mdi mdi-account-group-outline"></i>
              <span>Users</span>
            </a>
          </li>

          <li [routerLinkActive]="['active']" [routerLink]="['/admin/program']">
            <a>
              <i class="ri-file-list-line"></i>
              <span>Training Programs</span>
            </a>
          </li>
          <!-- <li [routerLinkActive]="['active']" [routerLink]="['/admin/sms/sms']">
            <a>
              <i class="ri-file-list-line"></i>
              <span>SMS  </span>
            </a>
          </li> -->
          <li [routerLinkActive]="['active']" [routerLink]="['/admin/expert']">
            <a>
              <i class="ri-wechat-line"></i>
              <span>Expert Q&A
                <span class="show-mess" *ngIf="expertQACount.length != 0">{{expertQACount.length }}</span>
              </span>
            </a>
          </li>
          <!-- <li [routerLinkActive]="['active']">
             <a (click)="toggleSubMenu('Trainings')"
               [attr.aria-expanded]="!sidePanelEnabled && !representativesCollapsed"
               [ngClass]="{'has-arrow': !sidePanelEnabled}" data-toggle="collapse" data-target="#trainings">
               <i class="ri-database-2-line"></i>
               <span>Trainings</span>
             </a>
            #trainings="ngbCollapse" [(ngbCollapse)]="representativesCollapsed" -->
          <!-- <ul id="trainings" class="collapse sub-menu">
               <li [routerLinkActive]="['active']" routerLinkActive="active" [routerLink]="['/admin/program']">
                 <a>Training Programs & Modules</a>
               </li>
             </ul>
           </li> -->
          <li [routerLinkActive]="['active']" [routerLink]="['/admin/faq']">
            <a>
              <i class="ri-questionnaire-line"></i>
              <span>FAQS</span>
            </a>
          </li>
          <li [routerLinkActive]="['active']" [routerLink]="['/admin/system-settings']">
            <a>
              <i class="ri-settings-4-line"></i>
              <span>System Settings</span>
            </a>
          </li>
          <!-- <li [routerLinkActive]="['active']" [routerLink]="['/admin/notifications']">
            <a>
              <i class="ri-notification-3-line"></i>
              <span>Notifications</span>
            </a>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
  <!-- Left Sidebar End -->
  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content">
    <router-outlet></router-outlet>
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <script>
              document.write(new Date().getFullYear())

            </script>© 2021 Splash Remote Learning.
          </div>
          <div class="col-sm-6">
            <div class="text-sm-right d-none d-sm-block copyright">Crafted with <i
                class="mdi mdi-heart text-danger"></i> by <a href="https://greychaindesign.com/">Grey Chain</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
  <!-- end main content-->
</div>
<!-- END layout-wrapper -->