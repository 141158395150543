<div class="page-content system-settings">
  <div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Notifications</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <!-- Notification Send -->
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body notification_box">
            <div class="row">
              <!-- <div class="col-md-6 col-md-6 col-sm-6">
                    <h4 class="card-title mb-2">Notification</h4>
                  </div>
                  <div class="col-md-6 col-md-6 col-sm-6">
                  </div> -->
              <div class="col-sm-12">
                <div class="notification_send">
                  <div class="form-group m-0">
                    <label style="font-size: 16px;color: #000;">Send to</label>
                    <div class="checkbox_box">
                      <label class="checkbox-box checkbox-true mr-3"><input type="checkbox" id="infrasonic"
                          name="infrasonic"><span>SMS</span></label>
                      <label class="checkbox-box checkbox-true"><input type="checkbox" id="infrasonic"
                          name="infrasonic"><span>Notification</span></label>
                    </div>
                    <div class="form-group">
                      <div class="radio_label">
                        <label class="radio-box">
                          <input type="radio" name="radio1" [(ngModel)]="preferenceType" value="allUsers"><span>All
                            Select</span></label>

                        <label class="radio-box">
                          <input type="radio" name="radio2" [(ngModel)]="preferenceType"
                            value="selectedUsers"><span>Selected Users</span></label>
                      </div>
                    </div>
                    <div class="multiple-select" *ngIf="preferenceType === 'selectedUsers'">
                      <div class="form-group">
                        <label>Select Users</label>
                        <ng-select [multiple]="true" [(ngModel)]="selectedUser">
                          <ng-option> Duncan Delarosa</ng-option>
                          <ng-option>Jared Soucie</ng-option>
                          <ng-option>Bob Cumpston</ng-option>
                          <ng-option>Joaquin Yelton</ng-option>
                          <ng-option>Darrin Gerhart</ng-option>
                          <ng-option>Gaston Wilfong</ng-option>
                          <ng-option>Javier Krick</ng-option>
                          <ng-option>Ezra Keyes</ng-option>
                          <ng-option>Jame Zarate</ng-option>
                          <ng-option>Glenn Kervin</ng-option>
                        </ng-select>
                      </div>
                      <div class="form-group">
                        <label>Select Order Number</label>
                        <ng-select [multiple]="true" [(ngModel)]="selectedOD">
                          <ng-option>OD09761001</ng-option>
                          <ng-option>OD09761002</ng-option>
                          <ng-option>OD09761003</ng-option>
                          <ng-option>OD09761004</ng-option>
                          <ng-option>OD09761005</ng-option>
                          <ng-option>OD09761006</ng-option>
                          <ng-option>OD09761007</ng-option>
                        </ng-select>
                      </div>
                      <div class="form-group">
                        <label>Select Product in Cart</label>
                        <ng-select [multiple]="false" [(ngModel)]="selectedProductCard">
                          <ng-option>Yes</ng-option>
                          <ng-option>No</ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Title</label>
                    <input type="text" placeholder="Enter Title" maxlength="32" class="form-control">
                  </div>
                  <div class="form-group">
                    <label>Message</label>
                    <textarea rows="5" placeholder="Enter Message" maxlength="500" class="form-control"></textarea>
                    <p style="float: right;">100</p>
                  </div>

                  <button type="submit" class="btn btn-warning">
                    Send
                  </button>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <h4 class="card-title mb-4">Notification List</h4>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th style="width:150px;">Title</th>
                    <th>Message</th>
                    <th style="width:100px;">Users Name</th>
                    <th style="width:100px;">Order Number</th>
                    <th style="width:110px;">Product in Card</th>
                    <th style="width:100px;">Status</th>
                    <th style="width:100px;">Date Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-title="No.">1</td>
                    <td data-title="Title">Lorem ipsum</td>
                    <td data-title="Message">In publishing and graphic design, Lorem ipsum is a placeholder
                      text commonly used to demonstrate the visual form of a document or a typeface
                      without relying on meaningful content. Lorem ipsum may be used as a placeholder
                      before final copy is available.</td>
                    <td data-title="Users Name">Duncan Delarosa</td>
                    <td data-title="Order Number">OD09761001</td>
                    <td data-title="Product in Card">Yes</td>
                    <td data-title="Status">
                      <span class="badge badge-success">Sent</span>

                    </td>
                    <td data-title="Date Time">May 30, 2021 17:00</td>


                  </tr>
                  <tr>
                    <td data-title="No.">2</td>
                    <td data-title="Title">Lorem ipsum</td>
                    <td data-title="Message">In publishing and graphic design, Lorem ipsum is a placeholder
                      text commonly used to demonstrate the visual form of a document or a typeface
                      without relying on meaningful content. Lorem ipsum may be used as a placeholder
                      before final copy is available.</td>
                    <td data-title="Users Name">Bob Cumpston</td>
                    <td data-title="Order Number">OD09761003</td>
                    <td data-title="Product in Card">Yes</td>
                    <td data-title="Status">
                      <span class="badge badge-warning">Pending</span>
                    </td>
                    <td data-title="Date Time">May 30, 2021 17:00</td>


                  </tr>

                  <tr>
                    <td data-title="No.">3</td>
                    <td data-title="Title">Lorem ipsum</td>
                    <td data-title="Message">In publishing and graphic design, Lorem ipsum is a placeholder
                      text commonly used to demonstrate the visual form of a document or a typeface
                      without relying on meaningful content. Lorem ipsum may be used as a placeholder
                      before final copy is available.</td>
                    <td data-title="Users Name">Gaston Wilfong</td>
                    <td data-title="Order Number">OD09761004</td>
                    <td data-title="Product in Card">No</td>
                    <td data-title="Status">

                      <span class="badge badge-danger">Failed</span>
                    </td>
                    <td data-title="Date Time">May 30, 2021 17:00</td>


                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing 1 to 10 of 11 entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded">
                      <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-left"></i></a>
                      </li>
                      <li class="paginate_button page-item active"><a href="#" class="page-link">1</a>
                      </li>
                      <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                      </li>
                      <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-right"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->