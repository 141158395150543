import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  languageType: any;
  contentList: any = [];
  termsCondition: any;;
  constructor(private trainingService: TrainingService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,) {
    this.activatedRoute.params.subscribe(
      ({ language }) => {
        this.languageType = language;

      }
    );
    this.getStaticContent();
  }

  ngOnInit(): void {
  }



  getStaticContent() {
    this.commonService.presentSpinner();
    this.trainingService.getContaint().then(
      ({ contentList }) => {
        if (contentList) {
          this.contentList = contentList;
          this.contentList.forEach(element => {

            if (element.slug == 'terms-condition') {
              this.termsCondition = element.description;

            }

          });
        }
      }
    ).catch((error) => {
      this.contentList = [];
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {

      this.commonService.dismissSpinner();
    })
  }
}
