<div #scrollEl cdk-scrollable style="height:241px; overflow-y:scroll" class="table-scroll">
  <table class="table mb-0">
    <thead class="thead-light">
      <tr>
        <th style="width:30px;">No.</th>
        <th>Question</th>
      </tr>
    </thead>
    <tbody cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">
      <tr *ngFor="let tab of questionList ; let i = index" cdkDrag>
        <td data-title="No.">{{i + 1}}</td>
        <td data-title="Question">
          {{tab.question.en}}<br>
          {{tab.question.am}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<button type="submit" class="btn btn-warning" (click)="reOrder()" *ngIf="questionList.length">Save</button>