import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';

@Component({
  selector: 'app-sms-modules',
  templateUrl: './sms-modules.component.html',
  styleUrls: ['./sms-modules.component.scss']
})
export class SmsModulesComponent implements OnInit {

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

}
