import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-topic-reorder',
  templateUrl: './topic-reorder.component.html',
  styleUrls: ['./topic-reorder.component.scss']
})
export class TopicReorderComponent implements OnInit {
  @Input() topicList;
  @Output() topicReorderSuccess: EventEmitter<any> = new EventEmitter();
  list: Array<any> = [];
  apiHitDone: boolean;
  constructor(
    private commonService: CommonService,
    private trainingService: TrainingService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.list = this.topicList;
    this.apiHitDone = false;
    if (this.list.length) {
      this.apiHitDone = true;
    }
  }

  newArr: any = [];
  async drop(event: CdkDragDrop<string[]>) {
    this.newArr = []
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
    let index = 0;
    for await (let e of this.list) {
      index++;
      this.newArr.push({ 'topicId': e._id, 'position': index })
    }
  }

  reOrderModule() {
    let postdata = {
      payload: this.newArr
    }
    this.commonService.presentSpinner();
    this.trainingService.reorderTopic(postdata).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.showSuccessToastMsg(res.message.en);
        }
      }
    ).catch((error) => {
      this.commonService.showErrorToastMsg(error.error.message);
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.newArr = [];
      this.modalService.close('topicModal');
      this.topicReorderSuccess.emit('reorder');
    })

  }

}
