import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpInputData, HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class FAQService {

  constructor(
    private http: HttpWrapperService,
    private commonService: CommonService
  ) { }

  getFaq(query?: { page?: string; limit?: string; }) {

    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.getfaqs, httpInput).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          // this.commonService.showErrorMessageForApiResponse(err);
          reject(err);
        }
      );
    });
  }

  addFaq(payload) {
    return new Promise((resolve, reject) => {
      this.http.post(Endpoints.addupdatefaq, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          // this.commonService.showErrorMessageForApiResponse(err);
          reject(err);
        }
      );
    });
  }
  reorderFaqs(payload) {
    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.reorderfaqs, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          // this.commonService.showErrorMessageForApiResponse(err);
          reject(err);
        }
      );
    });
  }

  deleteFaq(faqId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(Endpoints.getfaqs + `/${faqId}`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {

          reject(err);
        }
      );
    });
  }
  // getQAExpertList() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get(Endpoints.askmessagelist).subscribe(
  //       (res) => {
  //         resolve(res);
  //       },
  //       (err) => {

  //         reject(err);
  //       }
  //     );
  //   });
  // }
  getQAExpertList(query?: { page?: string; limit?: string; search?: string, status?: string }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.page) {
      httpParams = httpParams.set('page', query.page);
    }
    if (query && query.limit) {
      httpParams = httpParams.set('limit', query.limit);
    }
    if (query && query.search) {
      httpParams = httpParams.set('search', query.search);
    }
    if (query && query.status) {
      httpParams = httpParams.set('sort', query.status);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.askmessagelist, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  getuserChatList(query?: { supportId?: string; }) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (query && query.supportId) {
      httpParams = httpParams.set('supportId', query.supportId);
    }

    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.userchatmessage, httpInput).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  replyUserMessage(payload) {

    return new Promise((resolve, reject) => {
      this.http.post(Endpoints.replymessage, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  updatemessageStatus(receiverid, status) {

    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.updatestatus + `/${receiverid}` + `/${status}`, '').subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }
  deleteChat(support) {

    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.updatestatus + `/${support}` + `/delete`, '').subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

}
