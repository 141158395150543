import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/modal/modal.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { FAQService } from 'src/app/services/faq/faq.service';
import { CommonService } from 'src/app/services/common/common.service';
import { AuthService } from 'src/app/services/auth/auth.service';
declare var $: any;
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  menus: Array<any> = [
    { route: '/admin/dashboard', icon: 'ri-dashboard-line', name: 'Dashboard' },
    { route: '/admin/users', icon: 'mdi mdi-account-group-outline', name: 'Users' },
    // {
    //   route: '', icon: 'ri-database-2-line', name: 'Data Management',
    //   "submenu": [
    //     { route: '/admin/categories', subname: 'Product Categories' },
    //     { route: '/admin/rate-setup', subname: 'Rate Setup' },


    //   ]
    // },
  ];
  selectedMenu: any;
  sidePanelEnabled: boolean = false;
  userInfo: any;
  expertQAData: any = [];
  constructor(
    private router: Router,
    private modalService: ModalService,
    private storage: StorageService,
    private events: EventsService,
    private FAQService: FAQService,
    private commonService: CommonService,
    private authService: AuthService,
  ) {
    this.selectedMenu = this.menus[0];
    this.userInfo = this.storage.getSavedData();
    this.events.subscribe(EventsKey.eventforexpertmenu, (data) => {
      // this.expertQAData = data;
      // let list = []
      // this.expertQAData.forEach(e => {
      //   if (e.status == 'pending') {
      //     list.push(e);
      //     this.expertQAData = list;
      //   }
      // });
      this.getExpertQaList(1);

    })
  }

  ngOnInit(): void {
    this.getExpertQaList(1);
  }

  navigateTo(menu: any) {
    this.selectedMenu = menu
    this.router.navigate([menu.route]);
  }
  enableSidePanel() {
    this.sidePanelEnabled = !this.sidePanelEnabled;
  }

  goTo(route: string) {
    if (route) {
      this.router.navigate([route]);
    }
  }

  representativesCollapsed: boolean = true;

  toggleSubMenu(subMenu: "Trainings") {
    if (this.sidePanelEnabled) {
      return;
    }
    switch (subMenu) {
      case "Trainings":
        this.representativesCollapsed = !this.representativesCollapsed
        break;
    }
  }



  openModal(id: string) {
    this.events.publish(EventsKey.changepassword, '');
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  expertQACount: any = [];
  getExpertQaList(page: number) {
    this.FAQService.getQAExpertList({ page: page.toString(), limit: '1000', search: '', status: '' }).then(
      ({ messageList, totalResult }) => {
        if (messageList) {
          this.expertQAData = messageList
          let list = []
          this.expertQACount = []
          this.expertQAData.forEach(e => {
            if (e.status == 'pending') {
              list.push(e);
              this.expertQACount = list;
              console.log("this.expertQACount", this.expertQACount)
            }
          });
        }
      }
    ).catch(
      (error) => {

      }
    ).finally(
      () => {

      }
    );
  }

  logOut() {
    this.commonService.presentSpinner();
    this.authService.logout().then(
      (res: any) => {
        if (res.code == 200) {
          this.storage.clearStorageForLogout();
          this.router.navigate(['/login']);
        }
      }
    ).catch(() => {

    }).finally(() => {
      this.commonService.dismissSpinner();
    })
  }
}
