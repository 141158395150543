import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/services/common/common.service';
import { FAQService } from 'src/app/services/faq/faq.service';
import { EventsService } from 'src/app/services/events/events.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { ModalService } from 'src/app/modal/modal.service';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { merge, } from 'rxjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reorder-faq',
  templateUrl: './reorder-faq.component.html',
  styleUrls: ['./reorder-faq.component.scss']
})
export class ReorderFaqComponent implements OnInit {
  @ViewChild('scrollEl')
  scrollEl: ElementRef<HTMLElement>;
  @ViewChildren(CdkDrag)
  dragEls: QueryList<CdkDrag>;
  subs = new Subscription();
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  ngAfterViewInit() {
    const onMove$ = this.dragEls.changes.pipe(
      startWith(this.dragEls)
      , map((d: QueryList<CdkDrag>) => d.toArray())
      , map(dragels => dragels.map(drag => drag.moved))
      , switchMap(obs => merge(...obs))
      , tap(this.triggerScroll)
    );

    this.subs.add(onMove$.subscribe());

    const onDown$ = this.dragEls.changes.pipe(
      startWith(this.dragEls)
      , map((d: QueryList<CdkDrag>) => d.toArray())
      , map(dragels => dragels.map(drag => drag.ended))
      , switchMap(obs => merge(...obs))
      , tap(this.cancelScroll)
    );

    this.subs.add(onDown$.subscribe());
  }
  apiHitDone: boolean;
  limit: number = 10000;
  faqList: Array<any>;
  constructor(
    private commonService: CommonService,
    private faqService: FAQService,
    private events: EventsService,
    private modalService: ModalService,
  ) {
    this.events.subscribe(EventsKey.edit, () => {
      this.getFaq();
    })
  }

  ngOnInit(): void {
  }



  getFaq() {
    this.apiHitDone = false;
    this.commonService.presentSpinner();
    this.faqService.getFaq({ "page": '1', "limit": this.limit.toString() }).then(
      ({ faqList, totalResult }) => {
        if (faqList) {
          this.faqList = faqList;

        } else {
          this.faqList = [];

        }
      }
    ).catch(
      (error) => {
        this.faqList = [];

      }
    ).finally(() => {
      this.apiHitDone = true;
      this.commonService.dismissSpinner()
    })
  }

  newArr: any = [];
  drop(event: CdkDragDrop<string[]>) {
    this.newArr = []
    moveItemInArray(this.faqList, event.previousIndex, event.currentIndex);
    this.faqList.forEach(e => {
      this.newArr.push({ 'faqId': e._id })
    });

  }
  private animationFrame: number | undefined;
  @bound
  public triggerScroll($event: CdkDragMove) {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
    this.animationFrame = requestAnimationFrame(() => this.scroll($event));
  }
  @bound
  private cancelScroll() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
  }
  speed: number = 5
  private scroll($event: CdkDragMove) {

    const { y } = $event.pointerPosition;
    const baseEl = this.scrollEl.nativeElement;
    const box = baseEl.getBoundingClientRect();
    const scrollTop = baseEl.scrollTop;
    const top = box.top + - y;
    if (top > 0 && scrollTop !== 0) {
      const newScroll = scrollTop - this.speed * Math.exp(top / 500);
      baseEl.scrollTop = newScroll;
      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
      return;
    }

    const bottom = y - box.bottom;
    if (bottom > 0 && scrollTop < box.bottom) {
      console.log("---bottom plush", bottom)
      const newScroll = scrollTop + this.speed * Math.exp(bottom / 50);
      baseEl.scrollTop = newScroll;
      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
    }
    // if (bottom < 0) {
    //   console.log("---bottom minus", bottom)
    //   const newScroll = scrollTop + this.speed * Math.exp(bottom / 50);
    //   baseEl.scrollTop = newScroll;
    //   this.animationFrame = requestAnimationFrame(() => this.scroll($event));
    // }
  }
  SaveChangeOrder() {
    this.commonService.presentSpinner();
    let postdata = {
      payload: this.newArr
    }
    console.log(postdata)
    this.faqService.reorderFaqs(postdata).then(
      (res: any) => {
        if (res.code == 200) {
          this.commonService.dismissSpinner();
          this.modalService.close('reorder');
          this.commonService.showSuccessToastMsg(res.message.en, '');
          this.newArr = [];

        }
      }
    ).catch((error) => {
      this.newArr = [];
      this.commonService.dismissSpinner();
    }).finally(() => {
      this.commonService.dismissSpinner();
      this.events.publish(EventsKey.faqSuccessfullyupdate, '');
    })
  }
}

export function bound(target: Object, propKey: string | symbol) {
  var originalMethod = (target as any)[propKey] as Function;

  // Ensure the above type-assertion is valid at runtime.
  if (typeof originalMethod !== "function") throw new TypeError("@bound can only be used on methods.");

  if (typeof target === "function") {
    // Static method, bind to class (if target is of type "function", the method decorator was used on a static method).
    return {
      value: function () {
        return originalMethod.apply(target, arguments);
      }
    };
  } else if (typeof target === "object") {
    // Instance method, bind to instance on first invocation (as that is the only way to access an instance from a decorator).
    return {
      get: function () {
        // Create bound override on object instance. This will hide the original method on the prototype, and instead yield a bound version from the
        // instance itself. The original method will no longer be accessible. Inside a getter, 'this' will refer to the instance.
        var instance = this;

        Object.defineProperty(instance, propKey.toString(), {
          value: function () {
            // This is effectively a lightweight bind() that skips many (here unnecessary) checks found in native implementations.
            return originalMethod.apply(instance, arguments);
          }
        });

        // The first invocation (per instance) will return the bound method from here. Subsequent calls will never reach this point, due to the way
        // JavaScript runtimes look up properties on objects; the bound method, defined on the instance, will effectively hide it.
        return instance[propKey];
      }
    } as PropertyDescriptor;
  }
}