import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-narrative',
  templateUrl: './narrative.component.html',
  styleUrls: ['./narrative.component.scss']
})
export class NarrativeComponent implements OnInit {
  addForm: FormGroup;
  formSubmitted: boolean;
  topicDetail: any;
  constructor(
    private events: EventsService,
    private trainingService: TrainingService,
    private commonService: CommonService,
    private modalService: ModalService,
  ) {
    this.events.subscribe(EventsKey.edit, (data) => {
      if (data) {
        this.topicDetail = data;
        this.addForm.patchValue(this.topicDetail)
      } else {
        this.topicDetail = null;
        this.addForm.reset();
        this.formSubmitted = false;
      }
    });
    this.addForm = new FormGroup({
      "narrativeDescription": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),

    })
  }

  ngOnInit(): void {
  }

  save() {
    this.formSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }

    this.commonService.presentSpinner();
    this.trainingService.addUpdateNarrative(this.addForm.value, this.topicDetail._id).then(
      (res: any) => {

      }
    ).catch((error) => {

    }).finally(() => {
      this.events.publish(EventsKey.successfullyaddupdate, '');
      this.commonService.dismissSpinner();
      this.modalService.close('narrative');
      this.topicDetail = null;
      this.formSubmitted = false;
    })
  }
}
