import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ModalService } from 'src/app/modal/modal.service';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { TrainingService } from 'src/app/services/training/training.service';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent implements OnInit {
  roleForm: FormGroup;
  locationForm: FormGroup;
  FormSubmitted: boolean;
  locationFormSubmitted: boolean;
  entries: Entries;
  locationEntries: Entries;
  limit: number = 10;
  apiHitDone: boolean = false;
  locationApiHitDone: boolean = false;
  currentPage: number;
  locationCurrentPage: number;
  editorConfig: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        // 'toggleEditorMode',
        // 'heading',
        'fontName',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList'
      ]
    ],
  };
  editorConfigAr: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        // 'toggleEditorMode',
        // 'heading',
        'fontName',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList'
      ]
    ],
  };
  privacypolicyConfig: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        // 'toggleEditorMode',
        // 'heading',
        'fontName',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList'
      ]
    ],
  };
  privacypolicyConfigAr: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        // 'toggleEditorMode',
        // 'heading',
        'fontName',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList'
      ]
    ],
  };
  editorConfigtrmEn: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        // 'toggleEditorMode',
        // 'heading',
        'fontName',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList'
      ]
    ],
  };
  editorConfigtrmAr: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'insertVideo',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat',
        // 'toggleEditorMode',
        // 'heading',
        'fontName',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList'
      ]
    ],
  };
  contentList: any = [];
  adminLocationList: any = [];
  userrole: any;
  location: any;
  aboutus: string = '';
  aboutusAm: string = '';
  aboutDetail: any;
  privacypolicy: string = '';
  privacypolicyDetail: any;
  privacyAm: string = '';
  termsCondition: string = '';
  termsAm: string = '';
  termDetail: any;
  constructor(
    private trainingService: TrainingService,
    private commonService: CommonService,
    private modalService: ModalService,
  ) {
    this.roleForm = new FormGroup({
      // "roleName": new FormControl('', Validators.compose([Validators.required])),
      "roleName": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
    })
    this.locationForm = new FormGroup({
      "locationName": new FormGroup({
        "en": new FormControl('', Validators.compose([Validators.required])),
        "am": new FormControl('', Validators.compose([Validators.required]))
      }),
    })
    this.getStaticContent();
    this.getUserRole(1);
    this.getlocation(1);
  }

  ngOnInit(): void {
  }
  async openModal(id: string, role?: any) {
    if (role) {
      this.userrole = role;
      console.log(" this.userrole", this.userrole)
      this.roleForm.patchValue({
        roleName: {
          en: this.userrole.roleName?.en ? this.userrole.roleName?.en : this.userrole.roleName,
          am: this.userrole.roleName?.am ? this.userrole.roleName?.am : this.userrole.roleName
        }
      })
    } else {
      this.userrole = null;
      this.roleForm.reset();
      this.FormSubmitted = false;
    }
    this.modalService.open(id);
  }
  async openLocationModal(id: string, data?: any) {
    if (data) {
      this.location = data;

      this.locationForm.patchValue({
        locationName: {
          en: this.location.locationName?.en,
          am: this.location.locationName?.am
        }
      })
    } else {
      this.location = null;
      this.locationForm.reset();
      this.locationFormSubmitted = false;
    }
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  enableEditing(editorConfig: 'editorConfig' | 'editorConfigAr' | 'editorConfigtrmEn' | 'editorConfigtrmAr' | 'privacypolicyConfig' | 'privacypolicyConfigAr') {
    this[editorConfig].editable = !this[editorConfig].editable;
    this[editorConfig].showToolbar = !this[editorConfig].showToolbar;
  }


  getStaticContent() {
    this.commonService.presentSpinner();
    this.trainingService.getContaint().then(
      ({ contentList }) => {
        if (contentList) {
          this.contentList = contentList;
          this.contentList.forEach(element => {
            if (element.slug == 'privacy-policy') {
              this.privacypolicy = element.description.en;
              this.privacypolicyDetail = element;
              this.privacyAm = element.description.am;
            }
            if (element.slug == 'terms-condition') {
              this.termDetail = element;
              this.termsCondition = element.description.en;
              this.termsAm = element.description.am;
            }

            if (element.slug == 'about-us') {
              this.aboutDetail = element;
              this.aboutus = element.description.en;
              this.aboutusAm = element.description.am;
            }
          });
        }
      }
    ).catch((error) => {
      this.contentList = [];
      this.commonService.showErrorToastMsg(error.error.message)
    }).finally(() => {

      this.commonService.dismissSpinner();
    })
  }

  updateContent(contentId: string, contentType) {
    let payload = {};
    if (contentType == 'aboutUs') {
      payload = {
        'description': {
          en: this.aboutus,
          am: this.aboutusAm
        }
      }
    }
    if (contentType == 'privacy') {
      payload = {
        'description': {
          en: this.privacypolicy,
          am: this.privacyAm
        }
      }
    }
    if (contentType == 'term') {
      payload = {
        'description': {
          en: this.termsCondition,
          am: this.termsAm
        }
      }
    }

    // console.log("payload", payload)
    // console.log("contentId", contentId)
    // return;
    this.commonService.presentSpinner();
    this.trainingService.updateContent(payload, contentId).then(
      (res: any) => {

      }
    ).catch((error) => {

    }).finally(() => {
      this.commonService.dismissSpinner()
      this.editorConfig.showToolbar = false
      this.editorConfigAr.showToolbar = false
      this.privacypolicyConfig.showToolbar = false
      this.privacypolicyConfigAr.showToolbar = false
      this.editorConfigtrmEn.showToolbar = false
      this.editorConfigtrmAr.showToolbar = false
      this.getStaticContent();
    })

  }

  adminRoleList: any = [];
  getUserRole(currentPage: number) {
    this.commonService.presentSpinner();
    this.trainingService.getUserRole({ "page": currentPage.toString(), "limit": this.limit.toString() }).then(
      ({ adminRoleList, totalResult }) => {
        if (adminRoleList) {
          this.adminRoleList = adminRoleList;
          this.entries = this.commonService.calculateEntries(this.adminRoleList, currentPage, this.limit, totalResult);
        } else {
          this.adminRoleList = [];
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).finally(() => {
      this.apiHitDone = true;
      this.currentPage = currentPage;
      this.commonService.dismissSpinner();
    })
  }
  getlocation(page: number) {
    this.commonService.presentSpinner();
    this.trainingService.getLocationData({ "page": page.toString(), "limit": this.limit.toString() }).then(
      ({ adminLocationList, totalResult }) => {
        if (adminLocationList) {
          this.adminLocationList = adminLocationList;

          this.locationEntries = this.commonService.calculateEntries(this.adminLocationList, page, this.limit, totalResult);
          // console.log
        } else {
          this.adminLocationList = [];
          this.locationEntries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).finally(() => {
      this.locationApiHitDone = true;
      this.locationCurrentPage = page;
      this.commonService.dismissSpinner();
    })
  }

  saveRole() {
    let promise: Promise<any> = null;
    let payload = null
    this.FormSubmitted = true;
    let rolId = ''
    if (this.userrole) {
      if (this.roleForm.value.roleName.en.invalid && this.roleForm.value.roleName.am.invalid) {
        this.FormSubmitted = true;
        return;
      }
      rolId = this.userrole._id
      payload = {
        "roleName": {
          "en": this.roleForm.value.roleName.en,
          "am": this.roleForm.value.roleName.am
        },
        "status": this.userrole.status

      }
      // payload = {
      //   "roleName": this.roleForm.value.roleName,
      //   "status": this.userrole.status
      // }
      promise = this.trainingService.UpdateUserRole(payload, rolId);
    } else {
      if (this.roleForm.invalid) {
        return;
      }
      payload = this.roleForm.value;
      promise = this.trainingService.addUserRole(payload);
    }
    this.commonService.presentSpinner();
    promise.then(
      (res) => {
        if (res) {
          if (this.userrole) {
            this.commonService.showSuccessToastMsg('Updated successfully!');
          } else {
            this.commonService.showSuccessToastMsg('Added successfully!');
          }
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.commonService.showErrorToastMsg(error.error.message);
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.roleForm.reset();
        this.userrole = null;
        this.FormSubmitted = false;
        this.modalService.close('userRoleModel');
        this.getUserRole(1);
      }
    )

  }
  saveLocation() {
    let promise: Promise<any> = null;
    let payload = null
    this.FormSubmitted = true;
    let locationId = ''
    if (this.location) {
      if (this.locationForm.value.locationName.en.invalid && this.locationForm.value.locationName.en.invalid) {
        this.FormSubmitted = true;
        return;
      }
      locationId = this.location._id
      payload = {
        "locationName": {
          "en": this.locationForm.value.locationName.en,
          "am": this.locationForm.value.locationName.am
        },
        "status": this.location.status

      }
      // payload = {
      //   "roleName": this.roleForm.value.roleName,
      //   "status": this.userrole.status
      // }
      promise = this.trainingService.UpdateUserLocation(payload, locationId);
    } else {
      if (this.locationForm.invalid) {
        return;
      }
      payload = this.locationForm.value;
      promise = this.trainingService.addUserlocation(payload);
    }
    this.commonService.presentSpinner();
    promise.then(
      (res) => {
        if (res) {
          if (this.userrole) {
            this.commonService.showSuccessToastMsg('Updated successfully!');
          } else {
            this.commonService.showSuccessToastMsg('Added successfully!');
          }
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.commonService.showErrorToastMsg(error.error.message);
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.locationForm.reset();
        this.location = null;
        this.locationFormSubmitted = false;
        this.modalService.close('location');
        this.getlocation(1);
      }
    )

  }
}
