import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CommonService, Entries } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';
import { FAQService } from 'src/app/services/faq/faq.service';
declare var $: any;
@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class ExpertComponent implements OnInit {
  action: string;
  ExpertQaList: Array<any> = new Array<any>();
  userChat: Array<any> = new Array<any>();
  apiHitDone: boolean;
  entries: Entries;
  page: number = 1;
  limit: number = 10;
  totalResult: any;
  searchKeyword: string;
  receiverId: any;
  status: string = '';
  expartStatus: string = '';
  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private events: EventsService,
    private FAQService: FAQService,
    private datePipe: DatePipe
  ) {
    this.getExpertQaList(1);
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $(".table-responsive .table tr").click(function () {
        $(this).addClass("active-bg").siblings().removeClass("active-bg");
      });
    });
  }

  supportId: string;
  doAction(action: string, userChat?: any) {

    console.log("userChat", userChat)
    this.action = action;
    this.receiverId = userChat.senderId;
    this.supportId = userChat._id;
    this.status = userChat.status
    this.getUserChat(this.supportId)
  }

  getUserChat(senderId) {
    this.FAQService.getuserChatList({ supportId: this.supportId }).then(
      ({ messageList }) => {
        if (messageList) {
          this.userChat = messageList;
          console.log("this.userChat", this.userChat)
          this.events.publish(EventsKey.eventforexpertmenu, '');
          // this.userChat.forEach(x => {
          //   x['datetime'] = this.getTimeInterval(x.timestamp);
          // });
          // this.getExpertQaList(1);
          if (this.status != 'resolved') {
            this.updateMessageStatus(this.supportId, 'inProgress')
          } else {
            this.updateMessageStatus(this.supportId, 'resolved')
          }

        }
      }
    ).finally(
      () => {
        setTimeout(() => {
          let chatInbox = document.getElementById('chat-inbox');
          if (chatInbox) {
            console.log(chatInbox, chatInbox.scrollHeight);

            chatInbox.scroll(0, chatInbox.scrollHeight);
          }
        }, 200);
      }
    )
  }

  getExpertQaList(page: number) {
    this.FAQService.getQAExpertList({ page: page.toString(), limit: this.limit.toString(), search: this.searchKeyword, status: this.expartStatus }).then(
      ({ messageList, totalResult }) => {
        if (messageList) {
          this.ExpertQaList = messageList
          this.events.publish(EventsKey.eventforexpertmenu, this.ExpertQaList);
          this.totalResult = totalResult;
          this.entries = this.commonService.calculateEntries(this.ExpertQaList, page, this.limit, totalResult);
        } else {
          this.ExpertQaList = [];
          this.totalResult = 0;
          this.entries = this.commonService.calculateEntries([], 0, 0, 0);
        }
      }
    ).catch(
      (error) => {
        this.ExpertQaList = [];
        this.totalResult = 0;
        this.entries = this.commonService.calculateEntries([], 0, 0, 0);
      }
    ).finally(
      () => {
        this.apiHitDone = true;
        this.page = page;
        if (this.page > 1 && this.ExpertQaList.length == 0) {
          this.page = this.page - 1;
          this.getExpertQaList(this.page);
        } else {
          let params: any = {
            "page": this.page
          };
          if (this.searchKeyword) {
            params.search = this.searchKeyword;
          }
          let navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, replaceUrl: true, queryParams: params }
          this.router.navigate([], navigationExtras);
        }
      }
    );
  }

  btnDisable: boolean;
  getMessage() {
    if (this.message.trim()) {
      this.btnDisable = true;
    } else {
      this.btnDisable = false;
    }
  }
  message: string;
  replyMessage() {
    var currentDate = new Date();
    var timestamp = currentDate.getTime();

    if (!this.supportId || !this.receiverId) {
      return;
    }
    let payload = {
      "message": this.message,
      "mediaMessage": "",
      "supportId": this.supportId,
      "timestamp": timestamp.toString(),
      "receiverId": this.receiverId
    }
    // let postdata = {
    //   payload: payload
    // }
    this.btnDisable = false;
    this.message = '';
    this.FAQService.replyUserMessage(payload).then(
      (res: any) => {
        if (res.code == 200) {
          this.getUserChat(this.receiverId);
        }
      }
    ).catch((error) => {

    }).finally(() => {
      this.message = '';

      // this.receiverId = '';
      this.getExpertQaList(1);
    })

  }

  updateMessageStatus(supportId?: any, status?: string) {
    if (status == 'inProgress') {
      this.status = status
    }
    this.FAQService.updatemessageStatus(this.supportId, this.status).then(
      (res: any) => {
        if (res.code == 200) {
          if (this.status == 'resolved') {
            this.userChat[0].status = 'resolved';
          }
        }
      }
    ).catch(() => {

    }).finally(() => {
      this.events.publish(EventsKey.eventforexpertmenu, this.ExpertQaList);
      this.getExpertQaList(1);

      // this.getUserChat(this.supportId);
    })
  }
  userToBeDeleted: string;
  deleteModal(userId: string) {
    this.userToBeDeleted = userId;
    $('#delete-offer').modal('show');
  }


  deleteChat() {
    this.commonService.presentSpinner();
    let payload = {
      status: 'Delete'
    }
    this.FAQService.deleteChat(this.userToBeDeleted).then(
      (res) => {

      }
    ).finally(
      () => {
        $('#delete-offer').modal('hide');
        this.getExpertQaList(1);
        this.commonService.dismissSpinner();
      }
    )
  }

  replyOnEnter(event) {
    if (event && event.keyCode == 13) {
      if (this.message.trim()) {
        this.replyMessage();
      }
    }
  }
}
