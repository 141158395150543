<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item" [routerLink]="['/admin/program']">Training Programs</li>
              <li class="breadcrumb-item" (click)="goBack()">Modules</li>
              <li class="breadcrumb-item" (click)="goBack()">Chapters</li>
              <li class="breadcrumb-item active">Topics</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
            (click)="openReorderModal('topicModal')">Reorder</button>
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addTopics')">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h4 class="card-title mb-4">Chapter Name:- {{topicList[0]?.Chapters?.chapterName?.en}}</h4>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter">
                  <label>
                    <input type="search" class="form-control form-control-sm" placeholder="Search.."
                      [(ngModel)]="searchKeyword" (input)="getTopic(1)">
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <!-- <th style="width:120px;">Picture</th> -->
                    <th style="width:120px;">Topic Name</th>
                    <th>Description</th>
                    <th style="width:115px;">Full Activity Description</th>
                    <th style="width:115px;">Full Narrative</th>
                    <th style="width:75px;">Read Time</th>
                    <th style="width:85px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of topicList | paginate: {
                    id: 'topicList',
                    itemsPerPage: limit,
                    currentPage: page,
                    totalItems: entries?.total
                  }; let i = index;">
                    <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                    <!-- <td data-title="Topic Name">
                      <div class="training-img">
                        <div class="bg_img"
                          style="background-color: rgb(248, 249, 250); background-image: url({{list?.mediaUrl}});">
                        </div>
                      
                      </div>
                    </td> -->
                    <td data-title="Topic Name">{{list?.topicsName?.en}}<br>{{list?.topicsName?.am}}</td>
                    <td data-title="Description">
                      <!-- <div class="description" data-maxlength="100">
                          <p></p>
                        </div> -->
                      <div class="description" style="text-align: justify;">
                        <a (click)="showText('en', list)">
                          <p [innerHTML]="list?.description?.en" [ngClass]="{'limitTextHeight': list?.readMore_en}">
                          </p>
                        </a>

                      </div>
                      <div class="description">
                        <a (click)="showText('am', list)">
                          <p [innerHTML]="list?.description?.am" [ngClass]="{'limitTextHeight': list?.readMore_am}">
                          </p>
                        </a>
                      </div>
                    </td>
                    <td data-title="Full Narrative">
                      <a (click)="openFullnarrativeModal('fullNarrative',list)"
                        Style="text-decoration: underline!important;" *ngIf="list.fullNarrative">Yes</a>
                      <a *ngIf="!list.fullNarrative">No</a>
                    </td>
                    <td data-title="Narrative">
                      <a (click)="openModal('narrative',list)" Style="text-decoration: underline!important;"
                        *ngIf="list.narrative">Yes</a>
                      <a *ngIf="!list.narrative">No</a>
                    </td>
                    <td data-title="Read Time">{{list?.readTime}} minute</td>
                    <td data-title="Actions">
                      <button type="button" (click)="openModal('addTopics',list)" class="mr-2 text-primary btn_style"><i
                          class="mdi mdi-pencil font-size-18"></i>
                      </button>
                      <!-- <a (click)="openModal('narrative')" href="javascript:void(0);" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a> -->
                      <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(list?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !topicList?.length">
                    <td colspan="4">
                      <h5 class="text-center">Topics not found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && topicList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
                    {{entries?.total}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="topicList"
                      (pageChange)="getTopic($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->

<!-- Add Topics Popup -->
<modal id="addTopics" class="big-modal" style="display: block;opacity: 0;visibility: hidden;">
  <div class="modal-header">
    <h5 class="modal-title mt-0"> {{topicDetail ? 'Edit' : 'Add'}} Topics</h5>
    <button type="button" class="close" (click)="closeModal('addTopics');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-topics-add [ids]="sendId"></app-topics-add>
  </div>
</modal>
<!-- Edit Topics Popup -->

<!-- Add Topics edit Popup -->
<modal id="editTopics" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Topics</h5>
    <button type="button" class="close" (click)="closeModal('editTopics');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-topics-add></app-topics-add>
  </div>
</modal>
<!-- End Edit Topics Popup -->

<!-- Narrative Popup -->
<modal id="narrative" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add/Edit Narrative</h5>
    <button type="button" class="close" (click)="closeModal('narrative');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-narrative></app-narrative>
  </div>
</modal>
<!-- End Narrative Popup -->

<!-- Add Topic Popup -->
<modal id="topicModal" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reorder Training</h5>
    <button type="button" class="close" (click)="closeModal('topicModal');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-topic-reorder [topicList]="topicList"  (topicReorderSuccess)="getData($event)"></app-topic-reorder>
  </div>
</modal>
<!-- Edit Topic Popup -->

<!-- Full Narrative Popup -->
<modal id="fullNarrative" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Full Activity Description</h5>
    <button type="button" class="close" (click)="closeModal('fullNarrative');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addNarritiveDescriptionForm">
      <div class="row" formGroupName="fullNarritiveDescription">
        <div class="col-md-6">
          <div class="form-group">
            <label>Description (English)</label>
            <textarea class="form-control" id="editor1" formControlName="en"></textarea>
            <div *ngIf="formSubmitted && !addNarritiveDescriptionForm?.value?.fullNarritiveDescription?.en"
              class="error-msg">
              <span>Required!</span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Description (Amharic)</label>
            <textarea class="form-control" id="editor1" formControlName="am"></textarea>
            <div *ngIf="formSubmitted && !addNarritiveDescriptionForm?.value?.fullNarritiveDescription?.am"
              class="error-msg">
              <span>Required!</span>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-warning" type="submit" (click)="save(fullNarrativeDescriptionDetail)">Save</button>
    </form>
  </div>
</modal>
<!-- End Narrative Popup -->
<div class="modal fade alert_popup" id="delete-module" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Topic</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteTopic()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>