<div class="form">
  <form [formGroup]="addForm">
    <div class="row" formGroupName="narrativeDescription">
      <div class="col-md-6">
        <div class="form-group">
          <label>Description (English)</label>
          <textarea class="form-control" id="editor1" formControlName="en"></textarea>
          <!-- <angular-editor id="editor1" formControlName="en"></angular-editor> -->
          <div *ngIf="formSubmitted && !addForm?.value?.narrativeDescription?.en" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Description (Amharic)</label>
          <textarea class="form-control" id="editor1" formControlName="am"></textarea>
          <!-- <angular-editor id="editor1" formControlName="am"></angular-editor> -->
          <div *ngIf="formSubmitted && !addForm?.value?.narrativeDescription?.am" class="error-msg">
            <span>Required!</span>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-warning" type="submit" (click)="save()">Save</button>
  </form>
</div>