<div class="page-content">
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item" [routerLink]="[routeUrl[2] == 'sms' ?'/admin/sms/sms': '/admin/program']">Training Programs</li>
              <li class="breadcrumb-item" (click)="goBack()">Modules</li>
              <li class="breadcrumb-item active">Chapters</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
            (click)="openReorderModal('chapterModal')">Reorder</button>
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
              (click)="openModal('addChapters')">Add</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card table_page">
          <div class="card-body pt-3">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h4 class="card-title mb-4">Module Name:- {{chapterList[0]?.Modules?.modulesName?.en}}</h4>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter">
                  <label>
                    <input type="search" class="form-control form-control-sm" placeholder="Search.."
                      [(ngModel)]="searchKeyword" (input)="getChapter(1)">
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width:40px;">No.</th>
                    <th style="width:120px;">Chapter Name</th>
                    <th style="width:150px;">Description</th>
                    <th style="width:75px;">Read Time</th>
                    <th style="width:100px;">Topics </th>
                    <th style="width:100px;">Assessments </th>
                    <th style="width:60px;">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of chapterList | paginate: {
                    id: 'chapterList',
                    itemsPerPage: limit,
                    currentPage: page,
                    totalItems: entries?.total
                  }; let i = index;">
                    <td data-title="No.">{{ limit * (page - 1) + i + 1 }}</td>
                    <td data-title="Chapter Name">{{list?.chapterName?.en}}<br>{{list?.chapterName?.am}}</td>
                    <td data-title="Description">
                      <div class="description" style="text-align: justify;">
                        <a (click)="showText('en', list)">
                          <p [innerHTML]="list?.description?.en" [ngClass]="{'limitTextHeight': list?.readMore_en}">
                          </p>
                        </a>

                      </div>
                      <!-- <div class="description" data-maxlength="70">
                        <p [innerHtml]="list.description.en"> </p>
                      </div> -->
                      <div class="description">
                        <a (click)="showText('am', list)">
                          <p [innerHTML]="list?.description?.am" [ngClass]="{'limitTextHeight': list?.readMore_am}">
                          </p>
                        </a>
                      </div>
                      <!-- <div class="description" data-maxlength="60">
                        <p [innerHtml]="list.description.am"></p>
                      </div> -->
                    </td>
                    <td data-title="Read Time">{{list?.readTime}} minute</td>
                    <td data-title="Topics"><span class="badge badge-dark"
                        (click)="goToTopic(list)">{{list?.topicCount}}</span>
                    </td>
                    <td data-title="Assessments"><span class="badge badge-dark"
                        (click)="goToAssessment(list)">{{list?.questionCount}}</span></td>
                    <td data-title="Actions">
                      <button type="button" (click)="openModal('addChapters',list)"
                        class="mr-2 text-primary btn_style"><i class="mdi mdi-pencil font-size-18"></i>
                      </button>
                      <!-- <a [routerLink]="['/admin/modules']" class="mr-2 text-solitud"><i class="fas fa-eye font-size-18"></i></a> -->
                      <a href="javascript:void(0);" class="text-danger" (click)="deleteModal(list?._id)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="apiHitDone && !chapterList?.length">
                    <td colspan="4">
                      <h5 class="text-center">Chapter not found!</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="api-inline-loader" *ngIf="!apiHitDone">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="row" *ngIf="apiHitDone && chapterList?.length">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{entries?.from}} to {{entries?.to}} of
                    {{entries?.total}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <pagination-controls [autoHide]="true" [nextLabel]="''" [previousLabel]="''" id="chapterList"
                      (pageChange)="getChapter($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->

<!-- Add Chapters Popup -->
<modal id="addChapters" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">{{chapterDetail ? 'Edit' : 'Add'}} Chapters</h5>
    <button type="button" class="close" (click)="closeModal('addChapters');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-chapter [ids]="sendId"></app-add-chapter>
  </div>
</modal>
<!-- Edit Chapters Popup -->

<!-- Add Chapters edit Popup -->
<modal id="editChapters" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Edit Chapters</h5>
    <button type="button" class="close" (click)="closeModal('editChapters');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-chapter></app-edit-chapter>
  </div>
</modal>

<!-- Add Chapter Popup -->
<modal id="chapterModal" class="big-modal">
  <div class="modal-header">
    <h5 class="modal-title mt-0">Reorder Training</h5>
    <button type="button" class="close" (click)="closeModal('chapterModal');"> <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <app-chapter-reorder [chapterList]="chapterList"  (chapterReorderSuccess)="getData($event)"></app-chapter-reorder>
  </div>
</modal>
<!-- Edit Chapter Popup -->



<!-- End Edit Chapters Popup -->

<div class="modal fade alert_popup" id="delete-module" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete Chapter</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
            </div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button" aria-label="Close" (click)="deleteChapter()">
                <span class="alert-button-inner sc-ion-alert-ios">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>