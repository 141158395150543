import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/modal/modal.service';
import { AddAdmin, UpdateAdmin } from 'src/app/services/admin/admin.mode';
import { AdminService } from 'src/app/services/admin/admin.service';
import { CommonService, Entries, passwordMatchValidator } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-administrator-add',
  templateUrl: './administrator-add.component.html',
  styleUrls: ['./administrator-add.component.scss']
})
export class AdministratorAddComponent implements OnInit {

  password: boolean;
  confirmpassword: boolean;
  adminList: Array<any> = new Array<any>();
  apiHitDone: boolean;

  page: number = 1;
  limit: number = 10;
  totalResult: any;
  searchKeyword: string;
  adminForm: FormGroup;
  adminFormSubmitted: boolean;
  adminToEdit: any;
  userDetail: any;
  constructor(
    private adminService: AdminService,
    private commonService: CommonService,
    private modalService: ModalService,
    private events: EventsService,
  ) {
    this.adminForm = new FormGroup({
      "name": new FormControl('', Validators.compose([Validators.required])),
      "email": new FormControl('', Validators.compose([Validators.required, Validators.email])),
      "password": new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16)])),
      "confirmPassword": new FormControl('', Validators.compose([Validators.required])),
    }, [passwordMatchValidator]);
    this.events.subscribe(EventsKey.edit, (data) => {
      if (data) {
        this.userDetail = data;
        this.openAdminnFormModal(this.userDetail)

      } else {
        this.openAdminnFormModal()
        this.userDetail = null
      }

    })
  }

  async openAdminnFormModal(admin?: any) {
    this.adminForm.reset();
    this.adminFormSubmitted = false;
    this.adminToEdit = null;
    if (admin) {
      this.adminToEdit = { ...admin };
      await this.adminForm.controls['password'].clearValidators();
      await this.adminForm.controls['confirmPassword'].clearValidators();
      await this.adminForm.clearValidators();
      this.adminForm.patchValue(admin.additionalInfo)
    } else {
      this.adminForm.controls['password'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(16)]);
      this.adminForm.controls['confirmPassword'].setValidators([Validators.required]);
      this.adminForm.setValidators([passwordMatchValidator]);
    }
  }
  ngOnInit(): void {
  }

  showPassword() {
    this.password = !this.password;
  }
  showconfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }

  addOrUpdateAdmin() {
    let promise: Promise<any> = null;
    let payload = null
    this.adminFormSubmitted = false;
    if (this.adminToEdit) {
      if (this.adminForm.controls['name'].invalid || this.adminForm.controls['email'].invalid) {
        this.adminFormSubmitted = true;
        return;
      }
      payload = new UpdateAdmin(this.adminForm.value);
      promise = this.adminService.updateAdmin(this.adminToEdit._id, payload);
    } else {
      if (this.adminForm.invalid) {
        this.adminFormSubmitted = true;
        return;
      }
      payload = new AddAdmin(this.adminForm.value);
      promise = this.adminService.addAdmin(payload);
    }

    this.commonService.presentSpinner();
    promise.then(
      (res) => {
        if (res) {
          if (this.adminToEdit) {
            this.commonService.showSuccessToastMsg('Updated successfully!');
          } else {
            this.commonService.showSuccessToastMsg('Added successfully!');
          }
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.commonService.showErrorToastMsg(error.error.message);
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.adminForm.reset();
        this.adminToEdit = null;
        this.events.publish(EventsKey.successfullyaddupdate, '');
        this.modalService.close('addAdministrator');
      }
    )
  }

}