<form class="form-horizontal" [formGroup]="adminForm">
  <!-- <div class="mb-4">
      <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
        <label for="userpassword3">Password</label>
        <input [type]="oldpassword ? 'text' : 'password' " [autocomplete]="'off'" class="form-control"
          id="userpassword3" placeholder="Enter old password"> <i (click)="showoldPassword()"
          class=" auti-custom-input-icon auti-custom-eye"
          [ngClass]="oldpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
      </div>
      <div class="invalid-feedback" style="display:none;">
        <div>Password is required</div>
      </div>
    </div> -->
  <div class="mb-4">
    <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
      <label for="userpassword3">Password</label>
      <input [type]="forgotpassword ? 'text' : 'password' " [autocomplete]="'off'" class="form-control"
        id="userpassword3" placeholder="Enter new password" formControlName="password"> <i
        (click)="showforgotPassword()" class=" auti-custom-input-icon auti-custom-eye"
        [ngClass]="forgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
    </div>
    <div *ngIf="resetPasswordFormSubmitted && adminForm?.controls['password']?.errors" class="invalid-feedback"
      style="display:inline-block;">
      <div *ngIf="adminForm?.controls['password']?.errors?.required">Password is required</div>
      <div *ngIf="adminForm?.controls['password']?.errors?.minlength">Password should be minimum 6 digit!</div>
    </div>
  </div>
  <div class="mb-4">
    <div class="form-group auth-form-group-custom mb-0"> <i class="ri-lock-2-line auti-custom-input-icon"></i>
      <label for="userpassword4">Confirm Password</label>
      <input [type]="confirmforgotpassword ? 'text' : 'password' " [autocomplete]="'off'" class="form-control"
        id="userpassword4" placeholder="Enter confirm password" formControlName="confirmPassword"> <i
        (click)="showConfirmforgotPassword()" class=" auti-custom-input-icon auti-custom-eye"
        [ngClass]="confirmforgotpassword ? 'ri-eye-fill' : 'ri-eye-line'"></i>
    </div>
    <div class="invalid-feedback" *ngIf="resetPasswordFormSubmitted && adminForm?.controls['confirmPassword']?.errors"
      style="display:inline-block;">
      <div *ngIf="adminForm?.controls['confirmPassword']?.errors?.required">Confirm Password is required</div>
    </div>
    <div class="invalid-feedback" *ngIf="resetPasswordFormSubmitted && adminForm?.errors" style="display:inline-block;">
      <div *ngIf="adminForm?.errors?.mismatch">Confirm Password does not
        match!</div>
    </div>
  </div>
  <div class="mt-4">
    <button class="btn btn-primary w-md waves-effect waves-light" type="submit" (click)="resetPassword()">Save</button>
  </div>
</form>