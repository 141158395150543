<div class="form">
  <form [formGroup]="userForm">
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <label>Picture</label>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="customFile" formControlName="profileImage"
              (change)="selectImage($event)">
            <label class="custom-file-label" for="customFile">Choose file</label>

          </div>
          <div style="background-color: rgb(245, 245, 245);" *ngIf="image_upload">
            <img src="{{image_upload}}" alt="" style="width: 50px; height: 50px;">
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>Name*</label>
          <input type="name" class="form-control" formControlName="name">
          <div *ngIf="userFormSubmitted && userForm?.controls['name']?.errors" class="error-msg mb-1">
            <span *ngIf="userForm?.controls['name']?.errors?.required">Required!</span>
          </div>
        </div>

      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>Phone Number*</label>
          <input type="text" class="form-control" (keypress)="numberOnly($event, 'value')"
            formControlName="mobileNumber" maxlength="15">
          <div *ngIf="userFormSubmitted && userForm?.controls['mobileNumber']?.errors" class="error-msg mb-1">
            <span *ngIf="userForm?.controls['mobileNumber']?.errors?.required">Required!</span>
            <span *ngIf="userForm?.controls['mobileNumber']?.errors?.maxlength">Max nummber should not greater than 15
              digit!</span>
            <span *ngIf="userForm?.controls['mobileNumber']?.errors?.minlength">Minimum nummber should be 10
              digit!</span>
          </div>
        </div>

      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>Role </label>
          <select class="custom-select" id="role" formControlName="roleId">
            <option value="" selected="">Select Role</option>
            <option value="{{role?._id}}" *ngFor="let role of userRole">{{role?.roleName?.en | uppercase}} -
              {{role?.roleName?.am | uppercase}} </option>

          </select>
          <div *ngIf="userFormSubmitted && userForm?.controls['roleId']?.errors" class="error-msg mb-1">
            <span *ngIf="userForm?.controls['roleId']?.errors?.required">Required!</span>
          </div>
        </div>

      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>School</label>
          <input type="text" class="form-control" formControlName="schoolName">
          <div *ngIf="userFormSubmitted && userForm?.controls['schoolName']?.errors" class="error-msg mb-1">
            <span *ngIf="userForm?.controls['schoolName']?.errors?.required">Required!</span>
          </div>
        </div>

      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>Location</label>
          <!-- <input type="text" class="form-control" formControlName="location"> -->
          <select class="custom-select" id="location" formControlName="locationId">
            <option value="" selected="">Select Location</option>
            <option value="{{location?._id}}" *ngFor="let location of adminLocationList">{{location?.locationName?.en |
              uppercase}} -
              {{location?.locationName?.am | uppercase}} </option>

          </select>
          <div *ngIf="userFormSubmitted && userForm?.controls['locationId']?.errors" class="error-msg mb-1">
            <span *ngIf="userForm?.controls['locationId']?.errors?.required">Required!</span>
          </div>
        </div>

      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label>User Type</label>
          <select class="custom-select" id="user-type" formControlName="role">
            <option value="" selected>Select User Type</option>
            <option value="trainee">Trainee</option>
            <option value="guest">Guest</option>
          </select>
          <div *ngIf="userFormSubmitted && userForm?.controls['role']?.errors" class="error-msg mb-1">
            <span *ngIf="userForm?.controls['role']?.errors?.required">Required!</span>
          </div>
        </div>

      </div>
    </div>
  </form>
  <button class="btn btn-warning" type="submit" (click)="addOrUpdateUser()">Save</button>
</div>