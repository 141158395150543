<div class="page-content">
  <div class="container-fluid">

    <!-- start page title -->
    <div class="row dashboard-topbar">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><a href="javascript: void(0);">Splash Remote Learning</a></li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <div class="select_date">
              <div class="input-group-date" daterangepicker [options]="picker1"
                (selected)="selectedDate($event, chosenDate)">
                <span class=" uneditable-input" name="daterange">
                  <span *ngIf="chosenDate.start else elseblock">
                    {{ chosenDate.start | date:'mediumDate' }} - {{
                    chosenDate.end| date:'mediumDate' }}
                  </span>
                  <ng-template #elseblock>
                    <div class="md-text">
                      <div class="primary-date-range-key">
                        Last 7 days
                      </div>
                      <div class="ng-binding ri-arrow-down-s-fill">
                        Jan 12 - Jan 18, 2021
                      </div>
                    </div>
                  </ng-template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <!-- start chart Filter-->
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <h5 class="card-title">Total Users</h5>
                <p class="mb-0">{{dbResponse?.TotalUserCount}}</p>
              </div>
              <div class="text-primary">
                <i class="ri-team-line font-size-24"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <h5 class="card-title">Overall %</h5>
                <p class="mb-0">18</p>
              </div>
              <div class="text-primary">
                <i class=" ri-file-search-line font-size-24"></i>
              </div>
            </div>

          </div>
        </div>
      </div> -->
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <h5 class="card-title">App Users</h5>
                <p class="mb-0">{{dbResponse?.AppUserCount}}</p>
              </div>
              <div class="text-primary">
                <i class="ri-team-line font-size-24"></i>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <h5 class="card-title">Mobile Users</h5>
                <p class="mb-0">{{dbResponse?.SMSUserCount}}</p>
              </div>
              <div class="text-primary">
                <i class="ri-team-line font-size-24"></i>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title float-left">App Users Vs SMS Users</h3>
            <div class="apx_chart">
              <apx-chart [series]="chartOptions1.series" [chart]="chartOptions1.chart"
                [dataLabels]="chartOptions1.dataLabels" [xaxis]="chartOptions1.xaxis" [legend]="chartOptions1.legend">
              </apx-chart>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <!-- <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title float-left">SMS Training Vs Total SMS</h3>
            <div class="apx_chart">
              <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart"
                [dataLabels]="chartOptions2.dataLabels" [xaxis]="chartOptions2.xaxis" [legend]="chartOptions2.legend">
              </apx-chart>
            </div>
          </div>
        </div>
      </div> -->
      </div>
      <!-- end row -->
    </div> <!-- container-fluid -->
  </div>
  <!-- End Page-content -->