import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/modal/modal.service';
import { changePasswordForAdmin } from 'src/app/services/admin/admin.mode';
import { AdminService } from 'src/app/services/admin/admin.service';
import { CommonService, passwordMatchValidator } from 'src/app/services/common/common.service';
import { EventsKey } from 'src/app/services/events/events-key.constant';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  password: boolean;
  confirmpassword: boolean;
  oldpassword: boolean;
  adminForm: FormGroup;
  resetPasswordFormSubmitted: boolean;
  constructor(private events: EventsService,
    private commonService: CommonService,
    private adminService: AdminService,
    private modalService: ModalService,
  ) {
    this.events.subscribe(EventsKey.changepassword, () => {
      console.log("change password");
      this.resetPasswordFormSubmitted = false;
    })
    this.adminForm = new FormGroup({

      "password": new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16)])),
      "confirmPassword": new FormControl('', Validators.compose([Validators.required])),
    },
      { validators: [passwordMatchValidator] });
  }

  ngOnInit(): void {
  }
  showoldPassword() {
    this.oldpassword = !this.oldpassword;
  }
  showPassword() {
    this.password = !this.password;
  }
  showConfirmPassword() {
    this.confirmpassword = !this.confirmpassword;
  }
  getpassword() {
    if (!this.adminForm.value.confirmPassword.length) {
      this.adminForm.errors.mismatch = false

    }
  }
  resetPassword() {


    if (this.adminForm.invalid) {
      this.resetPasswordFormSubmitted = true
      return
    }
    let payload = new changePasswordForAdmin({
      "password": this.adminForm.value.password,
      "confirmPassword": this.adminForm.value.confirmPassword
    });
    this.commonService.presentSpinner();
    this.resetPasswordFormSubmitted = false;
    this.adminService.changePassword(payload).then(
      (res) => {
        if (res) {
          this.commonService.showSuccessToastMsg('Password updated!');
        } else {
          this.commonService.showSuccessToastMsg("Something went wrong!");
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error && error.error && error.error.message) {
          this.commonService.showErrorToastMsg(error.error.message || "Something went wrong!");
        } else {
          this.commonService.showErrorToastMsg("Something went wrong!");
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
        this.adminForm.reset();
        this.resetPasswordFormSubmitted = false;
        this.modalService.close('changePassword');

      }
    )
  }
}
