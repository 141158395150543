import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  password: boolean;
  loginForm: FormGroup;
  loginFormSubmitted = false;
  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router
  ) {
    this.loginForm = new FormGroup({
      "email": new FormControl('', Validators.compose([Validators.required])),
      "password": new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit(): void {
  }
  showPassword() {
    this.password = !this.password;
  }


  doLogin() {
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    let payload = {
      email: this.loginForm.value.email.trim(),
      password: this.loginForm.value.password,

    }
    this.authService.login(payload).then(
      (res: any) => {
        if (res && res.userData) {
          this.storage.set(Storage_Keys.admin_data, res.userData);
          this.storage.set(Storage_Keys.token, res.userData.authToken);
          this.router.navigate(['/admin/dashboard'])
        } else {

        }
      }
    ).catch(
      (error) => {
        if (error && error.error && error.error.message) {

        } else {

        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }

  forgotpasswormsg: boolean;
  errormsg: string = ''
  forgotpassword() {
    // [routerLink] = "['admin/forgot-password']" 


    let email = { "username": "superadmin@gmail.com" }
    this.commonService.presentSpinner();
    this.authService.forgotpassword(email).then(
      (res: any) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.commonService.showSuccessToastMsg(res.message)
            this.commonService.dismissSpinner();
            this.forgotpasswormsg = true;
            setTimeout(() => {
              this.forgotpasswormsg = false;
            }, 6000);
            // this.router.navigate([res.resetUrl]);
          } else if (res.code == 422) {
            this.errormsg = res.message;
            this.commonService.dismissSpinner();
          }
        } else {
          this.commonService.dismissSpinner();
        }

      }
    )

    // this.router.navigate([''])
  }
}
