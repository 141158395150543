import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpUserWrapperService } from '../http/http-wrapper/http-user-wrapper.service';
import { HttpInputData, HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpWrapperService,
    private httpUser: HttpUserWrapperService,
    private commonService: CommonService,

  ) { }

  login(payload: { email: string, password: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpUser.post(Endpoints.login, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
            this.commonService.showErrorToastMsg('', 'Invalid credentials');
          } else {
            this.commonService.showErrorToastMsg('', 'Something went wrong. Please try again later.');
          }
          reject(error);
        }
      );
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.httpUser.get(Endpoints.logout).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
            this.commonService.showErrorToastMsg('', error.error.message);
          } else {
            this.commonService.showErrorToastMsg('', 'Something went wrong. Please try again later.');
          }
          reject(error);
        }
      )
    })
  }

  changePassword(payload: { newPassword: string, confirmPassword: string }) {
    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.changePassword, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
            this.commonService.showErrorToastMsg('', error.error.message);
          } else {
            this.commonService.showErrorToastMsg('', 'Something went wrong. Please try again later.');
          }
          reject(error);
        }
      )
    })
  }
  forgotpassword(email) {
    return new Promise((resolve, reject) => {
      this.httpUser.post(Endpoints.forgotpassword, email).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
            this.commonService.showErrorToastMsg('', error.error.message);
          } else {
            this.commonService.showErrorToastMsg('', 'Something went wrong. Please try again later.');
          }
          reject(error);
        }
      )
    })
  }

  RestPassword(payload) {
    return new Promise((resolve, reject) => {
      this.httpUser.post(Endpoints.resetpassword, payload).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
            this.commonService.showErrorToastMsg('', error.error.message);
          } else {
            this.commonService.showErrorToastMsg('', 'Something went wrong. Please try again later.');
          }
          reject(error);
        }
      )
    })
  }

  getDashboardData(period?: string, startDate?: string, endDate?: string) {
    let httpInputData = new HttpInputData();
    let httpParams = new HttpParams();
    if (period) {
      httpParams = httpParams.set('period', period);
    }
    if (startDate) {
      httpParams = httpParams.set('startDate', startDate);
    }
    if (endDate) {
      httpParams = httpParams.set('endDate', endDate);
    }
    httpInputData.params = httpParams;
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.dashboard, httpInputData).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error && error.error && error.error.message) {
            this.commonService.showErrorToastMsg('', error.error.message);
          } else {
            this.commonService.showErrorToastMsg('', 'Something went wrong. Please try again later.');
          }
          reject(error);
        }
      )
    });
  }
}
