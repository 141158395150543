export class AddAdmin {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    constructor(payload: {
        name: string;
        email: string;
        password: string;
        confirmPassword: string;
    }) {
        if (payload) {
            this.name = payload.name;
            this.email = payload.email;
            this.password = payload.password;
            this.confirmPassword = payload.confirmPassword;
        }
    }
}

export class UpdateAdmin {
    name: string;
    email: string;

    constructor(payload: {
        name: string;
        email: string;
    }) {
        if (payload) {
            this.name = payload.name;
            this.email = payload.email;
        }
    }
}

export class ResetPasswordForAdmin {
    password: string;
    confirmPassword: string;
    _id: string;

    constructor(payload: {
        _id: string;
        password: string;
        confirmPassword: string;
    }) {
        if (payload) {
            this._id = payload._id;
            this.password = payload.password;
            this.confirmPassword = payload.confirmPassword;
        }
    }
}
export class changePasswordForAdmin {
    password: string;
    confirmPassword: string;


    constructor(payload: {
        password: string;
        confirmPassword: string;
    }) {
        if (payload) {
            this.password = payload.password;
            this.confirmPassword = payload.confirmPassword;
        }
    }
}
