<div class="form program-editor">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Picture (English)</label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label" for="customFile">Choose file</label>
        </div>
      </div>
      <div class="form-group">
        <label>Training Name (English)</label>
        <input type="name" class="form-control">
      </div>
      <div class="form-group">
        <label>Description (English)</label>
        <angular-editor id="editor1"></angular-editor>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Picture (Amharic)</label>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="customFile">
          <label class="custom-file-label" for="customFile">Choose file</label>
        </div>
      </div>
      <div class="form-group">
        <label>Training Name (Amharic)</label>
        <input type="name" class="form-control">
      </div>
      <div class="form-group">
        <label>Description (Amharic)</label>
        <angular-editor id="editor1"></angular-editor>
      </div>
       
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Tags</label>
        <div class="data">
          <div class="data-value"><span class="data-value-label">Tag1</span><span class="data-value-icon">×</span></div>
        </div>
        <input type="text" class="form-control">
      </div>
    </div>
  </div>
  <button type="submit" class="btn btn-warning">Save</button>
</div>